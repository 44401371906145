import React, { useState,useEffect, useRef }  from 'react';
import SavedCards from 'components/payment/SavedCards.jsx';
import { GetRequest, PostRequest } from 'components/common/index.js';

const PaymentForm = ({ subscription, setSubscription, setCurrencyName, setMode }) => {
  const [rememberCard, setRememberCard] = useState(0);
  const [savedCards, setSavedCards] = useState([]);

  const cardElementRef = useRef();
  const paymentFormRef = useRef();
  const savedCardFormRef = useRef();

  const setSubmitHandlerMode = (value) => { window.submitHandlerMode = value; }
  const setSubmitHandlerModeDefault = () => { setSubmitHandlerMode('default'); }

  const fetchMyCards = () => {
    return;
    GetRequest({
      url: '/charge/my_cards',
      callback: (data) => {
        setSavedCards( (_prev) => data );
      }
    })
  }

  const setUp = () => {
    setSubmitHandlerModeDefault()
    fetchMyCards();
  }

  useEffect( () => {
    let listen = 'show_drawing.currency_changed';
    document.addEventListener(listen, fetchSubscription);
    return () => { document.removeEventListener(listen, fetchSubscription);}
  }, [])

  useEffect( () => {
    $("#preview_promo_modal_content_container").css("height", "unset");
  }, [])

  useEffect(setUp, []);

  useEffect(() => {
    $('.my_tab').click(popupTabsOnClick);

    if(savedCards.length > 0) {
      $("#tab_saved_cards").trigger('click');
    } else {
      $("#content-stripe").removeClass('hidden');
    }
  }, [savedCards]);

  useEffect( () => {
    trackEvent('js_selected_subscription', {
      item: subscription,
    });

  }, [subscription]);

  const appearance = {
    theme: 'stripe',

    variables: {
      colorPrimary: '#eb5424',
      colorBackground: '#ffffff',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    }
  };

  const fetchSubscription = () => {
    GetRequest({
      url: '/charge/subscriptions',
      callback: (data) => {
        let key = subscription.type;
        if(subscription.type == 'monthly')  { key = 'monthly_subscription'}
        if(subscription.type == 'annually') { key = 'annual_subscription'}

        let newSubscription = data[key]
        setSubscription( _prev => newSubscription)
        setCurrencyName( _prev => data.currency_name);
      }
    })
  }

  const setPayCTA = (value) => {
    if(window.submitHandlerMode == 'default') { setPayWithCardCTA( () => value) }
    if(window.submitHandlerMode == 'savedCard') { setPayWithSavedCardCTA( () => value) }}
  const [payWithCardCTA, setPayWithCardCTA] = useState(t('sign_up.submit_payment'));
  const [payWithSavedCardCTA, setPayWithSavedCardCTA] = useState(t('sign_up.please_select_a_card'));

  const setPayButtonDisabled = (value) => {
    if(window.submitHandlerMode == 'default') { setPayWithCardDisabled( () => value) }
    if(window.submitHandlerMode == 'savedCard') { setPayWithSavedCardDisabled( () => value) }}

  const [payWithCardDisabled, setPayWithCardDisabled] = useState('disabled');
  const [payWithSavedCardDisabled, setPayWithSavedCardDisabled] = useState('disabled');

  const setCardErrorMessage = (value) => {
    if(window.submitHandlerMode == 'default') { setCardErrorMessageDefault( () => value) }
    if(window.submitHandlerMode == 'savedCard') { setCardErrorMessageSavedCard( () => value) }}
  const [cardErrorMessageDefault, setCardErrorMessageDefault] = useState('');
  const [cardErrorMessageSavedCard, setCardErrorMessageSavedCard] = useState('');

  const previewEnabledForMapping = {
    one_credit: t('sign_up.24_hours_preview'),
    five_credits: t('sign_up.10_days_preview'),
    ten_credits: t('sign_up.30_days_preview'),
    monthly: t('sign_up.unlimited_previews'),
    annually: t('sign_up.unlimited_previews')
  }

  useEffect(() => {
    if(cardErrorMessageDefault != '') {
      trackEvent('js_stripe_card_error_message', {
        message: cardErrorMessageDefault,
        stripeReturnURL: window.stripeReturnURL,
      })
    }

  }, [cardErrorMessageDefault])

  const [loading, setLoading] = useState(true);
  const [paymentIntentId, setPaymentIntentId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [paymentIntentCoupon, setPaymentIntentCoupon] = useState('');

  var elements = useRef()
  var paymentElement = useRef()

  const stripe = Stripe(window.stripePublishableKey);

  const paymentElementOptions = { layout: "tabs", defaultValues: { billingDetails: { email: window.currentUserEmail } } };

  const onPaymentElementReady = () => {
    setLoading( () => false );
    paymentElement.current.focus();
    setPayButtonDisabled('');
  }

  const handlePayWithSavedCard = (pm_uuid) => {
    setSubmitHandlerMode('savedCard')

    setPayButtonDisabled('disabled');
    setPayCTA(t('sign_up.please_wait'));
    setCardErrorMessage('');

    PostRequest({
      url: `/charges/payment_intent_add_payment_method/${paymentIntentId}/${pm_uuid}`,
      data: {},
      callback: (data) => {
        elements.current.fetchUpdates()
          .then(function(result) {
            stripe.confirmCardPayment(clientSecret, {
              return_url: window.stripeReturnURL,
              receipt_email: window.currentUserEmail
            }).then(processConfirmResult);
          });
      }
    })
  }

  const processPaymentIntentResult = (paymentIntentId, callback) => {
    PostRequest({
      url: `/charges/process_payment_intent_result/${paymentIntentId}`,
      data: {},
      callback: callback,
    });
  }

  const processConfirmResult = (result) => {
    if(result?.paymentIntent?.status) {
      setPaymentIntentId( () => result.paymentIntent.id );
      processPaymentIntentResult(result.paymentIntent.id, (data) => {
        setCardErrorMessage('');
        setMode('payment_success');
      })
      return;
    }

    if(result.error != undefined) {
      setCardErrorMessage(result.error.message);
      paymentElement.current.clear();

      setPayButtonDisabled('');
      setPayCTA(t('sign_up.submit_payment'));

      return;
    }

  }

  const createPaymentIntent = () => {
    setPayWithCardDisabled(() => 'disabled');
    setPayWithSavedCardDisabled( () => 'disabled')
    setPayWithSavedCardCTA( () => t('sign_up.please_select_a_card') )
    setLoading(() => true );

    PostRequest({
      url: "/charges/create_payment_intent/",
      data: {
        subscription_type: subscription.type,
        currency: subscription.currency,
        code: subscription.coupon_code,
      },
      callback: (data) => {
        elements.current = stripe.elements({
          appearance: appearance,
          clientSecret: data.clientSecret,
        });

        setPaymentIntentId( () => data.payment_intent_id);
        setClientSecret( () => data.clientSecret);
        setPaymentIntentCoupon( () => data.payment_intent_coupon);
        paymentElement.current = elements.current.create("payment", paymentElementOptions);
        paymentElement.current.mount(cardElementRef.current);
        paymentElement.current.addEventListener('ready', onPaymentElementReady);
        paymentFormRef.current.addEventListener('submit', handleSubmit);
        setLoading( () => false );
        setCardErrorMessage( '' );
      },
      403: (data) => {
        setCardErrorMessage(data.error)
        setLoading( () => false );
      }
    });
  }

  useEffect(createPaymentIntent, [subscription])

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitHandlerMode('default')
    setPayButtonDisabled('disabled');
    setPayCTA(t('sign_up.please_wait'));
    setCardErrorMessage('');

    stripe.confirmPayment({
      elements: elements.current,
      confirmParams: {
        return_url: window.stripeReturnURL,
        receipt_email: window.currentUserEmail,
      },
      redirect: 'if_required',
    }).then(processConfirmResult)
  }

  const [promoCodeFormVisible, setPromoCodeFormVisible] = useState(false);
  const showPromoCode = (e) => {
    e.preventDefault();
    setPromoCodeFormVisible(true);
  }

  const [promoCode, setPromoCode] = useState('');

  const applyPromo = (e) => {
    e.preventDefault();
    setUserParam('promocode', promoCode, () => {
      fetchSubscription()
      setPromoCode( () => '');
    })
  }

  useEffect(() => {
    if(!subscription.has_discount) {
      setUserParam('promocode', '')
    }
  }, [subscription])

  const [planDetailsVisible, setPlanDetailsVisible] = useState(false);

  useEffect(setUpPreviewExplainedTooltip, [planDetailsVisible])

  const handlePromoCodeChange = (e) => {
    setPromoCode( () => e.target.value );
  }

  const loadingIcon = () => { return (<><img src={window.loading_icon} /> <br /> <br /></>) }

  const fetchElementUpdates = (data) => {
    if(elements.current == undefined) { feturn }

    elements.current.fetchUpdates()
      .then(function(result) {
        setPayButtonDisabled('');
        setPayCTA(t('sign_up.submit_payment'));
    });
  }

  useEffect(() => {
    if(paymentIntentId == undefined || paymentIntentId == '' || paymentIntentId == null) { return }

    setPayButtonDisabled('disabled');
    setPayCTA(t('sign_up.please_wait'));

    PostRequest({
      url: `/charges/payment_intent_setup_future_usage/${paymentIntentId}/${rememberCard ? 1 : 0}`,
      data: { },
      callback: fetchElementUpdates
    })
  }, [rememberCard])


  return (<>
    <br />
    <br />
    <div className="plan">
      <div className="plan-head">
        <div className="price" style={{"fontSize": "28px"}}>
          { t(`sign_up.${subscription.type}`) }
        </div>
      </div>

      <div className="plan-head">
        <div className="price">
          {subscription.has_discount && <>
            <strike>
              <small>
                <font color="#cccccc"> {subscription.price_without_discount_formatted} </font>
              </small>
            </strike>
            &nbsp;
          </>}

          {subscription.price_formatted}
          {!subscription.recurring && <span> {t('sign_up.one_off')} </span> }
          {subscription.recurring && subscription.type == 'monthly' && <span>  {t('sign_up.per_month')}, {t('sign_up.auto_renew').toLowerCase()} </span> }
          {subscription.recurring && subscription.type == 'annually' && <span> {t('sign_up.per_year')}, {t('sign_up.auto_renew').toLowerCase()} </span> }
        </div>
      </div>

      <div className="plan-head">
        <span className="price" style={{"fontSize": "14px"}}>
          {t(`sign_up.subscription_plan_title_${subscription.type}`) } &nbsp;
          {!planDetailsVisible && <a href="#" onClick={(e) => {e.preventDefault(); setPlanDetailsVisible(() => true) } }> - {t('sign_up.learn_more')} <i className="fa fa-caret-down" aria-hidden="true"></i> </a>}
          {planDetailsVisible && <a href="#" onClick={(e) => {e.preventDefault(); setPlanDetailsVisible(() => false) } }> - {t('sign_up.hide_details')} <i className="fa fa-caret-up" aria-hidden="true"></i> </a>}
          </span>
      </div>

      {planDetailsVisible && <div className="plan_details">
        <span dangerouslySetInnerHTML={{__html: t('sign_up.professional_dielines')}}></span> <br />
        {subscription.recurring && <><span dangerouslySetInnerHTML={{__html: t('sign_up.manufacturing_cost_calculator')}}></span> <br /> </>}
        {subscription.recurring && <><span dangerouslySetInnerHTML={{__html: t('sign_up.no_contract')}}></span> <br /></>}
        <span dangerouslySetInnerHTML={{__html: t(`sign_up.subscription_plan_details_${subscription.type}`) }}></span> <br />
        {subscription.time_limited_previews && <><span dangerouslySetInnerHTML={{__html: t('sign_up.time_limited_previews')}}></span> <br /> </>}
        <span dangerouslySetInnerHTML={{__html: previewEnabledForMapping[subscription.type] }}></span>

        {subscription.time_limited_previews && (
          <span className="abc_helper_tooltip">
            <a href="#" className="preview_explained" title={t('sign_up.more_about_previews')}>
              <i className="zmdi zmdi-pin-help"></i>
            </a>
          </span>
        )}

      </div>}

      <div className="plan-head">
        <span className="price" style={{"fontSize": "14px", "borderBottom": "0px"}}>
          <span>
            <span style={{'display': 'inline', "fontSize": "14px"}} dangerouslySetInnerHTML={{__html: t('sign_up.your_card_will_be_debited').replace('%{price}', subscription.transaction_amount_formatted) }}></span>
             &nbsp;
            {!subscription.has_discount && !promoCodeFormVisible && <a style={{"fontSize": "14px"}} href="#" onClick={showPromoCode}> {t('sign_up.got_a_promotion_code')} <i className="fa fa-caret-down" aria-hidden="true"></i> </a>}
          </span>

         {!subscription.has_discount && promoCodeFormVisible && (
          <>
            <input style={{'height': '29px'}} size="20" type="text" value={promoCode} placeholder={t('sign_up.promo_placeholder')} onChange={handlePromoCodeChange} />
            &nbsp;
            <button className="btn simple-btn btn-short" onClick={applyPromo}> {t('sign_up.apply_promo')}</button>
          </>)}

          {subscription.has_discount && (
            <>
            <span style={{"display": "inline"}}> {t('sign_up.promo_code')} </span> {subscription.coupon_code}, &nbsp; <span style={{"display": "inline"}}> {t('sign_up.discount_off')} </span> {subscription.discount_formatted}
          </>)}

        </span>
      </div>

      {savedCards.length > 0 && (
        <div className="shortcode_tabs paymentForm">
          <div className="all_heads_cont">
            <div className="image_upload_popup_tab shortcode_tab_item_title my_tab inactivetab payment_method_tab" data-show="content-saved-cards" id="tab_saved_cards">
              {t('sign_up.saved_cards')}
            </div>
            <div className="image_upload_popup_tab shortcode_tab_item_title my_tab payment_method_tab active" data-show="content-stripe" id="tab_new_card">
              { t('sign_up.other_payment_methods') }
            </div>
          </div>
      </div>)}

      <div className="all_body_cont hidden" id="content-stripe">
        <form ref={paymentFormRef}>
          <div ref={cardElementRef} style={{ "height": "100%" }}></div>

          {false && !loading && !subscription.recurring && <>
            <br />
            <small>
              <input type="checkbox" value="on" name="remember_card" checked={rememberCard ? 'checked': ''} onChange={(e) => setRememberCard(() => e.target.checked)} />
              &nbsp;
              {t('sign_up.remember_this_card_for_future_purchases')}
            </small>
            <br />

          </>}

          <br />

          {cardErrorMessageDefault != '' && <>
            <div id="card-errors" className="well card-errors-react">
              {cardErrorMessageDefault}
            </div>
          </>}

          {!loading && cardElementRef.current && ( <>
            <button className="stripe-button btn btn-default simple-btn" disabled={payWithCardDisabled}>
             {payWithCardCTA}
            </button>
            <br />
            <br />
          </>)}

          {loading && loadingIcon() }
        </form>
      </div>

      {savedCards.length > 0 && (
        <div className="all_body_cont hidden" id="content-saved-cards">
          {!loading && <SavedCards
            cardErrorMessageSavedCard={cardErrorMessageSavedCard}
            handlePayWithSavedCard={handlePayWithSavedCard}
            cards={savedCards}
            setPayWithSavedCardCTA={setPayWithSavedCardCTA}
            payWithSavedCardCTA={payWithSavedCardCTA}
            disabled={payWithSavedCardDisabled}
            setDisabled={setPayWithSavedCardDisabled}
          />}
           {loading && loadingIcon() }
        </div>
      )}
    </div>
  </>)
}

export default PaymentForm;
