import React, { useState, useEffect } from 'react';
import { GetRequest } from 'components/common';

const LayoutPartGroupsWindows = ({ key, config, values, setValue }) => {
  const [layoutPartGroups, setLayoutPartGroups] = useState([]);

  useEffect( () => {
    setLayoutPartGroups( (_prev) => { return window.user_quote.layout_part_group_windows; })
  }, []);

  return (<>
   {layoutPartGroups.length > 0 && (
      layoutPartGroups.map(layoutPartGroup => <LayoutPartGroupForm layoutPartGroup={layoutPartGroup} values={values} setValue={setValue} /> )
    )}
    </>
  )
};

const LayoutPartGroupForm = ({ layoutPartGroup, values, setValue }) => {
  useEffect( () => {
    var event = new CustomEvent('quote_builder.layout_part_group_window_changed', { "detail": {
      code: layoutPartGroup.code,
      value: values[layoutPartGroup.attribute_name],
    } });

    document.dispatchEvent(event);
  }, [ values[layoutPartGroup.attribute_name] ]);

  const setValueWrapped = (e) => {
    setValue(layoutPartGroup.attribute_name, e.target.value);
  }

  useEffect( () => {
    for(let i = 0; i < window.user_quote.layout_part_group_windows.length; i++) {
      if (window.user_quote.layout_part_group_windows[i].attribute_name == layoutPartGroup.attribute_name) {
        setValue(layoutPartGroup.attribute_name, window.user_quote.layout_part_group_windows[i].value);
      }
    }
  }, [])

  return (
   <tr key={layoutPartGroup.attribute_name}>
      <td>
        {(layoutPartGroup.code == '_default' || layoutPartGroup.name == undefined) && t('quote_builder.has_windows_question_no_group_name')}
        {layoutPartGroup.code != '_default' && layoutPartGroup.name != undefined && t('quote_builder.has_windows_question').replace('%s', layoutPartGroup.name.toLowerCase()) }
      </td>
      <td align="right">
       <div className="input-group">
         <select value={values[layoutPartGroup.attribute_name]}
            onChange={setValueWrapped}
            name={layoutPartGroup.code}
            className="form-control general-frm-element">
            <option key='none' value='none'> {t('quote_builder.windows_none')} </option>
            <option key='open' value='open'> {t('quote_builder.windows_open')} </option>
            {window.grand_parent_group_code == 'karton' && <option key='cellophane' value='cellophane'> {t('quote_builder.windows_cellophane')} </option>}
          </select>
        </div>
      </td>
    </tr>
  )
};

export default LayoutPartGroupsWindows;
