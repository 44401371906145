import React, { useState, useRef, useContext, useEffect }  from 'react';

const SavedCards = ({ cards, payWithSavedCardCTA, setPayWithSavedCardCTA, disabled, setDisabled, handlePayWithSavedCard, cardErrorMessageSavedCard }) => {
  const [pm_uuid, set_pm_uuid] = useState('');

  const selectCardForPayment = function(e) {
    e.preventDefault();
    let pm_uuid = e.target.dataset.uuid;
    let expiry = e.target.dataset.expiry;
    let last4 = e.target.dataset.last4;
    let card_type = e.target.dataset.card_type.toUpperCase();

    $(".saved-card-btn").removeClass("selected-card");
    $("#card_uuid_" + pm_uuid).addClass("selected-card");

    set_pm_uuid(() => pm_uuid);

    let wording = t('sign_up.paywith_selected_card').replace('%{last4}', last4).replace('%{card_type}', card_type).replace('%{expiry}', expiry)
    setPayWithSavedCardCTA(() => wording );
    setDisabled('');
  }

  useEffect(() => {
    $(".saved-card-btn").click(selectCardForPayment);
  }, [])


  return (
    <div>
      <div>
        {cards.map((saved_card) => {
          return <SavedCard key={saved_card['uuid']} saved_card={saved_card} />
        })}
     </div>

      {cardErrorMessageSavedCard != '' && <>
        <div id="card-errors" className="well card-errors-react">
          {cardErrorMessageSavedCard}
        </div>
      </>}

      <button className="stripe-button-saved-card btn btn-default simple-btn" disabled={disabled} id="pay_with_saved_card_btn" onClick={ () => { handlePayWithSavedCard(pm_uuid)} }>
        {payWithSavedCardCTA}
      </button>

  </div>
  )
}

const SavedCard = ({ saved_card }) => {
  return (
    <div key={saved_card['uuid']} id={`card_uuid_${saved_card['uuid']}`} className={`credit-card ${saved_card['card_type']} selectable saved-card-btn`} data-uuid={saved_card['uuid']} data-expiry={saved_card['expiry']} data-last4={saved_card['last4']} data-card_type={saved_card['card_type']}>
      <div className="credit-card-last4" data-uuid={saved_card['uuid']} data-expiry={saved_card['expiry']} data-last4={saved_card['last4']} data-card_type={saved_card['card_type']}>
        {saved_card['last4']}
      </div>

      <div className="credit-card-expiry" data-uuid={saved_card['uuid']} data-expiry={saved_card['expiry']} data-last4={saved_card['last4']} data-card_type={saved_card['card_type']}>
        {saved_card['expiry']}
      </div>
  </div>
  );
}

export default SavedCards;
