import React, { useState, createContext, useContext} from 'react';
import LayoutNavSectionList from 'components/layout/LayoutNavSectionList.jsx';
import LayoutDownloadButtonsSection from 'components/layout/LayoutDownloadButtonsSection.jsx';
import LayoutContext from 'components/contexts/LayoutContext.jsx';

const ShowLayoutPage = ( {navSections, dimensionGroups, layoutOptions, formulaOptions, optionsUserEntered, unit, locale, theme, btn_action, dimension_texts_on_layouts_enabled} ) => {
  const LayoutContextValues = {
    dimensionGroups: dimensionGroups,
    layoutOptions: layoutOptions,
    formulaOptions: formulaOptions,
    optionsUserEntered: optionsUserEntered,
    unit: unit,
    locale: locale,
    theme: theme,
    btn_action: btn_action,
    dimension_texts_on_layouts_enabled: dimension_texts_on_layouts_enabled,
  }
  return (
     <LayoutContext.Provider value={LayoutContextValues} >
        <div >
          <div className="container">
            <LayoutNavSectionList navSections={navSections} />
          </div>

          <div className="container">
            <LayoutDownloadButtonsSection />
          </div>

        </div>
     </LayoutContext.Provider>
  )
};

export default ShowLayoutPage;
