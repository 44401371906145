import React, { useEffect, useState } from 'react';
import { PostRequest } from 'components/common/index.js';
import { PricingTables, PaymentForm, ChangeCurrency } from 'components/payment/index.js';

const PurchaseRequired = ({ mode, setMode }) => {
  useEffect( () => {
    if(mode != 'pricing_tables') { return; }

    trackEvent('js_purchase_required_popup', {
      component: 'redraw_denied/PurchaseRequired',
      id: window.drawing_id,
      catalog_name: window.drawing_catalog_name,
    });
  }, [mode]);

  const [subscription, setSubscription] = useState({})
  const [currencyName, setCurrencyName] = useState('')

  const handleBackClick = (e) => {
    e.preventDefault();
    setMode('pricing_tables');
  }

  const backButton = () => {
    return (
      <span style={{'display':'inline', 'float':'right'}}>
        &nbsp;
        &nbsp;
        &nbsp;
        <button className="btn simple-btn"  onClick={handleBackClick}>
          <i className="zmdi zmdi-replay"></i> {t('nav.back')}
        </button>
      </span>
    );
  }

  return(<>
    <div className="testimonials-block preview-promo-block">

      {mode == 'pricing_tables' && (<>
      <div className="testimonials-photo preview_promo_header">
        <h1 className="preview-promo-photo">
          <i className="fa fa-check" aria-hidden="true"></i>
        </h1>
      </div>

      <div className="testimonials-text preview-promo-title preview_promo_header">
        <div className="testimonials-name">
          <h3> {t('drawing.dimensions_valid')} </h3>
        </div>

        <div className="testimonials-desc">
          {t('models.please_purchase_credit_or_subscription_to_download')}
          {mode == 'payment_form' && backButton() }
        </div>
      </div>
      </>)}

      <div className="testimonials-desc">
        {mode == 'payment_form' && backButton() }
        {mode == 'pricing_tables' && <br />}
        <ChangeCurrency currencyName={currencyName} mode={mode} />

        {mode == 'pricing_tables' && <PricingTables setMode={setMode} setSubscription={setSubscription} setCurrencyName={setCurrencyName} /> }
        {mode == 'payment_form'   && <PaymentForm setMode={setMode} subscription={subscription} setSubscription={setSubscription} setCurrencyName={setCurrencyName}/> }
      </div>
    </div>
  </>)
}

export default PurchaseRequired;
