import React, { useEffect, useState } from 'react';
import { GetRequest, PostRequest } from 'components/common/index.js';
import UpdatePaymentMethod from 'components/payment/UpdatePaymentMethod.jsx';
import BuySubscriptionButton from 'components/pricing/BuySubscriptionButton.jsx';

const MySubscription = ({ link_to_manage }) => {

  const [subscriptionDescription, setSubscriptionDescription] = useState('');
  const [nextPaymentDate, setNextPaymentDate] = useState('');
  const [nextPaymentAmount, setNextPaymentAmount] = useState('');
  const [per, setPer] = useState('');
  const [isPayPal, setIsPayPal] = useState(false);
  const [isStripe, setIsStripe] = useState(false);
  const [willRenew, setWillRenew] = useState(false);
  const [pastDue, setPastDue] = useState(false);
  const [canUpgradeToAnnual, setCanUpgradeToAnnual] = useState(false);
  const [annualSaving, setAnnualSaving] = useState('');
  const [stripePaymentId, setStripePaymentId] = useState('');
  const [paymentMethodName, setPaymentMethodName] = useState('');
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState('');
  const [currency, setCurrency] = useState('');

  const getMySubscription = () => {
    GetRequest({
      url: '/charge/my-subscription',
      callback: (data) => {
       if(data.subscription != undefined && data.subscription != '' && data.subscription != null) {

        if (data.subscription.is_paypal) {
          isPayPal(() => true)
        }

        if(data.subscription.is_stripe) {
          setIsStripe( () => true);
          setWillRenew( () => data.subscription.will_renew);
          setSubscriptionDescription( () => data.subscription.description);
          setNextPaymentDate( () => data.subscription.next_payment_date);
          setNextPaymentAmount( () => data.subscription.next_payment_amount);
          setPastDue( () => data.subscription.past_due);
          setCanUpgradeToAnnual( () => data.subscription.can_upgrade_to_annual);
          setAnnualSaving( () => data.subscription.annual_price_saving_formatted);
          setStripePaymentId( () => data.subscription.stripe_payment_method_id);
          setStripeSubscriptionId( () => data.subscription.stripe_subscription_id);
          setStripeCustomerId( () => data.subscription.stripe_customer_id);
          setPaymentMethodName( () => data.subscription.payment_method_name);
          setCurrency( () => data.subscription.currency);
          if(data.subscription.subscription_type == 'monthly' )  { setPer( () => t('sign_up.per_month')  ) }
          if(data.subscription.subscription_type == 'annually' ) { setPer( () => t('sign_up.per_year')  ) }
        }

       }
      }
    })
  }

  useEffect( getMySubscription, []);

  if(!isPayPal && !isStripe) {
    return ''
  }

  if(isPayPal) {
    return <>
      <p> {t("sign_up.subscription_managed_on_paypal")} </p> <br />
      <p> {t("sign_up.cancel_on_paypal")} </p>
    </>
  }

  const mySubscription = () => {
    return t('nav.my_subscription') +  ' | '  + subscriptionDescription;
  }

  return (
    <>
    {pastDue && (
      <div className="row">
        <div className="alert alert-warning">
          <span dangerouslySetInnerHTML={{__html: t("sign_up.subscription_past_due")}} ></span>
        </div>
      </div>
    )}

    <table className="table table-striped">
      <tbody>

        <tr>
          <td>
            <h4> {subscriptionDescription != '' && mySubscription() }  | {nextPaymentAmount != '' && nextPaymentAmount != undefined && <> {nextPaymentAmount} / {per}</> } </h4>
            <br />
            {!willRenew && <><strong>  {t('nav.subscription_will_not_be_renewed')}  </strong></> }
            {!pastDue && willRenew && nextPaymentDate != '' && nextPaymentDate != undefined && <><strong> {t('sign_up.next_payment_date')} </strong> {nextPaymentDate} </> }
            {paymentMethodName != '' && <><br /> {paymentMethodName} </>}

            {stripePaymentId != '' && <> <br /><UpdatePaymentMethod stripeCustomerId={stripeCustomerId} stripeSubscriptionId={stripeSubscriptionId} currency={currency} /></>}
            {link_to_manage && stripePaymentId != '' && <> <a className = "btn" href="/my_subscriptions"> {t('payment.manage_subscription')} </a>   </>}

          </td>


          {canUpgradeToAnnual && annualSaving != '' && ( <td align="right">
            <div className="well" style={{ "backgroundColor": "white" }}>
              <span dangerouslySetInnerHTML={{__html: t("sign_up.save_per_year").replace('%{saving}', annualSaving)}} ></span>
              <br />
              <div className="float-right">
                <BuySubscriptionButton type='annual_subscription' subscription_type="none" cta={t('sign_up.change_to_annual_cta')} />
              </div>
              <div className="clear-right"> </div>
            </div>

          </td>)}

        </tr>



      </tbody>
    </table>
    </>
  )
}

export default MySubscription;
