import React, { useState, useEffect, useContext } from 'react';
import LayoutContext from 'components/contexts/LayoutContext.jsx';
import OptionRowInteger from 'components/layout/options/OptionRowInteger.jsx'
import LoadFromLocalStorage from 'components/layout/options/LoadFromLocalStorage.jsx'

const ChooseNumXNumY = ({item}) => {
  const myLayoutContext = useContext(LayoutContext);

  const [num_x, set_num_x] = useState(myLayoutContext.layoutOptions.num_x);
  const [num_y, set_num_y] = useState(myLayoutContext.layoutOptions.num_y);
  const [auto_num_xy, set_auto_num_xy] = useState(myLayoutContext.layoutOptions.auto_num_xy);

  const handleAutoNumXYChange = () => {
    set_auto_num_xy(!auto_num_xy)
  }
  return(
    <div className="LayoutRedrawFormSection">
      <div className="choose_options">
        <h3> <i className="zmdi zmdi-photo-size-select-small"></i></h3>
        <span className="option-label"> {window.wording_layouts_choose_num_x_num_y} </span>
      </div>

      <div className="input-group LayoutRedrawFormInputRow">
        <span className="input-group-addon">
          <span className="float-left" > {window.wording_layouts_full_sheet} </span>
          <input className="float-right form-check-input"
            value="true"
            checked={auto_num_xy}
            type="checkbox"
            onChange={handleAutoNumXYChange}
            name="auto_num_xy"
            />
        </span>
      </div>

     { !auto_num_xy && (
        <OptionRowInteger
          description={window.wording_layouts_options_num_x}
          name="num_x"
          item={item}
          value={num_x}
          disabled={auto_num_xy}
          onChange={set_num_x}
          min_value={1}
          max_value={20}
        />
      )}

      { !auto_num_xy && (
        <OptionRowInteger
          description={window.wording_layouts_options_num_y}
          name="num_y"
          item={item}
          value={num_y}
          disabled={auto_num_xy}
          onChange={set_num_y}
          min_value={1}
          max_value={20}
       />
       )}
    </div>
  );   

}

export default ChooseNumXNumY;
