import React, { createRef, useEffect } from 'react';

const Tooltip = ( { title, style, hint, isSmall } ) => {
  const tooltipRef = createRef();
  useEffect( () => {
    $(tooltipRef.current).tooltip();
  }, []);

const icon = () => {
  if(style == 'info') { return 'zmdi zmdi-info'}

  return 'zmdi zmdi-pin-help';
}

const hintDesc = () => {
  if(hint == undefined) return '';
  if(isSmall) { return <small> {hint} </small> };
  return hint;
};

return (<span className="tooltip-react">
    <a title={title} ref={tooltipRef}>
      <i className={icon()}></i> {hintDesc()}
    </a>
  </span>)
}
export default Tooltip;
