import React, { useState, useEffect}  from 'react';
import { PostRequest } from 'components/common/index.js';
import { CanDownload, NeedsToConfirmEmail, LogInRequired } from 'components/layout/download_buttons/index.js';

const LayoutDownloadButtonsSection = ({}) => {
  const [mode, setMode] = useState('none');
  const [statusFetched, setStatusFetched] = useState(false);

  const [loggedIn, setLoggedIn] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [canDownload, setCanDownload] = useState(false);

  const [promptForCreateAccountEmail, setPromptForCreateAccountEmail] = useState('');

  const delayGetStatus = 500;
  const [quoteBuilderEnabled, setQuoteBuilderEnabled] = useState(false);

  const getStatus = () => {
    const timeOutId = setTimeout(getStatusAction, delayGetStatus);
    return () => clearTimeout(timeOutId);
  }

  const getStatusAction = (onStatusFetched) => {
    let data = {};
    if (window.currentDrawingOptions != undefined) {
      data = window.currentDrawingOptions;
      data.formula_options_present = true
    }

    PostRequest({
      url: '/layouts-button-status/',
      data: data,
      callback: ( data ) => {
        setLoggedIn( _prev => data.logged_in )
        setConfirmed( _prev => data.confirmed )
        setQuoteBuilderEnabled( _prev => data.quote_builder_enabled )
        setStatusFetched( () => true );
        if(data.omniauth_sign_in_failed_account_does_not_exists) {
          setPromptForCreateAccountEmail(() => data.omniauth_email)
        }

        if(onStatusFetched != undefined) { onStatusFetched() }
      }
    })
  }

  useEffect( () => {
    let listen = 'nesting.downloads_buttons_refresh_required';
    document.addEventListener(listen, getStatus);
    return () => { document.removeEventListener(listen, getStatus);}
  }, [])

  useEffect(getStatus, [])

  const onLoginSuccess = () => {
    getStatusAction(() => {
      setTimeout(enableDownloadButtons, delayGetStatus);
    });
  }

  //render starts here, no code below
  if(!statusFetched) {
    return;
  }

  return (
    <div>
      {!loggedIn && (
        <div className="float-right">
          <LogInRequired mode={mode} setMode={setMode} promptForCreateAccountEmail={promptForCreateAccountEmail} callback={onLoginSuccess} />
        </div>
      )}

      {loggedIn && confirmed && (
        <div className="float-right">
          <CanDownload quoteBuilderEnabled={quoteBuilderEnabled} />
        </div>
      )}

      {loggedIn && !confirmed && (
        <div className="float-right">
          <NeedsToConfirmEmail onSuccess={getStatus} />
        </div>
      )}

    </div>
  )
}

export default LayoutDownloadButtonsSection;
