import React from 'react';

const DescriptionLong = ({key, config, disabled, values}) => {
  const v = (key) => { return values[key] }
  const va = (key) => { return values['user_unit_price_attribute.' + key] }

  const formattedString = () =>  {
    for(let i = 0; i < config.descriptions.length; i++) {
      if ( (config.descriptions[i].visible_if == undefined || eval(config.descriptions[i].visible_if) ) && window.locale == 'en' ) {
        return interpolate(config.descriptions[i].en, config.descriptions[i].en_values.map((val) => {
          return decorate(eval(val))} )
        )
      }

      if ((config.descriptions[i].visible_if == undefined || eval(config.descriptions[i].visible_if)) && window.locale == 'tr' ) {
        return interpolate(config.descriptions[i].tr, config.descriptions[i].tr_values.map((val) => {
          return decorate(eval(val))
        } )
        )
      }
    }

    return '';
  }

  const decorate = (val) => {
    return `<strong>${val}</strong>`.replace('NaN', 0.00.toFixed(config.precision == undefined ? 2 : config.precision))
  }

  const price = (val) => {
    return parseFloat(parseFloat(val)).toFixed(config.precision == undefined ? 2 : config.precision) + " " + values.currency;
  }
  const visible = () => {
    return formattedString() != '';
  }

  if(!visible()) {
    return <></>;
  }

  return (
    <tr>
      <td align="left" colSpan="2">
       <br />
        <div className="panel">
          <div className="panel-body">
            <fieldset>
              {config.title != undefined && <legend className="myLegend"> {t(config.title)} </legend> }
              <span dangerouslySetInnerHTML={{__html: formattedString() }}></span>
            </fieldset>
          </div>
        </div>
      </td>
    </tr>
  )
}
export default DescriptionLong;
