import React, { useState, useEffect, useRef }  from 'react';
import { GetRequest } from 'components/common/index.js';
import Constants from 'components/show_drawing/Constants.jsx';
import MyEventListener from 'components/common/MyEventListener.jsx'

const MyDownloadsButton = ( { id, drawing } ) => {
  const [userDownloadCount, setUserDownloadCount] = useState(0)

  const getUserDownloadsCount = () => {
    if(drawing.image == undefined) { return; }

    GetRequest({
      url: `/${id}/user-downloads-btn-count`,
      callback: (data) => {
        setUserDownloadCount(() => data.count);
      }
    })
  }

  const handleShowDownloads = (e) => {
    e.preventDefault();
    setUpUserDownloadsBtn()

    if(userDownloadCount > 0) {
      window.drawing_id = id;
      fetchUserDownloads()
    }
  }

  // useEffect
  useEffect(getUserDownloadsCount, [id, drawing]);
  // useEffect

  MyEventListener( [
    {
      key: Constants.update_user_downloads_count_required,
      callback: getUserDownloadsCount,
    }]
  )

  if(userDownloadCount == 0) { return }

  return (
    <div className="inner">
      <div className="child float-right">
       <button className="btn btn-default btn-lg download-btn" onClick={handleShowDownloads}>
        <span className="icon-download"></span> &nbsp; { t("models.my_downloads") }  &nbsp;
        <span className="badge badge-light" id="user_download_count_cont" style={{"backgroundColor":"#eb5424", "fontWeight":"bold"}}>
        {userDownloadCount}
       </span>
       </button>
      </div>
    </div>
  )
}

export default MyDownloadsButton;