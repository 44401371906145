import React, { useState, useEffect, useRef }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import AddAWindowForm from 'components/show_drawing/redraw_form/add_windows/AddAWindowForm.jsx';
import { ContentPopup } from 'components/ContentPopup.jsx';

const AddWindowsPopup = ( { id, addWindowsVisible, setAddWindowsVisible, GetRequestPath, unit, currentDrawingOptions, optionsUserChanged, addToOptionsUserChanged, addAddOnToDieline, removeAddOnFromDieline, addOnsAsQueryStr} ) => {
  const [title, setTitle] = useState(t('drawing.add_a_window_title'))

  const popupRef = useRef();
  const popupContent = () => {
    let callback = () => {
      popupRef.current.style.display = "none";
      setAddWindowsVisible( () => false );
    };

    let content = <>
      <h4> {title} </h4>

      <AddAWindowForm
        id={id}
        GetRequestPath={GetRequestPath}
        unit={unit}
        currentDrawingOptions={currentDrawingOptions}
        setTitle={setTitle}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChanged}
        addAddOnToDieline={addAddOnToDieline}
        removeAddOnFromDieline={removeAddOnFromDieline}
        close={callback}
        addOnsAsQueryStr={addOnsAsQueryStr}
      />
    </>

    return <ContentPopup
      contextClass="wrapper"
      myRef={popupRef}
      autoOpen={true}
      content={content}
      cancelButton={false}
      beforeClose={callback}
      notWide={false}
    />;
  }

  return (
    <div>
      { addWindowsVisible && popupContent() }
    </div>
  )
}

export default AddWindowsPopup;
