import React, { useState, useEffect, useRef }  from 'react';
import { GetRequest, PostRequest } from 'components/common/index.js';
import AddOnOptions from 'components/show_drawing/redraw_form/add_windows/AddOnOptions.jsx';
import ValidationRule from 'components/show_drawing/ValidationRule.jsx';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import Constants from 'components/show_drawing/Constants.jsx';
import MyEventListener from 'components/common/MyEventListener.jsx'

const AddAWindowForm = ( { id, GetRequestPath, unit, currentDrawingOptions, setTitle, optionsUserChanged, addToOptionsUserChanged, addAddOnToDieline, removeAddOnFromDieline, addOnsAsQueryStr, close } ) => {
  const [svgContent, setSvgContent] = useState("<svg />");
  const [canBeAdded, setCanBeAdded] = useState(true);
  const [redrawInProgress, setRedrawInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addOnId, setAddOnId] = useState(0);
  const [logInRequired, setLogInRequired] = useState(false);
  const [rules, setRules] = useState([]);
  const [redrawRequested, setRedrawRequested] = useState(false)

  const formRef = useRef();
  const optionsRef = useRef();
  const redrawRef = useRef();
  const ValidationRuleRef = useRef();

  const [svgHeight, setSvgHeight] = useState(400)
  const requestInProgress = useRef(false);

  const checkIfAddonsCanBeAdded = () => {
    if(requestInProgress.current) { return }
    requestInProgress.current = true;

    GetRequest({
      url: "/drawing_check_can_add_addons/" + GetRequestPath(),
      callback: (data) => {
        setCanBeAdded( () => data.result);
        setLoading( () => false );
        if(data.result) {
          getAddOnList();
        }
      }
    })
  }

  const getAddOnList = () => {
    PostRequest({
      url: "/add_on_list/" + id + "?" + addOnsAsQueryStr(),
      data: {
        user_options: currentDrawingOptions,
      },
      callback: (data) => {
        setSvgContent( () => data.svg_content )
        requestInProgress.current = false;
      }
    })
  }

  const selectAddOn = (elem, useCurrentDrawingOptions) => {
    setAddOnId( () => $(elem).data("add-on-drawing-id") );
  }

  const renderSvg = () => {
    updateSvgHeight();

    if( $("#add_on_list").length == 0 ) { return; }

    svgPanZoom('#add_on_list', {
      zoomEnabled: false,
      controlIconsEnabled: false,
      fit: true,
      center: true,
      panEnabled: false,
      contain: true
    }).zoom(0.90);

    if(addOnId == 0) {
      $(".add-on-rect").hover(function() { highlightAddOn($(this))}, function() { { unhighlightAddOn($(this))} } );
      $(".add-on-rect").click(function() { selectAddOn(this, true); });
    }
  }

  const redrawAddnBadRequest = (data) => {
    setRules( () => data.rules )
    setRedrawInProgress( () => false )
  }

  const redrawAddOn = () => {
    setRedrawRequested( () => false )
    if(addOnId == 0) { checkIfAddonsCanBeAdded(); return; }

    let useCurrentDrawingOptions = true;
    const addOnOptionsAsObject = objectifyForm($(formRef.current));
    if(Object.keys(addOnOptionsAsObject).length == 0) {
      setTimeout(redrawAddOn, 100);
      return
    }

    const dataToPost = Object.assign({}, addOnOptionsAsObject);
    dataToPost['user_options'] = useCurrentDrawingOptions ? currentDrawingOptions : window.proposedDrawingOptions; // @todo remove window dependency
    dataToPost['options_user_entered'] = window.userOptions; // @todo remove window dependency
    dataToPost['unit'] = unit

    if(!dataToPost['user_options'].hasOwnProperty("options_user_changed") ) {
      dataToPost['user_options'] = { options_user_changed: optionsUserChanged.join(",") }
    } else {
      dataToPost['user_options']['options_user_changed'] = optionsUserChanged.join(",");
    }

    setRedrawInProgress( () => true )
    PostRequest({
      url: "/redraw_add_on/" + id + "/" + addOnId,
      data: dataToPost,
      callback: (data) => {
        data.recommended_values.map( (recommendedValue) => {
          emitEvent(Constants.drawing_option_recommended_value_changed_add_ons, { id: id, name: recommendedValue.name, value: recommendedValue.value } );

          if(!recommendedValue.user_changed) {
            addOnOptionsAsObject[recommendedValue.name] = recommendedValue.value;
          }
        } )

        setSvgContent( () => data.svg_content)
        setCurrentAddOnOptions( () => addOnOptionsAsObject)
        setRules( () => [] )
        setRedrawInProgress( () => false )
        handleOptionsWithActivationFormula();
        emitEvent(Constants.drawing_addon_redraw_completed, { id: id })
      },
      400: redrawAddnBadRequest
    })
  }

  const updateTitle = () => {
    setTitle( () => ( addOnId > 0 ) ? t('three_d.options_and_dimensions') : t('drawing.add_a_window_title') )
  }

  const handleCancel = (e) => {
    e.preventDefault();
    setAddOnId( () => 0 );
  }

  const handleRedraw = (e) => {
    e.preventDefault();
    redrawAddOn();
  }

  const onRedrawRequiredMsg = (msg) => {
    if(msg.detail.id == id) {
      redrawAddOn();
    }
  }

  const controlButtons = () => {
    return <table className={styles.add_on_control_buttons}>
      <tbody>
        <tr>
          <td align="left">
            <button className="btn btn-default" onClick={handleRedraw} ref={redrawRef}>
              <div className="promo-join-now">
               {t('models.redraw')}
              </div>
            </button>
          </td>

          <td align="right">

            <button className="btn btn-default" onClick={handleCancel}>
              <div className="promo-join-now">
                {t('models.cancel')}
              </div>
            </button>

          </td>
        </tr>
      </tbody>
    </table>
  }

  const handleOptionsWithActivationFormula = () => {
    const currentDrawingOptionsToSend = Object.assign({}, currentDrawingOptions);

     // we already have add_an, now updating it with new values so, we need to remove the old values from the current_drawing_options
    // to see if we need to hide an option
    for (const [proposedKey, proposedValue] of Object.entries( objectifyForm($(formRef.current)) )) {
      if(currentDrawingOptionsToSend[proposedKey] != undefined) {
        delete currentDrawingOptionsToSend[proposedKey]
      }
    }

    const currentFormData = $(formRef.current).serialize();
    const current_drawing_options = new URLSearchParams(currentDrawingOptionsToSend).toString();

    GetRequest({
      url: "/drawing_option_activation_add_ons/" + id + "/" + addOnId + "?format=json&" + currentFormData + "&" + current_drawing_options,
      callback: (data) => {
        data.visible_options.map( (_name) =>   { emitEvent(Constants.drawing_option_visible_updated, { drawing_id: id, name: _name, visible: true }) } )
        data.invisible_options.map( (_name) => { emitEvent(Constants.drawing_option_visible_updated, { drawing_id: id, name: _name, visible: false }) } )
      }
    })

  }

  const [currentAddOnOptions, setCurrentAddOnOptions] = useState({})
  const addAddOnToDielineClick = (e) => {
    e.preventDefault();
    if(addOnId == 0) { return; }
    addAddOnToDieline(addOnId, currentAddOnOptions)
    setAddOnId( () => 0);
    close();
  }

  const removeAddOnFromDielineClick = (e) => {
    e.preventDefault();
    if(addOnId == 0) { return; }
    removeAddOnFromDieline(addOnId)
    setAddOnId( () => 0);
    close();
  }

  const addToDrawingButton = () => {
    return <div style={{ paddingTop : "20px" }}>
      <button className="simple-btn btn btn-default" onClick={addAddOnToDielineClick} disabled={rules.length > 0}>
        <div className="promo-join-now">
          <i className="fa fa-plus" aria-hidden="true"></i> { t("models.add_add_on_to_drawing") }
        </div>
      </button>
    </div>
  }

  const updateAddOnButton = () => {
    return <button className="simple-btn btn btn-default" onClick={addAddOnToDielineClick} disabled={rules.length > 0}>
      <div className="promo-join-now">
        <i className="fa fa-floppy-o" aria-hidden="true"></i> { t("models.update_add_on_in_drawing") }
      </div>
    </button>
  }

  const removeAddOnButton = () => {
    return <button className="btn btn-default" onClick={removeAddOnFromDielineClick}  disabled={rules.length > 0}>
      <div className="promo-join-now">
        <i className="fa fa-trash-o" aria-hidden="true"></i> { t("models.remove_add_on_from_drawing") }
      </div>
    </button>
  }

  const addOnIncludedInDieline = () => {
    if(currentDrawingOptions == undefined || currentDrawingOptions['add_ons'] == undefined) { return false }
    return currentDrawingOptions['add_ons'].includes(addOnId);
  }

  const updateSvgHeight = () => {
    let newH =  addOnId > 0 ? $(optionsRef.current).css("height") : 400;
    setSvgHeight( () => newH);
    $("#add_on_list").attr("height", newH);
    $("#add_on_list").attr("width", "420px");
  }

  // useEffect starts
  useEffect( checkIfAddonsCanBeAdded, [] )
  useEffect( renderSvg, [svgContent] )
  // useEffect( redrawAddOn, [addOnId] )
  useEffect( updateTitle, [addOnId] )
  useEffect( () => {
    if(redrawRequested) {
      redrawAddOn();
    }
  }, [redrawRequested] )
  // useEffect ends

  MyEventListener([
    {
      key: Constants.drawing_add_on_redraw_required,
      callback: onRedrawRequiredMsg
    }
  ])

  if(loading) { return <div> ... </div> }
  if(!canBeAdded) { return <div className="alert alert-danger fade in"> <strong> {t('drawing.cannot_add_add_on')} </strong> </div> }

  return (
    <div>
      {rules.length > 0 && <div style={{marginLeft: '-20px'}}>
        <ValidationRule id={id} rules={rules} recommendations={[]} logInRequired={logInRequired} isAddOnRule={true} myRef={ValidationRuleRef} />
        <br />
      </div>}

      {addOnId != 0 && (
        <div className={styles.add_on_options} ref={optionsRef}>
          <form ref={formRef}>
            <AddOnOptions
              id={id}
              addOnId={addOnId}
              addOnsAsQueryStr={addOnsAsQueryStr}
              unit={unit}
              setRedrawRequested={setRedrawRequested}
              handleOptionsWithActivationFormula={handleOptionsWithActivationFormula}
              optionsUserChanged={optionsUserChanged}
              addToOptionsUserChanged={addToOptionsUserChanged}
              currentDrawingOptions={currentDrawingOptions}
              formRef={formRef}
            />
            <br />
            { controlButtons() }
          </form>
        </div>
      )}

      <div className={`${addOnId != 0 ? styles.add_on_svg : ''}` }>
        <div className={`main_svg_add_on main_svg_black_bg ${redrawInProgress ? styles.svg_in_progress : ''}`} style={{ height: svgHeight }}>
          <center>
            <div id="add_ons_svg" dangerouslySetInnerHTML={{__html: svgContent}}></div>
          </center>
        </div>

       { addOnId != 0 && !addOnIncludedInDieline() && <center> {addToDrawingButton()} </center> }
      </div>

      { addOnId != 0 && addOnIncludedInDieline() && <> <div className="clear-right"> </div>  <br /> <table width="100%">
          <tbody>
            <tr>
              <td> {updateAddOnButton()} </td>
              <td> {removeAddOnButton()} </td>
            </tr>
          </tbody>
        </table></>
      }

    </div>
  )
}

export default AddAWindowForm;
