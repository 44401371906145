import React, {useEffect} from 'react';
import LoadFromLocalStorage from 'components/layout/options/LoadFromLocalStorage.jsx'

const OptionRowIntegerFreeForm = ( {item, description, name, value, onChange, onKeyUp, doNotLoadFromLocalStorage}) => {

  useEffect(() => {
    if(doNotLoadFromLocalStorage) { return; }
    LoadFromLocalStorage(item, name, onChange)
  } ,[]);

  return (
    <div className="LayoutRedrawFormInputRow">
      <div className="input-group short-text">
        <span className="input-group-addon">
         {description}
        </span>

        <input
          onChange={ e => onChange(e.target.value)}
          onKeyUp={e => { if(onKeyUp != undefined) { onKeyUp(e.target.value)} }}
          autoComplete="off"
          type="text"
          className="drawing-option-input form-control input_use_local_storage"
          name={name}
          value={value}
        />
      </div>
  </div>
  );
}

export default OptionRowIntegerFreeForm