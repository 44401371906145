import React, { useState, useEffect } from 'react';
import { GetRequest } from 'components/common';
import { HiddenInput } from 'components/common';

const LayoutPartGroupsWindowsHidden = ({ key, config, values, setValue }) => {
  const [layoutPartGroups, setLayoutPartGroups] = useState([]);

  useEffect( () => {
    setLayoutPartGroups( (_prev) => { return window.user_quote.layout_part_group_windows; })
  }, []);

  return (<>
   {layoutPartGroups.length > 0 && (
      layoutPartGroups.map((layoutPartGroup) => {
        return <LayoutPartGroupFormHidden key={layoutPartGroup.attribute_name} layoutPartGroup={layoutPartGroup} values={values} setValue={setValue} />
      })
    )}
    </>
  )
};

const LayoutPartGroupFormHidden = ({ layoutPartGroup, values, setValue }) => {
  useEffect(() => {
    let listen = 'quote_builder.layout_part_group_window_changed';

    let listener = function(e) {
      if(e.detail.code == layoutPartGroup.code) {
        setValue(layoutPartGroup.attribute_name, e.detail.value);
        setValue('updated_at', Date.now());
      }

    }
    document.addEventListener(listen, listener);

    return () => { document.removeEventListener(listen, listener);}

  }, []);

  return (
    <tr className="hiddenTr">
      <td colSpan="2">
        <HiddenInput
          name={layoutPartGroup.attribute_name}
          value={values[layoutPartGroup.attribute_name]}
        />
      </td>
    </tr>
  )
};

export default LayoutPartGroupsWindowsHidden;
