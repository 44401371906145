import React, { useState, useEffect } from 'react';
import QuoteBuilderForm from 'components/quote_builder/QuoteBuilderForm.jsx';

const Cutting = ({ config, disabled, options, uuid, item, extra_item_title, getExtraItems, navSection, colIndex, rowIndex, disabledReason, navSectionColumnType}) => {
  return (
    <div>
      <QuoteBuilderForm
        config={config.cutting}
        disabled={disabled}
        disabledReason={disabledReason}
        options={options}
        section_uuid={uuid}
        navSectionColumnType={navSectionColumnType}
        item={item}
        extra_title={extra_item_title}
        getExtraItems={getExtraItems}
        navSection={navSection}
        colIndex={colIndex}
        rowIndex={rowIndex}
      />
    </div>
  )
}

export default Cutting;
