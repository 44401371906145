import React, {useState, useEffect, useContext, useRef }  from 'react';
import { MyDownloadsButton } from 'components/show_drawing/download_buttons/index.js';
import LayoutContext from 'components/contexts/LayoutContext.jsx';

const CanDownload = ({quoteBuilderEnabled}) => {
  const myLayoutContext = useContext(LayoutContext);
  const [dtDXF, setDtDXF] = useState(true);
  const [dtSVG, setDtSVG] = useState(true);
  const [dtPDF, setDtPDF] = useState(true);

  const [disabled, setDisabled] = useState ('disabled');

  const handleDownloadDXF = (e) => {
    e.preventDefault();
    show_layout_confirm("dxf");
  }

  const handleDownloadSVG = (e) => {
    e.preventDefault();
    show_layout_confirm("svg");
  }

  const handleDownloadPDF = (e) => {
    e.preventDefault();
    show_layout_confirm("pdf");
  }

  const onEnableRequested = () => {
    setDisabled( () => '' );
  }

  const onDisableRequested = () => {
    setDisabled( () => 'disabled' );
  }

  useEffect( () => {
    let listen = 'nesting.enable_downloads_button_requested';
    document.addEventListener(listen, onEnableRequested);
    return () => { document.removeEventListener(listen, onEnableRequested);}
  }, [])

  useEffect( () => {
    let listen = 'nesting.disable_downloads_button_requested';
    document.addEventListener(listen, onDisableRequested);
    return () => { document.removeEventListener(listen, onDisableRequested);}
  }, [])

  const handleQuoteBuilder = (e) => {
    if(e != undefined) { e.preventDefault() }
    showQuoteBuilderBuildOrListQuotes()
  }

  useEffect(() => {
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('layouts.download_buttons_ready', {}));
    }, 250);
  }, []);

  useEffect(() => {
    if(myLayoutContext.btn_action == 'quote_builder' && disabled == '') {
      setTimeout(showQuoteBuilderBuildOrListQuotes, 2000);
    }
  }, [disabled])

  return (<>
    <div className="downloadButtonscontainer">

      <div className="inner">
        <div className="child">
          <input value="1" type="checkbox" id="dxf_layout_dimension_lines_included" checked={dtDXF ? 'checked': ''} onChange={(e) => setDtDXF(() => e.target.checked)} />
            &nbsp;
          { t("layouts.download_with_dimension_borders")}
          <br />
          <button disabled={disabled} className="btn btn-default btn-lg download-btn" onClick={handleDownloadDXF}>
            <img className="downloadIcon"src={window.dxf_icon} /> &nbsp; {t('models.download_as_dxf')}
          </button>
        </div>
      </div>

      <div className="inner">
        <div className="child">
          <input value="1" type="checkbox" id="svg_layout_dimension_lines_included" checked={dtSVG ? 'checked': ''} onChange={(e) => setDtSVG(() => e.target.checked)} />
            &nbsp;
          { t("layouts.download_with_dimension_borders")}
          <br />
          <button  disabled={disabled} className="btn btn-default btn-lg download-btn" onClick={handleDownloadSVG}>
            <img className="downloadIcon" src={window.svg_icon} /> &nbsp; {t('models.download_as_svg')}
          </button>
        </div>
      </div>

      <div className="inner">
        <div className="child">
          <input value="1" type="checkbox" id="pdf_layout_dimension_lines_included" checked={dtPDF ? 'checked': ''} onChange={(e) => setDtPDF(() => e.target.checked)} />
          &nbsp;
          { t("layouts.download_with_dimension_borders")}
          <br />
          <button disabled={disabled} className="btn btn-default btn-lg download-btn" onClick={handleDownloadPDF}>
            <img src={window.pdf_icon} className="downloadIcon"/> &nbsp; {t('models.download_as_pdf')}
          </button>
        </div>
      </div>

      {quoteBuilderEnabled && (
      <div className="inner">
        <div className="child">
           <br />

          <button disabled={disabled} className="btn btn-default btn-lg download-btn download-btn-quote-builder" onClick={handleQuoteBuilder}>
            {t('nav.quote_builder_cta')}
          </button>

        </div>
      </div>
      )}


    </div>

    <div className="download_instructionsCont">
      <span className="download_instructions" dangerouslySetInnerHTML={{__html: t("drawing.download_instructions_for_layout") }}></span>
    </div>
      </>
  );
}

export default CanDownload;
