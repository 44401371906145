import React, { useState, useEffect }  from 'react';
import { GetRequest } from 'components/common/index.js';
import { OptionLenghtWithUnit, OptionBoolean, OptionIntegerWithOptions, OptionIntegerWithRange, OptionAlignment } from 'components/show_drawing/redraw_form/index.js';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import Constants from 'components/show_drawing/Constants.jsx';

const AddOnOptions = ( {id, addOnId, addOnsAsQueryStr, unit, setRedrawRequested, handleOptionsWithActivationFormula, optionsUserChanged, addToOptionsUserChanged, currentDrawingOptions, formRef} ) => {
  const key = (option) => { return "" + id + option.name + unit; }

  const [options, setOptions] = useState([])
  const fetchOptions = () => {
    // https://diecuttemplates.com.dev/add_on_options/23281/66481?format=json&
    GetRequest({
      url: `/add_on_options/${id}/${addOnId}?format=json&${addOnsAsQueryStr()}`,
      callback: (data) => {
        setOptions( () => data.options)
      }
    })
  }

  const setValues = () => {
    if(options.length == 0) { return }

    for(let i = 0; i< options.length; i++) {
      const option = options[i]
      if(optionsUserChanged.includes(option.name) ) {
        emitEvent(Constants.drawing_option_set_value, { id: id, name: option.name, value: currentDrawingOptions[option.name] } )
      }
    }

    setTimeout(redrawWhenAllValuesAreSet, 250)
  }

  const redrawWhenAllValuesAreSet = () => {
    if(options.length == 0) { return }

    var res = true;
    var formValues =  objectifyForm($(formRef.current));

    for(let i = 0; i< options.length; i++) {
      const option = options[i]
      if(optionsUserChanged.includes(option.name) ) {
        if(parseFloat(currentDrawingOptions[option.name]) != parseFloat(formValues[option.name])) {
          res = false
        }
      }
    }
    res ? setRedrawRequested( () => true ) :   setTimeout(redrawWhenAllValuesAreSet, 100)
  }

  const addToOptionsUserChangedWrapped = (option) => {
    addToOptionsUserChanged(option)
    handleOptionsWithActivationFormula();
  }

  const getOptionRow = (option) => {
    return <div className={styles.row}>
      {getOption(option)}
    </div>
  }

  const getOption = (option) => {
    if(option.input_type == 'length' && option.possible_values.length == 0) {
      return <OptionLenghtWithUnit
        id={id}
        option={option}
        unit={unit}
        key={key(option)}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChangedWrapped}
        setRedrawRequested={setRedrawRequested}
      />
    }

    if(option.input_type == 'integer' && option.possible_values.length == 0) {
      return <OptionIntegerWithRange
        id={id}
        option={option}
        unit={unit}
        key={key(option)}
        setRedrawRequested={setRedrawRequested}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChangedWrapped}
      />
    }

    if(option.input_type == 'boolean') {
      return <OptionBoolean
        id={id}
        option={option}
        unit={unit}
        setRedrawRequested={setRedrawRequested}
        key={key(option)}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChangedWrapped} />
    }

    if(option.input_type == 'alignment') {
      return <OptionAlignment
        id={id}
        option={option}
        unit={unit}
        setRedrawRequested={setRedrawRequested}
        key={key(option)}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChangedWrapped} />
    }

    if(option.input_type == 'integer' && option.possible_values.length > 0) {
      throw new Error("[OptionIntegerWithOptions] is not implemented yet.")
      return <OptionIntegerWithOptions id={id} option={option} unit={unit} key={key(option) } addToOptionsUserChanged={addToOptionsUserChangedWrapped}  />
    }

    throw new Error("[DrawingOptions] getOption returning null")
  }

  // useEffect starts
  useEffect(fetchOptions, [addOnId])
  useEffect(setValues, [options])
  // useEffect ends

  return (
    <div >
      {options.map(getOptionRow) }
    </div>
  )
}

export default AddOnOptions;
