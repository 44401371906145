import React, { useState, useEffect, useRef }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import { ContentPopup } from 'components/ContentPopup.jsx';
import Constants from 'components/show_drawing/Constants.jsx';
import MyEventListener from 'components/common/MyEventListener.jsx'

const SelectFlap = ( {id, setId, drawing, onFlap, theme, as, cta, cta_replace, title, isLast, image} ) => {
  const [showPopUp, setShowPopUp] = useState(false);

  const onClick = (e) => {
    e.preventDefault();
    setShowPopUp( () => true )
  }

  const popupRef = useRef();
  const popupContent = () => {
    let callback = () => {
      popupRef.current.style.display = "none";
      setShowPopUp(() => false);
    };

    const onFlapChanged = (option) => {
      callback();
      setId( () => option.drawing_id )
    }

    const asOption = (option) => {
      let ctaToUse = cta.replace(cta_replace, option.name);
      let onClick = (e) => { e.preventDefault(); onFlapChanged (option) }

      return (<tr>
          {image != '' && <td>
             <a href="#" onClick={onClick}>
              <img style={{ width: "98px" }} className={`additional_feature_img tag_img_${theme}`} src={`https://d2atdwxjx7uc4i.cloudfront.net/images/tags/${image}_${option.id}.png`} />
              </a>
            </td>
          }
          <td> <br /> <a href="#" onClick={onClick} className="btn simple-btn"> {ctaToUse} </a> </td>
        </tr>)
    }

    let content = <>
      <h4> {title} </h4> <br />

      <table className="table table-striped">
        <tbody>
          <tr>
            {image != '' && <td>  <img style={{ width: "98px" }} className={`additional_feature_img tag_img_${theme}`} src={`https://d2atdwxjx7uc4i.cloudfront.net/images/tags/${image}_${drawing[as].id}.png`} /> </td>}
            <td>  <br /> {drawing[as].name} </td>
          </tr>

          {onFlap.map(asOption)}
        </tbody>
      </table>
    </>
    return <ContentPopup
      contextClass="wrapper"
      myRef={popupRef}
      autoOpen={true}
      content={content}
      cancelButton={false}
      beforeClose={callback}
      notWide={true}
    />;
  }

  const onApplyCommand = (msg) => {
    if(msg.detail.id != id) { return }
    let apply_command =  msg.detail.apply_command;
    let parts = apply_command.split('_');
    let last_i = parts.length - 1;
    let apply_command_id  = parts[ last_i ]
    let apply_command_key = parts.slice(0, last_i ).join('_');
    if(as == apply_command_key) {
      onFlap.map( (on_flap) => {
        if(on_flap.id == apply_command_id) {
          setId( () => on_flap.drawing_id)
          trackEvent('js_apply_command_applied', { id: id, apply_command: apply_command, key: apply_command_key, value: apply_command_id } );
        }
      })
    }
  }

  MyEventListener([{
    key: Constants.drawing_apply_command,
    callback: onApplyCommand
  }])

  if (drawing[as] == undefined) { return }

  return (<>
    <div className={`${styles.row} ${styles.clickable}`} onClick={onClick}>
      <span className={`${isLast ? styles.last_tag : ''} input-group-addon input-group-addon-${window.locale} ${styles.input_add_on_for_boolean}`} >

        <span className={`${styles.clickable} ${styles.label}`}> {title} </span>
        <span className={`${styles.clickable} ${styles.floatRight}`}> { drawing[as].name }
          &nbsp;
          <a href="#" onClick={onClick}> <i className="fa fa-pencil-square-o"></i>
            {t('models.change')}
          </a>
        </span>

      </span>
    </div>
    {showPopUp && popupContent()}
   </>)
}

export default SelectFlap;
