import React, { useState, useEffect, useRef }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import Constants from 'components/show_drawing/Constants.jsx';

const ValidationRule = ({ id, rules, recommendations, logInRequired, isAddOnRule, myRef }) => {
  const recoveryApplyTo = (rule) => {
    return <>
      <br /><a href="#" onClick={ (e) => { e.preventDefault(); recoveryApplyToAction(rule) } } className='btn btn-default simple-btn btn-sm'>
      <span dangerouslySetInnerHTML={{ __html: rule.recovery_apply_to.cta }}></span>
      </a>
    </>
  }

  const recoveryApplyToAction = (rule) => {
    emitEvent(Constants.drawing_recovery_set_value_required, { id: id, rule: rule, isAddOnRule: isAddOnRule } )
  }

  const recoverySwapOptions = (rule) => {
    return <a href="#" onClick={ (e) => { e.preventDefault(); recoverySwapOptionsAction(rule) } } className='btn btn-default simple-btn btn-sm' >
      <span dangerouslySetInnerHTML={{ __html: rule.recovery_swap_options_cta }}></span>
    </a>
  }

  const recoverySwapOptionsAction = (rule) => {
    emitEvent(Constants.drawing_recovery_swap_options_required, { id: id, rule: rule, isAddOnRule: isAddOnRule })
  }

  const applyCommand = (command) => {
    emitEvent(Constants.drawing_apply_command, { id: id, apply_command: command })
    trackEvent('js_apply_command_clicked', { id: id, apply_command: command } );
  }

  const recommendationRow = (recommendation) => {
    return (<div className="row">
      <div className={styles.rule_error_cont}>
        <div className="alert alert-info fade in">
          <i className="fa fa-info-circle"> </i> &nbsp;
          <div style={{display: 'inline', fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: recommendation.description }}></div>
          {recommendation.apply_command != undefined && <a className="btn btn-default simple-btn btn-sm" onClick={ (e) => { e.preventDefault(); applyCommand(recommendation.apply_command)} }> { recommendation.cta }  </a>}
          {recommendation.path != undefined && <a href={recommendation.path} target="_blank" className="btn btn-default simple-btn btn-sm"> {recommendation.breadcrumbs} </a> }
        </div>
      </div>
    </div>
    )
  }

  const ruleRow = (rule) => {
    if(rule.recovery_apply_to) {         emitEvent(Constants.drawing_recovery_set_value_highlight_required,    { id: id, rule: rule, isAddOnRule: isAddOnRule }) }
    if(rule.recovery_swap_options_cta) { emitEvent(Constants.drawing_recovery_swap_options_highlight_required, { id: id, rule: rule, isAddOnRule: isAddOnRule })}

    return (
      <div className="row">
        <div className={styles.rule_error_cont}>
          <div className="alert alert-danger fade in">
            <i className="fa fa-exclamation-triangle"> </i> &nbsp;
            <div style={{display: 'inline', fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: rule.description }}></div>

            {rule.recovery_apply_to && recoveryApplyTo(rule) }
            {rule.recovery_swap_options_cta != "" && recoverySwapOptions(rule) }

            {rule.has_recommendations && <span> {t('drawing.please_try_again_with_correct_values_or_follow_recommendation')} </span>}
            {!rule.has_recommendations && (rule.recovery_apply_to || rule.recovery_swap_options.length > 1) && <span> {t('drawing.or_try_again_with_correct_values')} </span>}
            {!rule.has_recommendations && !(rule.recovery_apply_to || rule.recovery_swap_options.length > 1) && <span> {t('drawing.please_try_again_with_correct_values')} </span>}
          </div>
        </div>
      </div>
    )
  }

  const shakeDone = useRef(false);

  const scrollToTop = () => {
     var completeCalled = false
      $('html, body').animate({
        scrollTop: $(myRef.current).offset().top - 120
      }, {
        complete : function(){
          if(!completeCalled){
            completeCalled = true;
            if(!shakeDone.current) {
              $(myRef.current).addClass("shaken");
              setTimeout(function() { $(myRef.current).removeClass("shaken"); }, 200);
              shakeDone.current = true;
            }
          }
        }
      }, 500);
  }

  const trackRecommendations = () => {
    if(recommendations.length == 0) { return }
    trackEvent('js_drawing_recommendation_shown', { id: id, recommendations: recommendations } );
  }

  // useEffect
  useEffect(scrollToTop, [rules, recommendations])
  useEffect(trackRecommendations, [recommendations])
  // useEffect

  return (
    <div ref={myRef}>
      {recommendations.length > 0 && (recommendations.map(recommendationRow) )}

      {rules.map(ruleRow) }
    </div>
  )
}

export default ValidationRule;
