import React, { useState, useEffect }  from 'react';
import Constants from 'components/show_drawing/Constants.jsx';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import MyEventListener from 'components/common/MyEventListener.jsx'
import { OptionsCommon, ShowHideChildOptions } from 'components/show_drawing/redraw_form/index.js';

const OptionIntegerWithRange = ({ id, drawing, option, unit, optionsUserChanged, addToOptionsUserChanged, setRedrawRequested }) => {
  const formattedValue = (value) => { return value }
  const redrawOnChange = true;
  const [isVisible, value, valueAutoCalculated, recommendedValue, onChange, showAbcHelperLine, changed, highlighted, _d_input_add_on_ref] = OptionsCommon( { formattedValue, id, drawing, option, unit, optionsUserChanged, addToOptionsUserChanged, setRedrawRequested, redrawOnChange } )
  const defaultOptions = () => {
    if(option.possible_values.length > 0) {
      return option.possible_values;
    }
    return Array.from({length: option.max_int_value - option.min_int_value + 1}, (_, i) => i + option.min_int_value)
  }
  const [options, _setOptions] = useState(defaultOptions())

  const getShowHideChildOptions = () => {
    if(option.children.length == 0) { return }
    return <ShowHideChildOptions id={id} option={option} />
  }

  if (!isVisible) { return <input type="hidden" name={option.name} value={value} /> }

  return (
    <div className={styles.row}>
      <div className={`input-group ${highlighted ? styles.highlighted : ''}`}>
        <span className={`input-group-addon input-group-addon-${window.locale} ${styles.input_add_on}`} style={{'width': '100%' }}>
          {option.presented_name}
        </span>

        <select
          name={option.name}
          value={value}
          onChange={onChange}
          className={`${styles.select_for_int}`}
          onFocus={showAbcHelperLine}
          >
          {options.map( (i) => <option value={i}> {i} </option>  )}
        </select>

      </div>

      {valueAutoCalculated && <> <small> { t('drawing.this_has_been_automaticly_calculated') } </small> { getShowHideChildOptions()} {option.children.length == 0 && <br /> } </> }
      {!valueAutoCalculated && option.value_formula && recommendedValue &&  <> <small> <div> {t('drawing.choose_option_recommmended')}: {recommendedValue} </div> </small> { getShowHideChildOptions() } </>}
      {!valueAutoCalculated && !(option.value_formula && recommendedValue) &&  <> { getShowHideChildOptions() } </>}
    </div>
    )
}

export default OptionIntegerWithRange;
