import React, { useEffect, useState } from 'react';

import { PostRequest } from 'components/common/index.js';
import PricingTables from 'components/payment/PricingTables.jsx';
import PaymentForm from 'components/payment/PaymentForm.jsx';
import ChangeCurrency from 'components/payment/ChangeCurrency.jsx';

import SignUp from 'components/session/SignUp.jsx';
import SignIn from 'components/session/SignIn.jsx';

const LogInRequired = ({ mode, setMode, promptForCreateAccountEmail }) => {

  useEffect(() => {
    $("#preview_promo_modal > .modal-content").removeClass('modal-content-48');
    return () => { $("#preview_promo_modal > .modal-content").addClass('modal-content-48');}
  }, [])

  const handleBackClick = (e) => {
    e.preventDefault();
    setShowSignUp(() => false)
    setShowSignIn(() => true)
  }

  const backButton = () => {
    return (
      <span style={{'display':'inline', 'float':'right'}}>
        &nbsp;
        &nbsp;
        &nbsp;
        <button className="btn simple-btn"  onClick={handleBackClick}>
          <i className="zmdi zmdi-replay"></i> {t('nav.back')}
        </button>
      </span>
    );
  }
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);

  const [backButtonVisible, setBackButtonVisible] = useState(true);

  const handleShowSignUp = (e) => {
    e.preventDefault();
    setShowSignUp(() => true)
    setShowSignIn(() => false)
  }

  const handleShowSignIn = (e) => {
    e.preventDefault()
    setShowSignIn(() => true)
    setShowSignUp(() => false)
  }

  useEffect(() => {
    if(promptForCreateAccountEmail != '' && promptForCreateAccountEmail != undefined) {
      setShowSignIn(() => true)
      setShowSignUp(() => false)
    }
  }, [promptForCreateAccountEmail])

  return(<>
    <div className="testimonials-block preview-promo-block">

     {true && (<>
      <div className="testimonials-photo preview_promo_header">
        <h1 className="preview-promo-photo">
          <i className="fa fa-check" aria-hidden="true"></i>
        </h1>
      </div>

      <div className="testimonials-text preview-promo-title preview_promo_header">
        <div className="testimonials-name">
          <h4> {t('drawing.dimensions_valid')} </h4>
        </div>

        <div className="testimonials-desc">
          {t('models.credit_or_subscription_required_to_download')} <br />
          {t('models.please_register_or_log_in_to_continue')}
        </div>
      </div>
       <hr />

      {showSignUp && backButtonVisible && (
        <>
          {backButton()}
        </>
      )}

      {showSignUp && (
        <>
          <SignUp setShowSignIn={setShowSignIn} setShowSignUp={setShowSignUp} setBackButtonVisible={setBackButtonVisible} onSuccess={redrawAfterPurchase}/>
        </>)}

      {showSignIn && (
        <>
          <SignIn promptForCreateAccountEmail={promptForCreateAccountEmail} setShowSignIn={setShowSignIn} setShowSignUp={setShowSignUp} onSuccess={redrawAfterPurchase} />
          </>
       )}

      </>)}



    </div>
  </>)
}

export default LogInRequired;
