import React, { useEffect, useState, useRef } from 'react';
import { Tooltip } from 'components/common';
import { ListenCondition } from 'components/quote_builder/form_fields/index.js'

const SelectFormField = ({ config, setValue, value, values, disabled, setFetchInProgress, options, readOnlyValues, register, errors, requestPublishItemOnChange, item, navSection, requestGetExtraItems, d, context}) => {
  const [readOnly, setReadOnly] = useState(false);
  const [readOnlyMessage, setReadOnlyMessage] = useState('');
  const [filteredValues, setFilteredValues] = useState(config.values);
  const v = (key) => { return values[key] }

  useEffect(() => {
    if(config.filters != undefined) {
      beforeFilteredValuesChange();

      for(let i=0; i< config.filters.length; i++) {
        setFilteredValues( (prev) => { return prev.filter( (valuePair) => { return eval(config.filters[i].filter); } ) } )
      }
    }

  }, [])

  useEffect(() => {
    if (readOnlyValues != undefined) {
      readOnlyValues.map((readOnlyValue) => {
        if(readOnlyValue.name == config.name && readOnlyValue.value_eval != undefined) {
          setTimeout( () => {
            let value_eval_result = eval(readOnlyValue.value_eval);
            if(value_eval_result != '' && value_eval_result != undefined) {
              setValue(config.name, (_prev) => { return eval(readOnlyValue.value_eval) });
              setReadOnly(readOnlyValue.make_read_only == undefined ? (_prev) => true :  (_prev) => readOnlyValue.make_read_only );
            }
          }, 250)
        }

        if(readOnlyValue.name == config.name && readOnlyValue.value != undefined) {

          setTimeout( () => {
            setValue(config.name, (_prev) => { return readOnlyValue.value });
            setReadOnly(readOnlyValue.make_read_only == undefined ? (_prev) => { return true; } :  (_prev) => { return readOnlyValue.make_read_only; } );
          }, 250)
        }
      })
    }

  }, [filteredValues])

  const validate = (val) => {
    if(config.validator_eval != undefined) {
      let res = eval(config.validator_eval);
      if(!res) {
        return t(`quote_builder.${config.validator_eval_message}`);
      }
    }
  }

 const valueStore = useRef(value);
  const beforeFilteredValuesChange = () => {
    valueStore.current = value;
  }

  useEffect(() => {
    let valueToSet = valueStore.current != undefined ? valueStore.current : filteredValues[0]?.value;
    let valueToSetFound = false;

    for(let i=0; i< filteredValues.length; i++) {
      if(filteredValues[i].value == valueToSet) {
        valueToSetFound = true;
      }
    }

    if(valueToSetFound) {
      setValue(config.name, (_prev) => { return valueToSet } );
    } else {
      setValue(config.name, (_prev) => { return filteredValues[0]?.value } );
    }
    setValue('updated_at', (_prev) => { return Date.now() } );

  }, [filteredValues] )


  if(config.listen_conditions != undefined) {
    for(let i=0; i < config.listen_conditions.length; i++) {

      useEffect(() => {
        let listener = ListenCondition.listener({
          config: config,
          condition: config.listen_conditions[i],
          item: item,
          navSection: navSection,
          setValue: setValue,
          setReadOnly: setReadOnly,
          setReadOnlyMessage: setReadOnlyMessage,
          setNotAvailable: () => {},
          setNotAvailableReason: () => {},
          requestPublishItemOnChange: requestPublishItemOnChange,
          filteredValues: filteredValues,
          setFilteredValues: setFilteredValues,
          beforeFilteredValuesChange: beforeFilteredValuesChange,
          options: options,
          d: d
        });

        document.addEventListener(config.listen_conditions[i].listen, listener);

        return () => { document.removeEventListener(config.listen_conditions[i].listen, listener);}

      }, []);
    }
  }

  useEffect(() => {
    if(config.get_extra_items_on_change_if == undefined && config.get_extra_items_on_change) {
      requestGetExtraItems( { key: config.key, value: value } );
    }

    if(config.get_extra_items_on_change_if != undefined && eval(config.get_extra_items_on_change_if)) {
      requestGetExtraItems( { key: config.key, value: value } );
    }

    if(config.publish_on_change_key != undefined) {
      var event = new CustomEvent(config.publish_on_change_key, { "detail": value });
      document.dispatchEvent(event);
    }

   if((config.publish_item_on_change && config.publish_item_if_eval == undefined) || (config.publish_item_on_change && eval(config.publish_item_if_eval)) ) {
      requestPublishItemOnChange({ publish_item_key: config.publish_item_key });
    }

  }, [value])

  const setValueWrapped = (e) => {
    setValue(config.name, e.target.value);
    setValue('updated_at', Date.now());
  }

  return (
    <tr>
      <td> {t(config.label)} { config.tooltip_text != undefined && <Tooltip title={t(config.tooltip_text)} /> } </td>
      <td align="right">
        <select value={value}
          name={config.name}
          disabled={readOnly}
          className={`${errors[config.name] ? 'input-error form-control  general-frm-element' : 'form-control  general-frm-element'}`}
          {...register(config.name, {
          validate: validate,
          onChange: setValueWrapped,
        })}
          >
          {filteredValues.map((valuePair) => {
            return <option
              key={valuePair.value}
              value={valuePair.value}> {t(valuePair.label)}
            </option>
          })}
        </select>
         {config.footer_note != undefined && (<><small> {t(config.footer_note)} <br /> </small></>)}
         {errors[config.name] && <small className="error"> {errors[config.name].message} </small> }
         {readOnlyMessage != '' && <small className="q-read-only-message"> {readOnlyMessage} </small> }
      </td>
    </tr>
  )
}

export default SelectFormField;
