import React, { useEffect, useState, useRef, createRef }  from 'react';

const ContentPopup = ({myRef, autoOpen, content, cancelButton, beforeClose, notWide, contextClass, modalStyle, onReady }) => {
  const modalRef = myRef == undefined ? useRef() : myRef;

  const hide = (e) => {
    e.preventDefault();
    if(beforeClose != undefined) { beforeClose(); };
    modalRef.current.style.display = "none";
  }

  const show = () => {
    modalRef.current.style.display = "block";
    if(onReady != undefined) { onReady() }
  }

  useEffect(() => {
    if(autoOpen) { show(); }
  }, []);

  return (
    <div className="modal" ref={modalRef} >
      <div className={`modal-content ${notWide ? '' : 'modal-content-wide'}`} style={modalStyle}>
        <span className="close" onClick={hide} >&times;</span>
        <div className={`${contextClass != undefined ? contextClass : 'Content'}`} > {content} </div>

        {cancelButton && <button onClick={hide}> Cancel </button>}
      </div>
    </div>
  )
}

export {
  ContentPopup
};
