const Constants = {
  SVG_minHeight: (window.innerHeight * 0.72) + 80,
  SVG_maxHeight: (window.innerHeight * 0.72) + 80,
  options_updated: 'show_drawing.options_updated',
  drawing_option_visible_updated: 'show_drawing.drawing_option_visible_updated',
  show_abc_line: 'show_drawing.show_abc_line',
  dimension_texts_included_changed: 'show_drawing.dimension_texts_included_changed',
  drawing_recovery_swap_options_required: 'show_drawing.drawing_recovery_swap_options_required',
  drawing_option_recommended_value_changed: 'show_drawing.drawing_option_recommended_value_changed',
  drawing_option_recommended_value_changed_add_ons: 'show_drawing.drawing_option_recommended_value_changed_add_ons',
  drawing_recovery_set_value_required: 'show_drawing.drawing_recovery_set_value_required',
  drawing_option_set_value: 'show_drawing.drawing_option_set_value',
  drawing_add_on_redraw_required: 'show_drawing.drawing_add_on_redraw_required',
  advanced_options_set_visible: 'show_drawing.advanced_options_set_visible',
  drawing_option_change_completed: 'show_drawing.drawing_option_change_completed',
  drawing_recovery_set_value_highlight_required: 'show_drawing.drawing_recovery_set_value_highlight_required',
  drawing_recovery_swap_options_highlight_required: 'show_drawing.drawing_recovery_swap_options_highlight_required',
  drawing_option_highlight: 'show_drawing.drawing_option_highlight',
  drawing_redraw_completed: 'show_drawing.drawing_redraw_completed',
  drawing_addon_redraw_completed: 'show_drawing.drawing_addon_redraw_completed',
  redraw_on_all_options_change_set_list: 'show_drawing.redraw_on_all_options_change_set_list',
  drawing_svg_updated: 'show_drawing.drawing_svg_updated',
  update_user_downloads_count_required: 'show_drawing.update_user_downloads_count_required',
  drawing_download_started: 'show_drawing.drawing_download_started',
  drawing_download_finished: 'show_drawing.drawing_download_finished',
  drawing_set_values_and_redraw: 'show_drawing.set_values_and_redraw',
  drawing_reset_form: 'show_drawing.drawing_reset_form',
  drawing_add_add_on: 'show_drawing.add_add_on',
  drawing_rules_and_formula_options_do_not_match: 'show_drawing.rules_and_formula_options_do_not_match.add_add_on',
  drawing_apply_command: 'show_drawing.drawing_apply_command',
  drawing_input_label_width: 'show_drawing.input_label_width',
  child_options_visible_change: 'show_drawing.child_options_visible_change',
}

export default Constants;
