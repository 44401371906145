import React, { useEffect } from 'react';

const RedrawLayoutButton = ({uuid, setRedrawRequested, redrawInProgress}) => {
  const handleClick = (e) => {
    e.preventDefault();
    setRedrawRequested(() => true);
  }

  useEffect(() => {
    if(setRedrawRequested != undefined) { setTimeout( () => setRedrawRequested( () => true), 250) }
  }, [uuid])

  return (
    <>
      <button id={`RedrawLayoutButton_${uuid}`} onClick={handleClick} className="btn btn-default btn-lg form-control redraw-btn redraw_btn_layout">
        <img width="40" disabled={redrawInProgress} src={redrawInProgress ? window.img_redraw_in_action : window.img_redraw_ready} />
          {window.wording_redraw}
      </button>
    </>
  )
};

export default RedrawLayoutButton;
