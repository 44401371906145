import React, { useState, useEffect } from 'react';
import { GetRequest } from 'components/common';

const SettingsMenu = ( {activeItem, setActiveItem} ) => {

  const _setActiveItem = (event, item) => {
    event.preventDefault();
    setCookie('quote_builder_default_settings_item', item, 365);
    setActiveItem(item);
    fetchCounts();
  }

  const [counts, setCounts] = useState({});

  const fetchCounts = () => {
    GetRequest({
      url: '/quote_builder_user_unit_price_counts',
      callback: (data) => {
        setCounts( { ...data.counts } );
      }
    })
  };

  useEffect(() => {
    document.addEventListener('quote_builder.user_unit_price_deleted', fetchCounts);
    document.addEventListener('quote_builder.user_unit_price_saved', fetchCounts);
    return () => {
      document.removeEventListener('quote_builder.user_unit_price_deleted', fetchCounts);
      document.removeEventListener('quote_builder.user_unit_price_saved', fetchCounts);
    }
  }, []);

  useEffect(fetchCounts, []);

  const countFor = (key) => {
    if(counts[key] == 0 || counts[key] == undefined ) { return '' }
    return `(${counts[key]})`;
  }

  if(activeItem != undefined && isMobile()) {
    return;
  }

  return(
  <div className="leftMarg15">
    <div className="promo-text-blog"> {t('quote_builder.materials')} </div>
    <ul className="blog-category">
      <li key="carton_material" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'carton_material') } className={`${activeItem == 'carton_material' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_material_cartons')} {countFor('carton_material')}
        </a>
      </li>

      <li key="corrugated_cardboard_material" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'corrugated_cardboard_material') } className={`${activeItem == 'corrugated_cardboard_material' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_material_corrugated_cardboards')} {countFor('corrugated_cardboard_material')}
        </a>
      </li>

      <li key="hard_cardboard_material" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'hard_cardboard_material') } className={`${activeItem == 'hard_cardboard_material' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_material_hard_cardboards')} {countFor('hard_cardboard_material')}
        </a>
      </li>

      <li key="coating_material" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'coating_material') } className={`${activeItem == 'coating_material' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_material_coating')} {countFor('coating_material')}
        </a></li>

      <li key="pvc_material" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'pvc_material') } className={`${activeItem == 'pvc_material' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_material_pvc')} {countFor('pvc_material')}
        </a></li>
    </ul>

    <div className="promo-text-blog"> {t('quote_builder.menu_print')} </div>
    <ul className="blog-category">
      <li key="printing_machine" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'printing_machine') } className={`${activeItem == 'printing_machine' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_machines')} {countFor('printing_machine')}
        </a>
      </li>
    </ul>

    <div className="promo-text-blog"> {t('quote_builder.guillotine_cutting')} </div>
    <ul className="blog-category">
      <li key="guillotine_machine" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'guillotine_machine') } className={`${activeItem == 'guillotine_machine' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_guillotine_machines')} {countFor('guillotine_machine')}
        </a> </li>
    </ul>

    <div className="promo-text-blog"> {t('quote_builder.menu_cutting')} </div>
    <ul className="blog-category">
      <li key="die_cutting_machine" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'die_cutting_machine') } className={`${activeItem == 'die_cutting_machine' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_die_cutting_machines')} {countFor('die_cutting_machine')}
          </a> </li>
      <li key="metal_strip" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'metal_strip') } className={`${activeItem == 'metal_strip' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_metal_strips')} {countFor('metal_strip')}
          </a> </li>
      <li key="cutting_board" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'cutting_board') } className={`${activeItem == 'cutting_board' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_cutting_boards')} {countFor('cutting_board')}
        </a> </li>
    </ul>

    <div className="promo-text-blog"> {t('quote_builder.menu_cellophane')} </div>
    <ul className="blog-category">
      <li key="cellophane_material" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'cellophane_material') } className={`${activeItem == 'cellophane_material' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_materials')} {countFor('cellophane_material')}
          </a> </li>
      <li key="cellophane_machine" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'cellophane_machine') } className={`${activeItem == 'cellophane_machine' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_machines')} {countFor('cellophane_machine')}
        </a> </li>
    </ul>

    <div className="promo-text-blog"> {t('quote_builder.glueing')} </div>
    <ul className="blog-category">
      <li key="glueing_machine" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'glueing_machine') } className={`${activeItem == 'glueing_machine' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.glueing_machines')} {countFor('glueing_machine')}
        </a> </li>
      <li key="glueing_manual_labor" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'glueing_manual_labor') } className={`${activeItem == 'glueing_manual_labor' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.glueing_manual_labor')} {countFor('glueing_manual_labor')}
        </a> </li>
      <li key="glueing_material" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'glueing_material') } className={`${activeItem == 'glueing_material' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.glueing_materials')} {countFor('glueing_material')}
        </a> </li>
    </ul>

    <div className="promo-text-blog"> {t('quote_builder.lamination')} </div>
    <ul className="blog-category">
      <li key="lamination_machine" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'lamination_machine') } className={`${activeItem == 'lamination_machine' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.lamination_machines')} {countFor('lamination_machine')}
        </a> </li>
      <li key="lamination_manual_labor" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'lamination_manual_labor') } className={`${activeItem == 'lamination_manual_labor' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.lamination_manual_labor')} {countFor('lamination_manual_labor')}
        </a> </li>
    </ul>

    <div className="promo-text-blog"> {t('quote_builder.menu_hot_stamping')} </div>
    <ul className="blog-category">
      <li key="hot_stamping_material" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'hot_stamping_material') } className={`${activeItem == 'hot_stamping_material' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_materials')} {countFor('hot_stamping_material')}
        </a> </li>
      <li key="hot_stamping_printing_block" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'hot_stamping_printing_block') } className={`${activeItem == 'hot_stamping_printing_block' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_printing_blocks')} {countFor('hot_stamping_printing_block')}
        </a></li>
      <li key="hot_stamping_machine" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'hot_stamping_machine') } className={`${activeItem == 'hot_stamping_machine' ? 'selected-section menuLink' : 'menuLink' }`} >
        {t('quote_builder.menu_machines')} {countFor('hot_stamping_machine')}
        </a></li>
    </ul>

    <div className="promo-text-blog"> {t('quote_builder.menu_magnet')} </div>
    <ul className="blog-category">
      <li key="magnet_material" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'magnet_material') } className={`${activeItem == 'magnet_material' ? 'selected-section menuLink' : 'menuLink' }`} >
          {t('quote_builder.menu_magnets')} {countFor('magnet_material')}
        </a> </li>
    </ul>

    <div className="promo-text-blog"> {t('quote_builder.menu_coating_labour')} </div>
    <ul className="blog-category">
      <li key="coating_machine" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(e, 'coating_machine') } className={`${activeItem == 'coating_machine' ? 'selected-section menuLink' : 'menuLink' }`} >
        {t('quote_builder.coating_machines')} {countFor('coating_machine')}
        </a> </li>
      <li key="coating_manual_labor" className="pricing_menu"> <i className="fa fa-angle-right"></i>
        <a href="#" onClick={(e) => _setActiveItem(event, 'coating_manual_labor') } className={`${activeItem == 'coating_labour' ? 'selected-section menuLink' : 'menuLink' }`} >
        {t('quote_builder.coating_manual_labor')} {countFor('coating_manual_labor')}
        </a> </li>
    </ul>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

  </div>
  )
}

export default SettingsMenu;