import React, { useState, useEffect } from 'react';
import { GetRequest } from 'components/common';

const CurrencyExchangeRate = ({ inputCurrency, outputCurrency }) => {
  const [disabled, setDisabled] = useState(true);
  const [rate, setRate] = useState(0);

  const handleClick = (e) => {
    e.preventDefault();
    setDisabled((previous) => {return !previous});
  }

  const getRate = () => {
    GetRequest({
      url: `/quote-builder/currency-exchange-rate/${window.user_quote_id}/${outputCurrency}/${inputCurrency}`,
      callback: (data) => {
        setRate(data.value);
      }
    })
  };

  useEffect(getRate, []);

  useEffect(() => {
    if(rate > 0) {
      let event = new CustomEvent('quote_builder.currency_exchange_rate_changed', { "detail":  {
        key: outputCurrency + "-" + inputCurrency,
        value: rate,
      } });
      document.dispatchEvent(event);
    }
  }, [rate]);

  useEffect(getRate, [inputCurrency]);
  return (
   <tr key={outputCurrency}>
      <td> 1 {outputCurrency} </td>
      <td align="right">
       <div className="input-group number_input">
          <input
            type="number"
            className="form-control"
            onWheel={(e) => e.target.blur()}
            size="10"
            autoComplete="off"
            name="abc"
            value={rate}
            onChange={(e) => setRate(e.target.value)}
            disabled={disabled}
          />
          <span className='input-group-addon'>
            <span> {inputCurrency}  </span>
            <button onClick={handleClick} className="exchange-edit-btn">
              <small> (<i className="fa fa-pencil-square-o" aria-hidden="true"></i>
               {disabled && t('quote_builder.edit') }
               {!disabled && t('quote_builder.save') })
              </small>
            </button>
          </span>
        </div>
      </td>
    </tr>
  )

}

export default CurrencyExchangeRate;
