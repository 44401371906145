import React, { useState, useRef } from 'react';
import { MoveableContentPopup } from 'components/MoveableContentPopup.jsx';
import ChooseDielineOptions from 'components/layout/options/ChooseDielineOptions.jsx';
import ChooseAdvancedOptions from 'components/layout/options/ChooseAdvancedOptions.jsx';
import LayoutContext from 'components/contexts/LayoutContext.jsx';

const ShowLayoutPageDebugPanel = ( {item, unit, dimensionGroups, formulaOptions, layoutOptions, optionsUserEntered, locale, theme} ) => {
  const LayoutContextValues = {
    dimensionGroups: dimensionGroups,
    layoutOptions: layoutOptions,
    formulaOptions: formulaOptions,
    optionsUserEntered: optionsUserEntered,
    unit: unit,
    locale: locale,
    theme: theme
  }

  const redraw = (e) => {
    e.preventDefault();
     document.dispatchEvent(new CustomEvent('REDRAW_REQUESTED', { "detail": item.uuid }));
  }

  const content = () => {
    return (<>
       <form id="admin_panel_form">
          <ChooseDielineOptions item={item} member_id={item.id} member_klass={item.klass} />
          <ChooseAdvancedOptions item={item} member_id={item.id} member_klass={item.klass} />
          <button onClick={redraw} className="btn btn-default simple-btn">
            Yeniden Ciz
          </button>
          <br />
          <br />
        </form>
      </>
    )
  };

  const contentPopupRef = useRef();
  const contentPopup = () => {
    let callback = () => {};
    return <MoveableContentPopup myRef={contentPopupRef} autoOpen={true} content={content()} cancelButton={false} beforeClose={callback} title={`${item.klass}/${item.id}`}/>;
  };

  return (
    <LayoutContext.Provider value={LayoutContextValues} >
      {contentPopup()}
    </LayoutContext.Provider>
  )
};

export default ShowLayoutPageDebugPanel;
