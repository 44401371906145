import React, { useState, useRef, useContext, useEffect }  from 'react';

const PopUp = ({content, show, setShow, onClosed }) => {
  const modalRef = useRef();
  const hideF = () => { modalRef.current.style.display = "none"; setShow(false); onClosed() }
  const showF = () => { modalRef.current.style.display = "block"; }

  useEffect(() => {
    if(show) {
      showF();
    }
  }, [show])

  return (
    <div className="modal" ref={modalRef} >
      <div className="modal-content">
        <span className="close" onClick={hideF}> &times; </span>
        {content}
      </div>
    </div>
  )
}

export default PopUp;
