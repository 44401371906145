import React, { useEffect, useRef } from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import MyEventListener from 'components/common/MyEventListener.jsx'

const RedrawButton = ( {id, unit, redrawInProgress, setRedrawRequested }) => {
  const myRef = useRef();

  const handleClick = (e) => {
    e.preventDefault();
    setRedrawRequested( () => true);
  }

  const redrawAfterPurchase = () => {
    setRedrawRequested( () => true);
    $('html, body').animate({
      scrollTop: $(myRef.current).offset().top - 450
    }, 500);
  }

  MyEventListener([{
    key: 'drawing.redraw_after_purchase',
    callback: redrawAfterPurchase,
  }])

  return (
    <div className={styles.row}>
      <button onClick={handleClick} className="btn btn-default btn-lg form-control redraw-btn redraw_btn_layout" ref={myRef} style={{ width: '274px' }}>
        <img width="40" disabled={redrawInProgress} src={redrawInProgress ? window.img_redraw_in_action : window.img_redraw_ready} />
          &nbsp; {t('models.redraw')}
      </button>
      <br />
      <br />
    </div>
  )
};

export default RedrawButton;
