import React, { useState }  from 'react';

import { PostRequest } from 'components/common/index.js';

const UpdatePaymentMethod = ( { stripeCustomerId, stripeSubscriptionId, currency } ) => {

  const [disabled, setDisabled] = useState('');
  const [cta, setCta] = useState(t('sign_up.update_my_card'));
  const stripe = Stripe(window.stripePublishableKey);

  const handleClick = (e) => {
    e.preventDefault();
    setDisabled( () => 'disabled' );
    setCta( () => t('sign_up.please_wait'))

    PostRequest({
      url: `/charges/create_checkout_session/${stripeCustomerId}/${stripeSubscriptionId}`,
      data: { currency: currency },
      callback: ( data ) => {
        setDisabled( () => '' );
        stripe.redirectToCheckout({
          sessionId: data.session_id
        })

      }
    })
  }

  return (
    <span style={{'marginLeft' : '-12px'}}>
      <a className="btn" onClick={handleClick} disabled={disabled} href="#"> {cta} </a>
    </span>
  )
}

export default UpdatePaymentMethod;
