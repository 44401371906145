import React, { useState, useEffect }  from 'react';
import MyEventListener from 'components/common/MyEventListener.jsx'
import { GetRequest } from 'components/common/index.js';

const AddToFavourites = ( { defaultId } ) => {

  const [id, setId] = useState(defaultId);
  const [hidden, setHidden] = useState(true);
  const [fav, setFav] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  var toggle_drawing_favourite = function (drawing_id) {
    if(drawing_id == id) { setFav( () => true ) }
  };

  const getUserFavourites = () => {
    setFav ( () => false )
    GetRequest({
      url:  "/user_favourites",
      callback: (data) => {
        setHidden( () => false )
        data.forEach(toggle_drawing_favourite);
      },
      401: () => {
        setHidden( () => true )
      }
    })
  }

  const handleClick = (e) => {
    e.preventDefault();

    $.ajax({
      type: "POST",
      url: "/user_favourites",
      data: {
        id: id,
        value: !fav,
        authenticity_token: $('meta[name=csrf-token]').attr('content')
      },
      success: function () {
        setFav( () => !fav )
        setTimeout( () => { setInProgress( () => false ) }, 300 );

      }
    });
  }

  // useEffect
  useEffect(getUserFavourites, [id])
  // useEffect

  MyEventListener([
    {
      key: 'user.session_started',
      callback: () => { setTimeout(getUserFavourites, 250) }
    }, {
      key: 'window.drawing_id_changed',
      callback: (msg) => { setId( () => msg.detail.id ) }
    }
  ])

  return <div>
    {!hidden && <span style={{marginLeft: '0px'}}
      onClick={handleClick}
      title={ t('my_favourites.add_to_favourites') }
      className={`ribbon ribbon-on-the-left-v2 ${fav ? 'ribbon-on' : ''} ${inProgress ? 'ribbon-in-progress' : ''}`}></span>}
  </div>
}

export default AddToFavourites;

