import React, { useState, createContext, useContext, useEffect} from 'react';
import QuoteBuilderNavSectionList from 'components/quote_builder/QuoteBuilderNavSectionList.jsx';

const ShowQuotePage = ( {config, payload, presentedOptions, navSections} ) => {
  const [loading, setLoading] = useState(true);
  const REQUEST_COUNT_CHANGED = 'quote_builder.request_count_changed'

   useEffect(() => {
    let listener = function(e) {
      if(window.quote_builder_request_count != undefined && window.quote_builder_request_count == 0)
        setLoading(false);
    }
    document.addEventListener(REQUEST_COUNT_CHANGED, listener);
    return () => { document.removeEventListener(REQUEST_COUNT_CHANGED, listener);}
  }, []);

  return (
    <> {loading && <div id="quote-builder-looading-cont">  <h1 style={{"color": "#cfcfcf"}}>Loading <img src={window.loading_image_url} height="50px" /></h1> </div>}
    <div className={`container ${loading ? 'quote-builder-looading' : '' }`}>
      <QuoteBuilderNavSectionList config={config} navSections={navSections} payload={payload} presentedOptions={presentedOptions} />
    </div>
    </>
  )
};

export default ShowQuotePage;


