import React from 'react';
import { ValidatorFactory } from 'components/common';

const LengthInput = (props) => {
  const validate = (val) => {
    if(props.validators != undefined) {
      for(let i=0; i< props.validators.length; i++) {
        let validator = props.validators[i];

        if(!(ValidatorFactory(validator, val) || ValidatorFactory(validator, props.value)) ) {
          return t(`quote_builder.validator_${validator}_message`);
        }
      }
    }

    if(props.value >= 0 ) { return; }
    if(val < 0) { return t('quote_builder.please_enter_a_valid_value');}
  }

  const formatValue = (e) => {
    let toFixedValueMapping = {
      in: 4,
      mm: 2,
      square_meter: 4,
      square_feet: 4,
      square_inch: 4,
      square_centimetre: 4,
    };
    let toFixedValue = toFixedValueMapping[props.unit] || 4;
    let toFormat = parseFloat(e.target.value);
    let formatted = (Number.isNaN(toFormat) ? 0 : toFormat).toFixed(toFixedValue);
    let ev = { target: { value: formatted} };
    props.onChange(ev);
  }

  return (
    <>
    <div className="input-group number_input general-frm-element">
      <input
        type="number"
        onWheel={(e) => e.target.blur()}
        className={`${props.errors[props.name] ? 'input-error form-control' : 'form-control'} ${props.class_name != undefined ? props.class_name : 'general-frm-element'}`}
        size="10"
        autoComplete="off"
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        {...props.register(props.name, {
          required: window.t_price_calculator_required,
          validate: validate,
          onChange: props.onChange,
          onBlur: formatValue,
        })}
        />
        <span className={props.errors[props.name] ? 'input-error input-group-addon' : 'input-group-addon'}>
          <span> {props.unit} </span>
        </span>
    </div>
   {props.errors[props.name] && <small className="error"> {props.errors[props.name].message} </small>}
    </>
  )
}

export default LengthInput
