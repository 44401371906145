import React, { useState, useEffect }  from 'react';

const Blank = ( { } ) => {

  return (
    <div >
       Blank
    </div>
  )
}

export default Blank;
