import React from 'react';
import { StringInput, Tooltip } from 'components/common';

const StringFormField = ({ config, value, setValue, register, errors, disabled, setFetchInProgress, email }) => {

  const onChange = (e) => {
    setValue(config.name, e.target.value);
    if(config.publish_on_change_key != undefined) {
      var event = new CustomEvent(config.publish_on_change_key, { "detail": e.target.value });
      document.dispatchEvent(event);
    }
  }

  return (
    <tr>
      <td> {t(config.label)} { config.tooltip_text != undefined && <Tooltip title={t(config.tooltip_text)} /> }</td>
      <td align="right">
        <StringInput
          name={config.name}
          placeholder={config.placeholder}
          register={register}
          errors={errors}
          email={email}
          class_name={config.class_name}
          validators={config.validators != undefined ? config.validators : []}
          value={value}
          onChange={onChange}
        />
      </td>
    </tr>
  )
}

export default StringFormField;
