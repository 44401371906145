import React, { useState,useEffect } from 'react';

import { ValidatorFactory } from 'components/common';

const NumberWithUnitInput = (props) => {
  const validate = (val) => {

    if(props.validators != undefined) {
      for(let i=0; i< props.validators.length; i++) {
        let validator = props.validators[i];
        if(!(ValidatorFactory(validator, val) || ValidatorFactory(validator, props.value)) ) {
          return t(`quote_builder.validator_${validator}_message`);
        }
      }
    }

    if(props.value >= 0 ) { return; }
    if(val < 0) { return t('quote_builder.please_enter_a_valid_value'); }
  }

  const formatValue = (e) => {
    props.integerOnly ? formatValueInteger(e.target.value) : formatValueDecimal(e.target.value);
  }

  const formatValueDecimal = (value) => {
    if(props.integerOnly) { return; }
    let toFormat = parseFloat(value);
    let formatted = (Number.isNaN(toFormat) ? 0 : toFormat).toFixed(2);
    let ev = { target: { value: formatted} };
    props.onChange(ev);
  }

  const formatValueInteger = (value) => {
    if(!props.integerOnly) { return; }

    let toFormat = parseFloat(value);
    let formatted = (Number.isNaN(toFormat) ? 0 : toFormat).toFixed(0);
    let ev = { target: { value: formatted} };
    props.onChange(ev);
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      props.onChange(e);
    }
  }
  return (
    <>
      <div className={`input-group number_input ${props.class_name != undefined ? props.class_name : 'general-frm-element'}`}>
        <input
          type="number"
          onWheel={(e) => e.target.blur()}
          className={`${props.errors[props.name] ? 'input-error form-control' : 'form-control'} ${props.class_name != undefined ? props.class_name : 'general-frm-element'}`}
          size="10"
          autoComplete="off"
          placeholder={props.placeholder}
          name={props.name}
          step={props.integerOnly ? '1' : '0.1'}
          value={props.value}
          onKeyDown={handleKeyDown}
          {...props.register(props.name, {
            required: window.t_price_calculator_required,
            validate: validate,
            onChange: props.onChange,
            onBlur: formatValue,
          })}
          />

        <span className={props.errors[props.name] ? 'input-error input-group-addon' : 'input-group-addon'}>
          <span> {props.unit} </span>
        </span>
      </div>
      {props.errors[props.name] && <small className="error"> {props.errors[props.name].message} </small> }
    </>
  )
}

export default NumberWithUnitInput
