import React, { useEffect, useState } from 'react';
import { PostRequest } from 'components/common/index.js';
import PurchaseRequired from 'components/redraw_denied/PurchaseRequired.jsx';
import LogInRequired from 'components/redraw_denied/LogInRequired.jsx';
import PaymentSuccess from 'components/payment/PaymentSuccess.jsx';
import PreviewExpired from 'components/redraw_denied/PreviewExpired.jsx';

const RedrawDenied = ({}) => {
  const [mode, setMode] = useState('none');
  const [promptForCreateAccountEmail, setPromptForCreateAccountEmail] = useState('');

  const [loggedIn, setLoggedIn] = useState(false);
  const [availableCredits, setAvailableCredits] = useState(0);

  const getStatus = () => {
    PostRequest({
      url: '/redraw_denied/status',
      data: {},
      callback: (data) => {
        setLoggedIn( _prev => data.logged_in )
        setAvailableCredits( _prev => data.available_credits );
        if(data.logged_in && data.available_credits > 0 )  { setMode(() => 'none');}
        if(data.logged_in && data.available_credits == 0)  { setMode(() => 'pricing_tables');}
        if(!data.logged_in && data.available_credits == 0) { setMode(() => 'log_in_required');}

        if(data.omniauth_email != undefined && data.omniauth_sign_in_failed_account_does_not_exists) {
          setPromptForCreateAccountEmail( () => data.omniauth_email )
        }
      }
    })
  }

  useEffect( () => {
    let listen = 'show_drawing.redraw_denied';
    document.addEventListener(listen, getStatus);
    return () => { document.removeEventListener(listen, getStatus);}
  }, [])

  return (
    <div data-mode={mode}>
      {loggedIn && availableCredits == 0 && mode != 'payment_success' && <PurchaseRequired mode={mode} setMode={setMode} />}
      {loggedIn && availableCredits > 0 && mode != 'payment_success' && <PreviewExpired mode={mode} setMode={setMode} availableCredits={availableCredits} callback={scrollToDownload} />}
      {loggedIn && mode == 'payment_success' && <PaymentSuccess callback={redrawAfterPurchase} />}
      {!loggedIn && mode == 'log_in_required' && <LogInRequired mode={mode} setMode={setMode} promptForCreateAccountEmail={promptForCreateAccountEmail} />}
    </div>
  )
}

export default RedrawDenied;
