import React, { useState, useEffect } from 'react';
import {CurrencyExchangeRate} from 'components/quote_builder/form_fields';

const CurrencyExchange = ({ config }) => {
  // input and output currency concepts are from this components point of view.
  const [inputCurrency, setInputCurrency] = useState(window.user_quote.currency);
  const [outputCurrencies, setOutputCurrencies] = useState([window.user_quote.currency]);

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  useEffect(() => {
    document.addEventListener('quote_builder.output_currencies_changed', function(e) {
      setOutputCurrencies((previous) => {
        return previous.concat(e.detail).filter(onlyUnique);
      });
    });

    document.addEventListener(config.subscribe_on_change_key_input_currency, function(e) {
      setInputCurrency( e.detail);
    });
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
  }

  const outputCurrencyForm = (outputCurrency) => {
    return (
       <>
       {inputCurrency != outputCurrency && (
        <CurrencyExchangeRate key={outputCurrency} inputCurrency={inputCurrency} outputCurrency={outputCurrency} />
        )}
      </>
    )
  }

  return outputCurrencies.length > 0 && outputCurrencies.map(outputCurrencyForm)
}

export default CurrencyExchange;
