import React, { useState, useEffect } from 'react';
import OptionRowInteger from 'components/layout/options/OptionRowInteger.jsx'
import OptionRowIntegerFreeForm from 'components/layout/options/OptionRowIntegerFreeForm.jsx'
import { HandleActivationFormula , HandleOptionsUserChanged} from 'components/layout/options/drawing_options/index.js';

const Angle =  ({ item, option, value, onChange, enabled } ) => {
  const optionsUserChanged = () => { return window.dielineOptions["options_user_changed"].split(','); }
  const updateOptionsUserChanged = (_e) => { HandleOptionsUserChanged().add(option.name) };
  const [active, setActive] = useState(true);
  const [autoCalculated, setAutoCalculated] = useState(false);
  const [weRecommend, setWeRecommend] = useState('');

  useEffect(() => {
    if(enabled && active && option.possible_values.length > 0 ) {
      document.dispatchEvent(new CustomEvent('REDRAW_REQUESTED', { "detail": item.uuid }));
    }
  }, [value]);

  useEffect(() => {
    if(weRecommend != undefined && weRecommend != '' && !optionsUserChanged().includes(option.name) ) {
      onChange(weRecommend);
      setAutoCalculated( (_prev) => { return true } );
    } else {
      setAutoCalculated( (_prev) => { return false } );
    }
  }, [weRecommend]);

  const attachEvents = () => {
    if (option.has_activation_formula || option.has_value_formula) {
      HandleActivationFormula({
        option: option,
        item: item,
        setActive: setActive,
        setWeRecommend: setWeRecommend,
      });
    }
  }

  useEffect(attachEvents, [enabled, item]);

  return (
    <>
    {active && option.possible_values.length == 0 && (
      <OptionRowIntegerFreeForm
        item={item}
        description={option.description}
        name={option.name}
        value={value}
        onChange={onChange}
        possible_values={option.possible_values}
        onKeyUp={updateOptionsUserChanged}
        doNotLoadFromLocalStorage={true}
      />
    )}

    {active && option.possible_values.length > 0 && (
      <OptionRowInteger
        item={item}
        description={option.description}
        name={option.name}
        value={value}
        onChange={onChange}
        possible_values={option.possible_values}
        onClick={updateOptionsUserChanged}
        doNotLoadFromLocalStorage={true}
      />
    )}
    {active && autoCalculated && <div className="drawing-option-autocalculated-cont"> <small> {t('drawing.this_has_been_automaticly_calculated') }  </small> </div>}
    {active && weRecommend != '' && weRecommend != undefined && <div className="drawing-option-recommend-cont"> <small> {t('drawing.choose_option_recommmended') }: {weRecommend} </small> </div>}
    </>
  )
}

export default Angle
