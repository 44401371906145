import Material from './Material';
import HotStamping from './HotStamping';
import Printing from './Printing';
import GuillotineCutting from './GuillotineCutting';
import Cutting from './Cutting';
import Cellophane from './Cellophane';
import Glueing from './Glueing';
import Lamination from './Lamination';
import Magnet from './Magnet';
import CoatingLabor from './CoatingLabor';
import Transport from './Transport';
import VCutLabor from './VCutLabor';

export {
  Material,
  HotStamping,
  Cutting,
  GuillotineCutting,
  Printing,
  Cellophane,
  Glueing,
  Lamination,
  Magnet,
  CoatingLabor,
  Transport,
  VCutLabor,
};
