import React, {}  from 'react';

const OneOff = ({ subscription, selectedSubscription, handleSubscriptionRowClick, handleSubscriptionRadioSelect}) => {
  if(subscription == undefined) { return }

  const price_formatted = () => {
    return subscription.has_discount ? price_formatted_with_discount() : price_formatted_without_discount()
  }

  const price_formatted_without_discount = () => {
    return (
      <div className="float-right" data-value={subscription.type}>
        <div className="float-right largeText" data-value={subscription.type}>
          {subscription.price_formatted}
        </div>
        {per_dieline()}
      </div>
    )
  }

  const price_formatted_with_discount = () => {
    return (
       <div className="float-right" data-value={subscription.type}>
        <div className="float-right clear largeText" data-value={subscription.type}>
          <strike data-value={subscription.type}>
            {subscription.price_without_discount_formatted}
          </strike>
           &nbsp;
          <strong data-value={subscription.type}> {subscription.price_formatted} </strong>
        </div>

        {per_dieline()}

      </div>
    )
  }

  const per_dieline = () => {
    if(subscription.unit_price_formatted == subscription.price_formatted) { return <div><br /><br /></div> }

    return (
      <div className="float-right clear-right" style={{"color":"#F44336"}}>
        <small data-value={subscription.type}> &nbsp; {t('sign_up.price_per_dieline').replace('%{price}', subscription.unit_price_formatted).replace('<br />', '') } </small>
      </div>
    );
  }

  return (
    <tr className="subscription_type_row" onClick={handleSubscriptionRowClick} data-value={subscription.type} key={subscription.type}>
      <td data-value={subscription.type}>
        <div className="float-left" data-value={subscription.type}>

          <input type="radio"
            name="subscription_type"
            value={subscription.type}
            checked={selectedSubscription === subscription.type}
            onChange={handleSubscriptionRadioSelect}
            className="subscription_option"
          />

          &nbsp; {t(`sign_up.${subscription.type}`)}

          &nbsp;({t(`sign_up.preview_wording_${subscription.type}`)} <sup>*</sup>, {t(`sign_up.limited_wording_${subscription.type}`)})

          {subscription.has_discount && <div className="marg5" data-value={subscription.type}>
            <span class="badge badge-dark" data-value={subscription.type}> {t('sign_up.special_offer')}</span>
          </div>}
        </div>

        {price_formatted(subscription)}
        <br />

      </td>
    </tr>
  )
}

export default OneOff;
