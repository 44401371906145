import React, { useState, useEffect, createRef }  from 'react';
import { ContentPopup } from 'components/ContentPopup.jsx';

const ToolTip = ({ title, toolTipKey, onReady }) => {

  const [showPopUp, setShowPopUp] = useState(false);
  const [tooltipContentHtml, setTooltipContentHtml] = useState('...');

  const tooltipRef = createRef();

  const handleClick = (e) => {
    e.preventDefault();
    setShowPopUp( () => true );
  }

  const popupRef = createRef();
  const popupContent = () => {
    let callback = () => {
      popupRef.current.style.display = "none";
      setShowPopUp(() => false);
    };

    fetch(`/faq-tooltip-content/${toolTipKey}`, {}).then(function(response) { return response.text() }).then(data => { setTooltipContentHtml( () => data ) })
    let content =<span dangerouslySetInnerHTML={{__html: tooltipContentHtml}}></span>

    return <ContentPopup
      contextClass="wrapper"
      myRef={popupRef}
      autoOpen={true}
      content={content}
      cancelButton={false}
      beforeClose={callback}
      onReady={onReady}
      notWide={true}
    />;
  }

  useEffect(() => {
    $(tooltipRef.current).tooltip({ close: tooltipCloseFunc})
  }, [tooltipRef])

  return <>
  <span className="abc_helper_tooltip">
    <a href="#" title={title} ref={tooltipRef} onClick={handleClick} >
      <i className="zmdi zmdi-pin-help"></i>
    </a>
  </span>
   {showPopUp && popupContent()}
  </>
}

export default ToolTip;
