import React, { useEffect,  useState} from 'react';
import { HandleActivationFormula , HandleOptionsUserChanged} from 'components/layout/options/drawing_options/index.js';
import LoadFromLocalStorage from 'components/layout/options/LoadFromLocalStorage.jsx'

const Bool = ({ item, option, value, onChange, enabled, doNotLoadFromLocalStorage} ) => {
  const [active, setActive] = useState(true);
  const [valueWrapped, setValueWrapped] = useState(value == 1);
  const [weRecommend, setWeRecommend] = useState('');
  const [autoCalculated, setAutoCalculated] = useState(false);
  const optionsUserChanged = () => { return window.dielineOptions["options_user_changed"].split(','); }

  const onChangeWrapped = (e) => {
    setValueWrapped(e.target.checked);
    let valueToSet = e.target.checked ? '1' : '0';
    onChange(valueToSet);
  }

  useEffect(() => {
    if(weRecommend != undefined && !optionsUserChanged().includes(option.name) ) {
      setValueWrapped((_prev) => { return weRecommend == 1 });
      onChange( weRecommend );
      setAutoCalculated( (_prev) => { return true } );
    } else {
      setAutoCalculated( (_prev) => { return false } );
    }
  }, [weRecommend]);


  useEffect(() => {
    if (option.has_activation_formula || option.has_value_formula) {
      HandleActivationFormula({
        option: option,
        item: item,
        setActive: setActive,
        setWeRecommend: setWeRecommend,
      });
    }
  }, [item]);

  useEffect(() => {
    if(enabled) {
      document.dispatchEvent(new CustomEvent('REDRAW_REQUESTED', { "detail": item.uuid }));
    }
  }, [value])

  const updateOptionsUserChanged = (_e) => {
    HandleOptionsUserChanged().add(option.name)
  }

  useEffect(() => {
    if(doNotLoadFromLocalStorage) { return; }
    LoadFromLocalStorage(item, option.name, setValueWrapped);
  } ,[]);

  return (<>
     {active && <div className="LayoutRedrawFormInputRow">
      <div className="input-group LayoutRedrawFormInputRow">
        <span className="input-group-addon input-group-addon-wide">
          <span className="float-left" > {option.description} </span>
          <input type="hidden" value={value} name={option.name} />
          <input className="float-right form-check-input"
            checked={valueWrapped}
            type="checkbox"
            onClick={updateOptionsUserChanged}
            onChange={onChangeWrapped}
          />
        </span>
      </div>
    </div> }

    {false && active && weRecommend != undefined && <div className="drawing-option-recommend-cont"> <small> {t('drawing.choose_option_recommmended') }: {weRecommend ? t('drawing.checked')  : t('drawing.unchecked') } </small> </div>}
  </>)
}

export default Bool
