import React, { useState, useEffect, useContext } from 'react';
import LayoutSelectButton from 'components/layout/LayoutSelectButton.jsx';
import LayoutCarousel from 'components/layout/LayoutCarousel.jsx';
import LayoutMostEfficient from 'components/layout/LayoutMostEfficient.jsx';
import LayoutNavSubSection from 'components/layout/LayoutNavSubSection.jsx';

const LayoutNavSection = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [items, setItems] = useState([]);
  const [activeMember, setActiveMember] = useState(props.navSection.first_member);
  const [error, setError] = useState("error");

  const selectedItemCount = (sub_section) => {
    let item_uuids = items.map((i) => i.uuid );
    let sub_section_uuids = sub_section.members.map((m) => m.uuid);
    let intersection = item_uuids.filter(value => sub_section_uuids.includes(value));
    return intersection.length;
  }

  const requiredPartsMissingError = () => {
    let item_parts = items.map((item) => item.part_names).flat();
    let missingParts = props.navSection.part_names.filter(value => !item_parts.includes(value));

    if (missingParts.length > 0) {
      return `${window.wording_select_templates_for_parts.replace('(?)',missingParts.map(str => `"${str}"`).join(', ')) }`;
    }

    return '';
  }

  const isMostEfficient = (member_uuid) => {
    for(let key in mostEfficients) {
      for(let i=0; i< mostEfficients[key].length; i++) {
        let member = mostEfficients[key][i];
        if(member.uuid == member_uuid) {
          return true;
        }
      }
    }
    return false;
  }

  useEffect(() => {
    let item_part_names = items.map((item) => item.part_names).flat();
    props.setSelectedMembers(items, `index_${props.index}`);
  }, [items])

  useEffect(() => {
    props.setRequiredParts(props.navSection.part_names);
  }, [])

  const onItemsChanged = () => {
    if(items.length == 0) { return };
    let lastItem = items[items.length - 1];
    for(let i = 0; i< items.length -1; i++){
      let intersection = items[i].parts.filter(x => lastItem.parts.includes(x));
      if( intersection.length > 0 ){
        setItems((prev) => prev.filter((item) => item.uuid != items[i].uuid))
      }
    }
  }

  useEffect(onItemsChanged, [items]);

  const getTab = (sub_section, index) => {
    let totalTabCount = props.navSection.sub_sections.length;

    return (<div
      key={`tab_${props.index}_${index}`}
      onClick={() => setActiveTab(index)}
      className={`shortcode_tab_item_title shortcode_tab_item_title_of_${totalTabCount} ${props.activeRedraws.length > 0 && 'disabledForm'} ${index != activeTab && 'active'} ${index == activeTab && 'inactivetab'}`}>
       {sub_section.label}

        {selectedItemCount(sub_section) > 0 && (
          <span className="badge bgred"> {selectedItemCount(sub_section)}
        </span>)
      }
    </div>)
  }

  const getSubSection = (sub_section, index) => {
    return <LayoutNavSubSection
      key={`sub_section_${props.index}_${index}`}
      sub_section={sub_section}
      index={index}
      activeTab={activeTab}
      activeRedraws={props.activeRedraws}
      setActiveRedraws={props.setActiveRedraws}
      items={items}
      setItems={setItems}
      requiredPartsMissingError={requiredPartsMissingError}
    />
  }

  return (
    <div className={`col-lg-12 navSection ${props.navSection.type == 'two_layers' ? 'card navSectionCardModif' : ''}`}>
      {props.navSection.type == 'two_layers' && (
      <div className="promo-block">
        <div className="promo-text"> {props.navSection.label} </div>
        <div className="center-line"></div>
      </div>
      )}

      {(props.navSection.type == 'two_layers' || props.navSection.type == 'one_layer') && (
       <div className="shortcode_tabs shortcode_tabs_layout">
        {props.navSection.sub_sections.map(getTab)}
      </div>)}

      <div className={`col-lg-12 newtabcontent navSectionContent ${props.navSection.type == 'two_layers' ? '' : 'navSectionContentWider'} `}>
        {props.navSection.sub_sections.map(getSubSection)}
      </div>
   </div>
  )
}

export default LayoutNavSection;
