const NonZeroValidator = (value) => {
  if(parseFloat(value) == 0.0) {
    return false;
  }
  return true;
}

const NonEmptyValidator = (value) => {
  if(value == 'not-available' || value == '' || value == undefined) {
    return false;
  }
  return true;
}

const ValidatorFactory = (validatorName, value) => {
  const validators = {
    NonZeroValidator: NonZeroValidator,
    NonEmptyValidator: NonEmptyValidator,
  }
  const SpecificValidator = validators[validatorName];
  return SpecificValidator(value);
}

export {
  NonZeroValidator,
  NonEmptyValidator,
  ValidatorFactory
};
