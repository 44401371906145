import React, { useContext} from 'react';
const LayoutContext = React.createContext({
  dimensionGroups: {},
  layoutOptions: {},
  formulaOptions: {},
  optionsUserEntered: {},
  unit: '',
  locale: '',
  theme: '',
  btn_action: '',
  dimension_texts_on_layouts_enabled: false
});
export default LayoutContext;