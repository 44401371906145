import React, { useState, useEffect }  from 'react';
import styles from './_style.module.scss';

const Progressbar = ( { } ) => {

  return (
      <div className={styles.animation_container}>
        <h4> {t('three_d.please_wait')} </h4>
        <div className="progress progress-striped">
          <div className={`${styles.progress} ${styles.progress_striped}`}>
            <div className={styles.progress_bar}>
            </div>
          </div>
      </div>
    </div>
  )
}

export default Progressbar;
