import React, { useState } from 'react';
const GetRequest = (props) => {

  if(props.useCache) {
    if(window.cachedRequests == undefined) { window.cachedRequests = {} }
    if(window.cachedRequests[props.url] != undefined) {
      props.callback(window.cachedRequests[props.url]);
      // console.log("reading from cache: " + props.url)
      return;
    }
  }

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json',  'X-Requested-With': 'XMLHttpRequest' }
  };
  updateRequestCount(1);

  fetch(props.url, requestOptions)
    .then(async response => {
      updateRequestCount(-1);
      const isJson = response.headers.get('content-type')?.includes('application/json');
      const data = isJson && await response.json();

      if (!response.ok) {
        const error = (data && data.message) || response.status;

        if(response.status == 500) {
          trackEvent('js_error_happened', {
            source:  "GetRequest returned error ",
            url: props.url,
            respons: data,
          });
        }

        if(props[response.status] != undefined) {
          props[response.status](data);
        }

        return Promise.reject(error);
      }
      if(props.useCache) { window.cachedRequests[props.url] = data }
      props.callback(data);
    })
    .catch((error) => {
      console.error('[GetRequest] There was an error!', error);
    });
}

export default GetRequest
