import React, { useState, useEffect } from 'react';
import LayoutSvg from 'components/layout/LayoutSvg.jsx';

const LayoutCarousel = ( {items, activeMember, setActiveMember, index, activeRedraws, setActiveRedraws}) => {
  useEffect(() => {

    $(`#carousel_${index}`).on('slid.bs.carousel', function (elem) {
      var active_item = $(`#carousel_${index}`).find('.item.active');
      items.forEach((item, index) => {
        if(item.uuid == active_item.data('uuid')) {
          setActiveMember( { ...item });
        }
      });
    });

  } )

  return (
    <div className="carousel slide" data-interval="false" data-ride="carousel" id={`carousel_${index}`}>
      <div className="carousel-inner">
        {items.map((item, index) => {
          return (
            <div key={item.uuid} className={`item card ${item.uuid == activeMember.uuid ? "active" : ""} `} data-uuid={item.uuid} >
              <LayoutSvg klass={item.klass} id={item.id} parts={item.parts} redrawUrl={item.redraw_url} uuid={item.uuid} partNames={item.part_names}
                activeRedraws={activeRedraws} setActiveRedraws={setActiveRedraws} />
            </div>
            )
        })}

        {items.length == 0 && <> <p> Please select a template. </p> </>}
      </div>

      {items.length > 1 &&
      <>
        <a className="left carousel-control nobackground" data-slide="prev" href={`#carousel_${index}`}>
          <i className="fa fa-angle-left car_icon"></i>
        </a>

        <a className="right carousel-control nobackground" data-slide="next" href={`#carousel_${index}`}>
          <i className="fa fa-angle-right car_icon"></i>
        </a>
      </>
      }

      {items.length > 1 &&
      <div className="carousel_nav_links">
        <center>
          <a data-slide="prev" href={`#carousel_${index}`}>
            <i className="fa fa-arrow-left" aria-hidden="true"></i> Previous
          </a>

         
          &nbsp;
          &nbsp;
          &nbsp;

          <a data-slide="next" href={`#carousel_${index}`}>
             Next <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </a> {activeMember.uuid}
        </center>
      </div>
      }
   </div>
  )
}

export default LayoutCarousel;
