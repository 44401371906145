import React, { useState, useEffect, useRef }  from 'react';
import { MyDownloadsButton } from 'components/show_drawing/download_buttons/index.js';
import { ContentPopup } from 'components/ContentPopup.jsx';
import { PricingTables, PaymentForm, ChangeCurrency, PaymentSuccess } from 'components/payment/index.js';
import { PostRequest } from 'components/common/index.js';

const NeedsToConfirmEmail = ({ onSuccess }) => {
  const handleClick = (e) => {
    e.preventDefault()
    setShowPopUp( () => true );
  }

  const [showPopUp, setShowPopUp] = useState(false);
  const popupRef = useRef();

  const handleBackClick = (e) => {
    e.preventDefault();
  }

  const [subscription, setSubscription] = useState({})
  const [currencyName, setCurrencyName] = useState('')

  const sendConfirmationEmail = (e) => {
    e.preventDefault();
    PostRequest({
      url: '/resend_confirmation',
      data: {},
      callback: (data) => {
        setShowIConfirmed(() => true);
      }
    });
  }

  useEffect(() => {
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent('layouts.download_buttons_ready', {}));
    }, 250);
  }, []);

  const [showIConfirmed, setShowIConfirmed] = useState(false);

  const popupContent = () => {
    let callback = () => {
      popupRef.current.style.display = "none";
      setShowPopUp(() => false);
    };

    const handleIConfirmed = (e) => {
      e.preventDefault();
      callback()
      onSuccess();
    }

    let content = (
      <div className="testimonials-block preview-promo-block">
        <div className="testimonials-desc">
          <span>
            <h4> {t('nav.confirmation')}</h4> <hr />
            <span dangerouslySetInnerHTML={{__html: t('sign_up.signed_up_but_unconfirmed')}}></span>
            (<strong>{window.currentUserEmail}</strong>)
            <hr />

             <div className="downloadButtonscontainer">
              <div className="inner">
                <div className="child">
                  <button className="btn btn-default simple-btn" onClick={sendConfirmationEmail}>
                    {t('sign_up.resend_confirmation_instructions')}
                  </button>
                </div>
              </div>

              {showIConfirmed && (
              <div className="inner">
                <div className="child">
                 <button className="btn btn-default simple-btn" onClick={handleIConfirmed}>
                  {t('sign_up.I_have_confirmed_my_email')}
                 </button>
                </div>
              </div>)}

            </div>

          </span>
        </div>
      </div>
    )

    return <ContentPopup
      contextClass="wrapper"
      myRef={popupRef}
      autoOpen={true}
      content={content}
      cancelButton={false}
      beforeClose={callback}
      notWide={true}
    />;
  }
  return (
    <div className="downloadButtonscontainer">

      <div className="inner">
        <div className="child"> <br />
          <button className={`btn btn-default btn-lg download-btn confirm-btn-with-icons-${window.I18nLocale}`} onClick={handleClick} style={{"width": "540px"}}>
            <img src={window.dxf_svg_and_pdf_icon} className="downloadIcons" /> {t('nav.confirmation')}
          </button>
        </div>
      </div>

      <MyDownloadsButton />

      {showPopUp &&  popupContent()}
    </div>
  )
}

export default NeedsToConfirmEmail;
