import React, {useState}  from 'react';
import { PostRequest, ConfigParam } from 'components/common/index.js';

const SignUp = ({ setBackButtonVisible, onSuccess }) => {

  const minPasswordLength = 6;

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(t('sign_up.sorry_there_was_an_error'));
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);

  const [checkPasswordMatches, setCheckPasswordMatches] = useState(false);
  const [CheckPasswordLenght, setCheckPasswordLenght] = useState(false);
  const [CheckAgreeTerms, setCheckAgreeTerms] = useState(false);

  const [signUpFailed, setSignUpFailed] = useState(false);
  const [signUpSuccess, setSignUpSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setCheckPasswordMatches(() => true);
    setCheckPasswordLenght(() => true);
    setCheckAgreeTerms(() => true);

    if(!agreeTerms) { return }
    if(password != passwordConfirmation) { return }
    if(password.length < minPasswordLength) { return }

    PostRequest({
      url: '/users',
      data: {
        user: {
          email: email,
          password: password,
          password_confirmation: passwordConfirmation
        }
      },
      302: () => {
        location.reload(true);
      },
      callback: (data) => {
        if (data == false) {
          setSignUpFailed(() => true)
          setPassword(() => '');
          setPasswordConfirmation(() => '');
          return;
        }

        if (data.is == 'ok') {
          setBackButtonVisible(() => false);
          setSignUpSuccess(() => true);
          setSignUpFailed(() => false);
          updateAuthTokensOnPage(data.t)
          refreshTopHeader();
          refreshDownloadButtons();
          emitEvent("user.session_started", {})
          return;
         }

         if (data.is == 'not_ok') {
          setBackButtonVisible(() => true);
          setSignUpSuccess(() => false);
          setSignUpFailed(() => true);
          setErrorMessage(() => data.error_message)
          return;
         }

         throw new Error("/users had unhandled case:" + data);
        }
    });
  }

  const form = () => {
    return (
      <div className="form_login transbox-sign-in" style={{"maxWidth": '325px'}}>
       {signUpFailed && (
          <div className="alert alert-danger fade in">
            <i className="fa fa-exclamation-triangle"> </i> <strong> {errorMessage} </strong>
          </div>
        )}

        {!signUpFailed && checkPasswordMatches && passwordConfirmation != password && (
          <div className="alert alert-danger fade in">
            <i className="fa fa-exclamation-triangle"> </i> <strong> {t('sign_up.password_confirmation_does_not_match')} </strong>
          </div>
        )}

        {!signUpFailed && CheckPasswordLenght && passwordConfirmation == password && password.length < minPasswordLength &&  (
          <div className="alert alert-danger fade in">
            <i className="fa fa-exclamation-triangle"> </i> <strong> {t('sign_up.password_too_short')} </strong>
          </div>
        )}

        {!signUpFailed && CheckAgreeTerms && passwordConfirmation == password && password.length >= 6 && !agreeTerms && (
          <div className="alert alert-danger fade in">
            <i className="fa fa-exclamation-triangle"> </i> <strong> {t('sign_up.please_agree_to_terms')} </strong>
          </div>
        )}

        <form method="POST" onSubmit={handleSubmit}>
          <div className="sign_up_label"> {t('sign_up.email')} </div>
          <input type="email" style={{"color":"#444"}} required={true} className="input-cont-textarea" value={email} onChange={(e) => setEmail(() => e.target.value)}/>

          <div className="sign_up_label"> {t('sign_up.password')} </div>
          <input type="password" style={{"color":"#444"}} required={true} className="input-cont-textarea" value={password} onChange={(e) => setPassword(() => e.target.value)}/>

          <div className="sign_up_label"> {t('sign_up.password_confirmation')} </div>
          <input type="password" style={{"color":"#444"}} required={true} className="input-cont-textarea" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(() => e.target.value)}/>

          <input style={{"width":"10%"}} className="input-cont-checkbox" type="checkbox" value="1" checked={agreeTerms ? 'checked': ''} onChange={(e) => setAgreeTerms(() => e.target.checked)}/>
          <span dangerouslySetInnerHTML={{__html: t('sign_up.I_agree_to_terms') }}></span>
          <button className="button"> {t('sign_up.create_an_account')} </button>
        </form>
      </div>)
  }

  const buttons = () => {
    return (
        <div>
        <a href={`/sign_up_via_google_oauth2?redirect_to_after_login=${redirect_to_after_login_path()}`}>
          <img src={ConfigParam.btn_google_signin_dark_normal_web} width="250px" />
        </a>

        <br />
        <br />

        <a href={`/sign_up_via_facebook?redirect_to_after_login=${redirect_to_after_login_path()}`}>
          <img src={ConfigParam.contFacebook} width="250px" />
        </a>

        <br />
        <br />
        <a href={`/sign_up_via_twitter?redirect_to_after_login=${redirect_to_after_login_path()}`}>
          <img src={ConfigParam.twitter_button} width="250px" />
        </a>
      </div>);
  }

  const continueAfterSignUpSuccess = (e) => {
    e.preventDefault();
    onSuccess()
  }

  if(signUpSuccess) {
    return (<>
      <div className="leanLeft welcome-checks">
        <h3> { t('welcome.tagline') } </h3>
        <hr />

        <p> {t('welcome.hero_description')} </p>
        <ul className="list-check">
          <li className="leanLeft"> <i className="fa fa-check"></i> <span dangerouslySetInnerHTML={{__html: t('welcome.li_1')}}></span> </li>
          <li className="leanLeft"> <i className="fa fa-check"></i> <span dangerouslySetInnerHTML={{__html: t('welcome.li_2')}}></span> </li>
          <li className="leanLeft"> <i className="fa fa-check"></i> <span dangerouslySetInnerHTML={{__html: t('welcome.li_3')}}></span> </li>
          <li className="leanLeft"> <i className="fa fa-check"></i> <span dangerouslySetInnerHTML={{__html: t('welcome.li_4')}}></span> </li>
          <li className="leanLeft"> <i className="fa fa-check"></i> <span dangerouslySetInnerHTML={{__html: t('welcome.li_5')}}></span> </li>
        </ul>
        <hr />
       <center> <a className="btn simple-btn" href="#" onClick={continueAfterSignUpSuccess}>  { t('welcome.get_started') } </a> </center>
      </div>
    </>)
  }

  return (
    <div>
      <h4> {t('sign_up.create_an_account')} </h4>

      <hr/>
      <br />

      <span className="float-left">
        {buttons()}
      </span>

      <span className="float-right">
        {form()}
      </span>
    </div>
  )
}

export default SignUp;
