const HandleOptionsUserChanged = ( ) => {
  return {
    add: (name) => {
      let changed = window.dielineOptions["options_user_changed"].split(',');
      changed.push(name);
      window.dielineOptions["options_user_changed"] = changed.filter(onlyUnique).join(',');
      window.optionsUserEntered["options_user_changed"] = changed.filter(onlyUnique).join(',');
    }
  }
}

export default HandleOptionsUserChanged;
