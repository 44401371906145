import React, { useState, useEffect, useRef }  from 'react';
import { ContentPopup } from 'components/ContentPopup.jsx';
import LogInRequired from 'components/header/LogInRequired.jsx';

const LoginButtonListener = ({ omniauth_email }) => {
  const show = () => {
    setTimeout(() => {
      $("#preview_promo_modal").hide();
    }, 500)
    setShowPopUp(() => true);
  }

  useEffect( () => {
    let listen = 'home.log_in_required';
    document.addEventListener(listen, show);
    return () => { document.removeEventListener(listen, show);}
   }, [])

  useEffect(() => {
    if(omniauth_email != undefined) {
      setPromptForCreateAccountEmail(() => omniauth_email);
      show();
    }
  }, [])

  const [showPopUp, setShowPopUp] = useState(false);
  const [mode, setMode] = useState('loading');
  const popupRef = useRef();

  const [promptForCreateAccountEmail, setPromptForCreateAccountEmail] = useState('')

  const popupContent = () => {
    let callback = () => {
      popupRef.current.style.display = "none";
      setShowPopUp(() => false);
      if(window.onLogInSuccess != undefined) {
        window.onLogInSuccess();
        window.onLogInSuccess = null;
      }
    };

    let content = <LogInRequired mode={mode} setMode={setMode} promptForCreateAccountEmail={promptForCreateAccountEmail} callback={callback} autoOpen={true} />
    return <ContentPopup
      contextClass="wrapper"
      myRef={popupRef}
      autoOpen={true}
      content={content}
      cancelButton={false}
      beforeClose={callback}
      notWide={true}
    />;
  }
  return (<>
    {showPopUp && popupContent()}
  </>)
}

export default LoginButtonListener;