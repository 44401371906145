import React from 'react';
import { UserUnitPriceInput, Tooltip } from 'components/common';

const PriceFormField = ({ config, currency, value, values, setValue, register, errors, disabled, setFetchInProgress, precision }) => {

  const getLabel = () => {
    if(config.label_eval != undefined) {
      return eval(config.label_eval)
    }
    return t(config.label)
  }
  return (
    <tr>
      <td> {getLabel()} { config.tooltip_text != undefined && <Tooltip title={t(config.tooltip_text)} /> } </td>
      <td align="right">
        <UserUnitPriceInput
          name={config.name}
          placeholder={config.placeholder}
          register={register}
          errors={errors}
          value={value}
          class_name={config.class_name}
          validators={config.validators != undefined ? config.validators : []}
          currency={currency}
          precision={precision}
          onChange={(e) => setValue(config.name, e.target.value)}
        />
      </td>
    </tr>
  )
}

export default PriceFormField;
