import React, { useState, useEffect }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import Constants from 'components/show_drawing/Constants.jsx';

const ShowHideChildOptions = ( {id, option } ) => {
  const [visible, setVisible] = useState(false)

  const handleMore = (e) => {
    e.preventDefault();
    setVisible( () => !visible )
  }

  const onVisibleChange = () => {
    emitEvent(Constants.child_options_visible_change, { id: id, name: option.name, visible: visible})
  }
  // useEffect
    useEffect(onVisibleChange, [visible])
  // useEffect
  return <>
     <span className="float-right">
        <a href="#" onClick={handleMore} className={styles.more_link}>
          {!visible && <> {t('drawing.show_child_options_cta')} <i className="fa fa-caret-down" aria-hidden="true"></i> </>}
          {visible && <> {t('drawing.hide_child_options_cta')} <i className="fa fa-caret-up" aria-hidden="true"></i> </>}
        </a>
      </span>
     <div style={{"clear": "both"}}></div>
    </>
}

export default ShowHideChildOptions;
