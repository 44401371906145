import React, {useState}  from 'react';
import { PostRequest, ConfigParam } from 'components/common/index.js';

const SignIn = ({ promptForCreateAccountEmail, setShowSignIn, setShowSignUp, onSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    PostRequest({
      url: '/users/sign_in',
      data: {
        user: {
          email: email,
          password: password,
          remember_me: rememberMe
        }
      },
      302: () => {
        location.reload(true);
      },
      callback: (data) => {
       if (data == false) {
        setPassword(() => '');
        setLoginFailed(() => true)
        return;
       }

      if (data.is == 'ok') {
        setLoginFailed(() => false);
        updateAuthTokensOnPage(data.t)
        refreshTopHeader();
        refreshDownloadButtons();
        onSuccess()
        emitEvent("user.session_started", {})
        return;
       }

       throw new Error("/users/sign_in had unhandled case:" + data);
      }
    });
  }

  const switchToSignUp = (e) => {
    e.preventDefault();
    setShowSignIn( () => false )
    setShowSignUp( () => true )
  }

  const signInForm = () => {
    return (
      <div className="form_login transbox-sign-in" style={{"maxWidth": '325px'}}>
        {loginFailed && (
          <div className="alert alert-danger fade in">
            <i className="fa fa-exclamation-triangle"> </i> <strong> {t('sign_up.invalid_login')} </strong>
          </div>
        )}


        <form onSubmit={handleSubmit}>
          <div className="sign_up_label"> {t('sign_up.email')} </div>
          <input type="email" style={{"color":"#444"}} required={true} className="input-cont-textarea" value={email} onChange={(e) => setEmail(() => e.target.value)}/>

          <div className="sign_up_label"> {t('sign_up.password')} </div>
          <input type="password" style={{"color":"#444"}} required={true} className="input-cont-textarea" value={password} onChange={(e) => setPassword(() => e.target.value)}/>
          <button className="button"> {t('login.login')} </button>

          <p className="message">
          <input style={{"width":"10%"}} className="input-cont-checkbox" type="checkbox" value="1" checked={rememberMe ? 'checked': ''} onChange={(e) => setRememberMe(() => e.target.checked)}/>
          {t('login.remember_me')}
          </p>

          <p className="message"> {t('login.forgot_password')} <a href="/users/password/new" target="_blank"> {t('login.reset_password')} </a> </p>
          <hr />
          <p className="message"> {t('login.dont_have_account')} <strong> <a href="#" onClick={switchToSignUp}> {t('sign_up.create_an_account')} </a> </strong> </p>

        </form>
    </div>)
  }

  const buttons = () => {
    return (
      <div className="socialButtonsCont">

        {promptForCreateAccountEmail != '' && promptForCreateAccountEmail != undefined && (
          <div style={{"width": "250px"}}>
            <div class="alert alert-danger fade in">

                <h5> {t('sign_up.account_not_found')} <br /> ({promptForCreateAccountEmail}) </h5>

                <hr />

              <strong> { t('login.dont_have_account')} <a href="#" onClick={switchToSignUp}> {t('sign_up.create_an_account')} </a> </strong>

            </div>
          </div>
        )}

        <a href={`/sign_in_via_google_oauth2?redirect_to_after_login=${redirect_to_after_login_path()}`}>
          <img src={ConfigParam.btn_google_signin_dark_normal_web} width="250px" />
        </a>

        <br />
        <br />

        <a href={`/sign_in_via_facebook?redirect_to_after_login=${redirect_to_after_login_path()}`}>
          <img src={ConfigParam.contFacebook} width="250px" />
        </a>

        <br />
        <br />
        <a href={`/sign_in_via_twitter?redirect_to_after_login=${redirect_to_after_login_path()}`}>
          <img src={ConfigParam.twitter_button} width="250px" />
        </a>

      </div>);
  }

  return (
    <div>
        <h4> {t('login.login')} </h4>

        <hr/>
        <br />
        <span className="float-left">
          {buttons()}
        </span>

        <span className="float-right">
          {signInForm()}
        </span>
    </div>
  )
}

export default SignIn;
