const RedrawDataToPost = ( { uuid } ) => {
  let layoutOptions = objectifyForm($(`#form_${uuid}`));
  let layoutOptionsAdminPanel = objectifyForm($("#admin_panel_form"));
  for (let key in layoutOptionsAdminPanel) { layoutOptions[key] = layoutOptionsAdminPanel[key];}

  let dataToPost = Object.assign({ layout: layoutOptions, authenticity_token: $('meta[name=csrf-token]').attr('content') }, window.dielineOptions);
  return dataToPost;
}

export default RedrawDataToPost;
