import React, { useEffect, useState } from 'react';
import { GetRequest } from 'components/common/index.js';
import { OneOff, Recurring } from 'components/payment/pricing_rows/index.js';

const PricingTables = ({ setMode, setSubscription, setCurrencyName, hideCredits }) => {
  const fetchSubscriptions = () => {
    GetRequest({
      url: '/charge/subscriptions',
      callback: (data) => {
        setCurrencyName(data.currency_name)

        setSubscriptionOneCredit( (_prev) => { return {...data.one_credit} } );
        setSubscriptionFiveCredits( (_prev) => { return {...data.five_credits} } );
        setSubscriptionTenCredits( (_prev) => { return {...data.ten_credits} } );
        setMonthlySubscription( (_prev) => { return {...data.monthly_subscription} } );
        setAnnualSubscription( (_prev) => { return {...data.annual_subscription} } );

        if(hideCredits) {
          setSelectedSubscription('monthly');
        } else {
          setSelectedSubscription('one_credit');
        }
      }
    })
  }

  useEffect(fetchSubscriptions, []);

  const [selectedSubscription, setSelectedSubscription] = useState('')

  const [subscriptionOneCredit, setSubscriptionOneCredit] = useState(undefined)
  const [subscriptionFiveCredits, setSubscriptionFiveCredits] = useState(undefined)
  const [subscriptionTenCredits, setSubscriptionTenCredits] = useState(undefined)
  const [monthlySubscription, setMonthlySubscription] = useState(undefined)
  const [annualSubscription, setAnnualSubscription] = useState(undefined)

  const [selectSubscriptionCTAWording, setSelectSubscriptionCTAWording] = useState(t('sign_up.please_wait'))

  const purchaseCtaMapping = {
    one_credit:   t('sign_up.purchase_one_credit'),
    five_credits: t('sign_up.purchase_five_credits'),
    ten_credits:  t('sign_up.purchase_ten_credits'),
    monthly:      t('sign_up.purchase_monthly_subscription'),
    annually:     t('sign_up.purchase_annual_subscription'),
  }

  useEffect(() => {
    setSelectSubscriptionCTAWording( () => purchaseCtaMapping[selectedSubscription] )
  }, [selectedSubscription])

  const handleSubscriptionRadioSelect = (e) => { setSelectedSubscription(e.target.value); }
  const handleSubscriptionRowClick    = (e) => { setSelectedSubscription(e.target.dataset['value']); }

  useEffect(setUpPreviewExplainedTooltip, [])
  useEffect(setUpManufacturingCostTooltip, [])

  const selectSubscriptionCTA = (e) => {
    e.preventDefault();
    if(selectedSubscription == 'one_credit')   { setSubscription( _prev => subscriptionOneCredit ) }
    if(selectedSubscription == 'five_credits') { setSubscription( _prev => subscriptionFiveCredits ) }
    if(selectedSubscription == 'ten_credits')  { setSubscription( _prev => subscriptionTenCredits ) }
    if(selectedSubscription == 'monthly')      { setSubscription( _prev => monthlySubscription ) }
    if(selectedSubscription == 'annually')     { setSubscription( _prev => annualSubscription ) }

    setMode('payment_form');
  }

  useEffect( () => {
    let listen = 'show_drawing.currency_changed';
    document.addEventListener(listen, fetchSubscriptions);
    return () => { document.removeEventListener(listen, fetchSubscriptions);}
  }, [])

  useEffect(() => {
    if(selectedSubscription != '' && window.promoCurrentHeight == undefined) {
      window.promoCurrentHeight = $("#preview_promo_modal_content_container").css("height");
    }
  }, [selectedSubscription])

  return (<>
     {hideCredits != true && <table width="100%" className="table pricing_table table-hover table-bordered" style={{"marginTop": "30px"}}>
      <tbody>
        <tr style={{"backgroundColor":"#eee"}} key="header">
          <td>
            <div className="plan-head">
              <strong> <h5> {t('sign_up.credits')} </h5> </strong>
            </div>
          </td>
        </tr>
        <OneOff subscription={subscriptionOneCredit} selectedSubscription={selectedSubscription} handleSubscriptionRowClick={handleSubscriptionRowClick} handleSubscriptionRadioSelect={handleSubscriptionRadioSelect} />
        <OneOff subscription={subscriptionFiveCredits} selectedSubscription={selectedSubscription} handleSubscriptionRowClick={handleSubscriptionRowClick} handleSubscriptionRadioSelect={handleSubscriptionRadioSelect} />
        <OneOff subscription={subscriptionTenCredits} selectedSubscription={selectedSubscription} handleSubscriptionRowClick={handleSubscriptionRowClick} handleSubscriptionRadioSelect={handleSubscriptionRadioSelect} />
      </tbody>
    </table>}

     <table width="100%" className="table pricing_table table-hover table-bordered" style={{"marginTop": "30px"}}>
      <tbody>
        <tr style={{"backgroundColor":"#eee"}} key="header">
          <td>
            <div className="plan-head">
              <strong> <h5> {t('sign_up.subscriptions')} </h5> </strong>
            </div>
          </td>
        </tr>

        <Recurring subscription={monthlySubscription} selectedSubscription={selectedSubscription} handleSubscriptionRowClick={handleSubscriptionRowClick} handleSubscriptionRadioSelect={handleSubscriptionRadioSelect} />
        <Recurring subscription={annualSubscription} selectedSubscription={selectedSubscription} handleSubscriptionRowClick={handleSubscriptionRowClick} handleSubscriptionRadioSelect={handleSubscriptionRadioSelect} />

      </tbody>
    </table>

    <div className="price pricing_table">
      <table width="100%" className="">
        <tbody>
        <tr>
          <td>
            <center>
              {selectedSubscription != '' && <a className="btn simple-btn" href="#" onClick={selectSubscriptionCTA}> {selectSubscriptionCTAWording} </a>}
              {selectedSubscription == '' && <a className="btn simple-btn" href="#" disabled="disabled"> {t('sign_up.please_wait')} </a>  }
            </center> </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div className="promo_foot_notes">

      {hideCredits != true && <><small> <sup>*</sup>  {t('sign_up.what_is_preview')}
        <span className="abc_helper_tooltip">
          <a href="#" className="preview_explained" title={t('sign_up.more_about_previews')}>
            <i className="zmdi zmdi-pin-help"></i>
          </a>
        </span>
      </small>
      <br /></>}

       <small> <sup>**</sup>  {t('sign_up.what_is_manufacturing_cost_calculator')}
        <span className="abc_helper_tooltip">
          <a href="#" className="manufacturing_cost_calulator_explained" title={t('sign_up.more_about_manufacturing_cost_calulator')}>
            <i className="zmdi zmdi-pin-help"></i>
          </a>
        </span>
      </small>
    </div>

  </>
  )
}

export default PricingTables;
