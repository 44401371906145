import React, { useEffect, useState } from 'react';
import { PostRequest } from 'components/common/index.js';
import { PricingTables, PaymentForm, ChangeCurrency, PaymentSuccess } from 'components/payment/index.js';

const PreviewExpired = ({ mode, setMode, availableCredits, callback }) => {
  const [subscription, setSubscription] = useState({})
  const [currencyName, setCurrencyName] = useState('')

  const handleBackClick = (e) => {
    e.preventDefault();
    setMode('none');
  }
  useEffect(() => {
    if(mode == 'none') {
      $("#preview_promo_modal_content_container").css("height", "unset");
    }
  }, [mode])

  const backButton = () => {
    return (
      <span style={{'display':'inline', 'float':'right', 'marginBottom': '10px'}}>
          &nbsp; &nbsp;<button className="btn simple-btn" onClick={handleBackClick}>
          <i className="zmdi zmdi-replay"></i> {t('nav.back')}
        </button>
      </span>
    );
  }

  const continueToDownload = (e) => {
    e.preventDefault();
    noPreviewPromoHide();
    callback()
  }

  const handleShowPricingTables = (e) => {
    e.preventDefault();
    setMode( () => 'pricing_tables');
  }

  return(<>
    <div className="testimonials-block preview-promo-block">
      { mode == 'none' && (<>
      <div className="testimonials-photo preview_promo_header">
        <h1 className="preview-promo-photo">
          <i className="fa fa-check" aria-hidden="true"></i>
        </h1>
      </div>

      <div className="testimonials-text preview-promo-title preview_promo_header">
        <div className="testimonials-name">
          <h3> {t('drawing.dimensions_valid')} </h3>
        </div>

        <div className="testimonials-desc">
          <span dangerouslySetInnerHTML={{__html: t('payment.you_have_credits').replace('%{credit_count}', availableCredits)}}></span>
          &nbsp;{t('drawing.ready_to_download')}

          <hr />

          <span dangerouslySetInnerHTML={{__html: t('payment.promote_monthly_and_annual_for_preview') }}></span>

          <hr />

          <a className="btn simple-btn" href="#" onClick={handleShowPricingTables}>  { t('payment.buy_now_and_enable_previews') } </a>
            &nbsp; &nbsp; &nbsp; &nbsp;

          <a className="btn simple-btn" href="#" onClick={continueToDownload}>  { t('payment.continue_to_download') } </a>
        </div>
      </div>
      </>)}

      <div className="testimonials-desc">
        {mode != 'none' && <> <br /> { backButton() } </> }
        {mode != 'none' && <ChangeCurrency currencyName={currencyName} mode={mode} />}
        {mode == 'payment_success' && <PaymentSuccess callback={refreshDownloadButtons} />}
        {mode == 'pricing_tables' && <PricingTables setMode={setMode} setSubscription={setSubscription} setCurrencyName={setCurrencyName} /> }
        {mode == 'payment_form'   && <PaymentForm setMode={setMode} subscription={subscription} setSubscription={setSubscription} setCurrencyName={setCurrencyName}/> }
      </div>
    </div>
  </>)
}

export default PreviewExpired;
