import React, { useState } from 'react';

const LayoutSelectButton = ( { member, items, index, removeItem, setItem, activeRedraws, isMostEfficient} ) => {

  const [redrawInProgress, setRedrawInProgress] = useState(false);

  const isMemberInItems = () => {
    if( items[0]==null ) { return false }

    for(var i=0; i < items.length; i++) {
      if(items[i].uuid == member.uuid) {
        return true;
      }
    }
    return false;
  }

  const hasParts = () => {
    return member.parts.length > 0
  }

  const hasPartInItems = () => {
    if(!hasParts()) { return false }
    if( items[0]==null ) { return false }

    for(var j = 0; j < member.parts.length; j++) {
      for(var i = 0; i < items.length; i++) {
        if( items[i].parts.includes(member.parts[j])) {
          return true;
        }
      }
    }
    return false;

  }

  const handleClick = (member) => {
    if(isMemberInItems()) {
      removeItem(member)
    } else {
      setItem(member)
    }
  }

  const title = () => {
    if(window.allowSingleDownloads) {
      return member.uuid;
    }
    return "";
  }

  return (
    <>
      <button title={title()}
        className={`selectLayoutBtn btn cbp-filter-item ${isMemberInItems() ? 'selectedLayoutBtn': ''}`}
        onClick={() => handleClick(member)}
        disabled={activeRedraws.length > 0 || (false && hasParts() && hasPartInItems() && !isMemberInItems())}
        >
          { isMemberInItems() &&
            <i className="fa fa-check" aria-hidden="true"></i>
          }

          {window.wording_template} {index + 1} {isMostEfficient && (
          <span className="most-efficient-badge">
            <strong>
              <span className="bgred badge" title={window.wording_most_efficient} >
                <i className="fa fa-flag-checkered"> </i></span>
            </strong>
          </span>
          )}
      </button>
    </>
  )
}

export default LayoutSelectButton;
