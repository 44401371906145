import React, { useState, useEffect, useContext } from 'react';
import LayoutContext from 'components/contexts/LayoutContext.jsx';
import OptionRowFloat from 'components/layout/options/OptionRowFloat.jsx'
import LoadFromLocalStorage from 'components/layout/options/LoadFromLocalStorage.jsx'

 const ChooseLayoutDimensions = ({item}) => {
  const myLayoutContext = useContext(LayoutContext);
  const [layout_dimension_id, set_layout_dimension_id] = useState("1");
  const [layout_dimension_width, set_layout_dimension_width] = useState(myLayoutContext.layoutOptions.layout_dimension_width);
  const [layout_dimension_height, set_layout_dimension_height] = useState(myLayoutContext.layoutOptions.layout_dimension_height);
  const [layout_dimension_top_margin, set_layout_dimension_top_margin] = useState(myLayoutContext.layoutOptions.layout_dimension_top_margin);
  const [layout_dimension_side_margin, set_layout_dimension_side_margin] = useState(myLayoutContext.layoutOptions.layout_dimension_side_margin);
  const [layout_dimension_bottom_margin, set_layout_dimension_bottom_margin] = useState(myLayoutContext.layoutOptions.layout_dimension_bottom_margin);

  const layoutDimensionOptions = () => {
    let results = []
    for (let [key, values] of Object.entries(myLayoutContext.dimensionGroups)) {
      results.push(
        <optgroup label={key} key={key}>
          {values.map((value) => {
            return <option key={value.id} value={value.id}> {value.description} </option>
          })}
        </optgroup>
      )
    }
    results.push(
      <optgroup label={window.wording_choose_manual_dimensions} key="custom">
        <option value="custom" key="custom">
          {window.wording_enter_custom_material}
        </option>
      </optgroup>
    )

    return results;
  }

  const dimensionIdSetter = (value) => { set_layout_dimension_id( () => value > 0 ? value : 1 ) }
  useEffect(() => LoadFromLocalStorage(item, 'layout_dimension_id', dimensionIdSetter), []);

  return (
  <div className="LayoutRedrawFormSection">
    <div className="choose_options">
      <h3> <i className="zmdi zmdi-photo-size-select-small"></i> </h3>
      <span className="option-label"> {window.wording_layouts_choose_dimensions} </span>
    </div>

    <input type="hidden" value={myLayoutContext.layoutOptions.layout_dimension_unit} name="layout_dimension_unit"/>

    <div className="input-group short-text LayoutRedrawFormInputRow">
      <select value={layout_dimension_id} className="form-control input_use_local_storage" name="layout_dimension_id" onChange={(e) => set_layout_dimension_id(e.target.value)} >
        {layoutDimensionOptions().map((value) => {
          return value;
        }) }
      </select>
    </div>

    {layout_dimension_id == 'custom' && (<>
      <OptionRowFloat description={window.wording_layouts_width}
        item={item}
        name="layout_dimension_width"
        value={layout_dimension_width}
        unit={myLayoutContext.unit}
        onChange={set_layout_dimension_width}
      />

      <OptionRowFloat description={window.wording_layouts_height}
        item={item}
        name="layout_dimension_height"
        value={layout_dimension_height}
        unit={myLayoutContext.unit}
        onChange={set_layout_dimension_height}
      />
      </>)}

    <OptionRowFloat description={window.wording_layouts_top_margin}
      item={item}
      name="layout_dimension_top_margin"
      value={layout_dimension_top_margin}
      unit={myLayoutContext.unit}
      abc_line='margin_top'
      onChange={set_layout_dimension_top_margin}
    />

    <OptionRowFloat description={window.wording_layouts_side_margin}
      item={item}
      name="layout_dimension_side_margin"
      value={layout_dimension_side_margin}
      unit={myLayoutContext.unit}
      abc_line='margin_side'
      onChange={set_layout_dimension_side_margin}
    />

    <OptionRowFloat description={window.wording_layouts_bottom_margin}
      item={item}
      name="layout_dimension_bottom_margin"
      value={layout_dimension_bottom_margin}
      unit={myLayoutContext.unit}
      abc_line='margin_bottom'
      onChange={set_layout_dimension_bottom_margin}
    />

    </div>
  )
}

export default ChooseLayoutDimensions;
