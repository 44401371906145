import React, { useState, useEffect, useRef }  from 'react';
import MyEventListener from 'components/common/MyEventListener.jsx'
import Constants from 'components/show_drawing/Constants.jsx';
import { LoadFromLocalStorage } from 'components/show_drawing/redraw_form/index.js';

const RedrawOnAllOptionChangesComplete = ( { id, userDowloadId, unit, formRef, drawing, setRedrawRequested, setResetEnabled, debug, initialOptions } ) => {
  const [list, _setList] = useState( [] );
  const [flag, setFlag ] = useState( false );

  const onOptionChangedMsg = (msg) => {
    if(msg.detail.id != id) { return }

    if(list.length == 0) { return }
    if(list.includes(msg.detail.name)){
      emitEvent('addDebugEntry', { event: "[RedrawOnAllOptionChangesComplete] removingFromList", name: msg.detail.name, list: list.join(",") })
      _setList( (prev) => prev.filter( (name) => name !== msg.detail.name ) );
    }
  }

  const onRedrawCompletedMsg = (msg) => {
    if(msg.detail.id != id) { return }

    if(list.length > 0) {
      emitEvent('addDebugEntry', { event: "[RedrawOnAllOptionChangesComplete] redraw completed, clear list", currentlist: list.join(',') })
      _setList( () => [] )
    }
  }

  const setList = (arg) => {
    if(list.length > 0) {
      emitEvent('addDebugEntry', { event: "[RedrawOnAllOptionChangesComplete] setting List but list is not empty" , currentlist: list.join(',') })
    }
    emitEvent('addDebugEntry', { event: "[RedrawOnAllOptionChangesComplete] setting List" , currentlist: list.join(',') })
    _setList( arg )
     setFlag( () => true )
  }

  useEffect( () => {
    if(!flag) {
      emitEvent('addDebugEntry', { event: "[RedrawOnAllOptionChangesComplete] list changed but flag no", list: list.join(",") })
      return
    }

    emitEvent('addDebugEntry', { event: "[RedrawOnAllOptionChangesComplete] list changed", list: list.join(",") })

    if(list.length == 0) {
      setTimeout( () => {
        setRedrawRequested( () => true);
        setFlag(() => false)
      }, 100 )
    }

  }, [list] )

  MyEventListener([
    {
      key: Constants.drawing_option_change_completed,
      callback: onOptionChangedMsg
    },{
      key: Constants.drawing_redraw_completed,
      callback: onRedrawCompletedMsg
    }
  ])

  return<>

    <LoadFromLocalStorage
      id={id}
      unit={unit}
      userDowloadId={userDowloadId}
      drawing={drawing}
      setList={setList}
      setResetEnabled={setResetEnabled}
      debug={debug}
      formRef={formRef}
      initialOptions={initialOptions}
    />

    {debug && <div> RedrawOnAllOptionChangesComplete [ { list.join(',') } ]  </div> }
    </>
}

export default RedrawOnAllOptionChangesComplete;
