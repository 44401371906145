import React, {useState, useEffect, createRef}  from 'react';
import { MyDownloadsButton, DownloadConfirmation } from 'components/show_drawing_download_section/download_buttons/index.js';
import { ContentPopup } from 'components/ContentPopup.jsx';
import Constants from 'components/show_drawing/Constants.jsx';
import MyEventListener from 'components/common/MyEventListener.jsx'
import Progressbar from 'components/common/Progressbar.jsx'

const LoggedInCanDownload = ( { id, drawing, currentDrawingOptions, currentUserDrawingOptions, advancedOptionsVisible, dimensionTextschecked, redrawInProgress } ) => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [pleaseWait, setPleaseWait] = useState(false)
  const [format, setFormat] = useState("");
  const popupRef = createRef();

  const handleDownloadDXF = (e) => {
    e.preventDefault();
    setFormat( () => 'dxf' )
  }

  const handleDownloadSVG = (e) => {
    e.preventDefault();
    setFormat( () => 'svg' )
  }

  const handleDownloadPDF = (e) => {
    e.preventDefault();
    setFormat( () => 'pdf' )
  }

  const closePopUp = () => {
    if( popupRef.current != undefined) {
      popupRef.current.style.display = "none";
    }
    setShowPopUp(() => false);
    setFormat( () => "" )
    setPleaseWait( () => false )
  }

  const getContent = () => {
    if(pleaseWait) { return <Progressbar />}

    return <DownloadConfirmation
      id={id}
      drawing={drawing}
      currentDrawingOptions={currentDrawingOptions}
      currentUserDrawingOptions={currentUserDrawingOptions}
      advancedOptionsVisible={advancedOptionsVisible}
      fileFormat={format}
      dimensionTextschecked={dimensionTextschecked}
    />
  }


  const popupContent = () => {
    let onReady = () => {}
    return <ContentPopup
      contextClass="wrapper"
      myRef={popupRef}
      autoOpen={true}
      content={getContent()}
      cancelButton={false}
      beforeClose={closePopUp}
      onReady={onReady}
      notWide={true}
    />;
  }

  // useEffect
  useEffect( () => {
    if(format == "") { return }

    setShowPopUp( () => true);
  }, [format])
  // useEffect

  MyEventListener([
    {
      key: Constants.drawing_download_started,
      callback: (msg) => {
        if(msg.detail.id == id) {
          setPleaseWait( () => true )
        }
      }
    }, {
      key: Constants.drawing_download_finished,
      callback: (msg) => {
        if(msg.detail.id == id) {
          closePopUp()
        }
      }
    }
  ])

  return (<>
    <div className="downloadButtonscontainer">

      <div className="inner">
        <div className="child">
          <button id="download_as_dxf" className="btn btn-default btn-lg download-btn" onClick={handleDownloadDXF} disabled={redrawInProgress}>
            <img className="downloadIcon"src={window.dxf_icon} /> &nbsp; {t('models.download_as_dxf')}
          </button>
        </div>
      </div>

      <div className="inner">
        <div className="child">
          <button id="download_as_svg" className="btn btn-default btn-lg download-btn" onClick={handleDownloadSVG} disabled={redrawInProgress}>
            <img className="downloadIcon" src={window.svg_icon} /> &nbsp; {t('models.download_as_svg')}
          </button>
        </div>
      </div>

      <div className="inner">
        <div className="child">
          <button id="download_as_pdf" className="btn btn-default btn-lg download-btn" onClick={handleDownloadPDF} disabled={redrawInProgress} >
            <img src={window.pdf_icon} className="downloadIcon"/> &nbsp; {t('models.download_as_pdf')}
          </button>
        </div>
      </div>

      <MyDownloadsButton id={id} drawing={drawing} />
    </div>

    <div className="download_instructionsCont">
      <span className="download_instructions" dangerouslySetInnerHTML={{__html: t("drawing.download_instructions_for_layout") }}></span>
    </div>
     {showPopUp && popupContent()}
     <br />
     <br />
    </>
  );
}

export default LoggedInCanDownload;
