import React, { useState, useEffect, useContext, useRef } from 'react';
import { PostRequest } from 'components/common/index.js';
import QuoteBuilderNavColumn from 'components/quote_builder/QuoteBuilderNavColumn.jsx'
import QuoteBuilderForm from 'components/quote_builder/QuoteBuilderForm.jsx';


// # has_many QuoteBuilderNavColumns
const QuoteBuilderNavSection = ({ config, payload, presentedOptions, navSection }) => {

  const [items, setItems] = useState([]);

  const getExtraItemsAction = (key_value, section_uuid, values, item, options) => {
    let user_quote_options = { user_quote_id: window.user_quote_id };
    let extraItemOptions = {
      is_item_extra: item.is_item_extra,
      extra_item_reason: item.extra_item_reason
    };

    let dataToPost = { ...{values}, ...{navSection}, ...options, extraItemOptions, ...{user_quote_options}, ...{key_value}, ... { material_attribute: values.material_attribute } };
    let filterFuncSame = (obj, index, self) => index === self.findIndex(t => JSON.stringify(t) === JSON.stringify(obj));
    let requesting_section_uuid = key_value.key + "@" + section_uuid + "@" + item.extra_item_reason + '';
    let queryMeta = `?query-name=extra_items&req=${requesting_section_uuid}&is_item_extra=${item.is_item_extra}&extra_item_reason=${item.extra_item_reason}&k=${JSON.stringify(key_value)}}`;

    PostRequest({
      url: `/quote-builder/extra-items/${requesting_section_uuid}/${item.uuid}/${item.extra_item_reason}${queryMeta}`,
      data: {
        options: dataToPost
      },
      callback: (data) => {
        let NoLongerInNewItemsFilter = (obj, index, self) => {
          let keep = true;
          if(obj.is_item_extra) {
            let foundInExtraItemsIndex = data.extra_items.findIndex(t => t.is_item_extra && t.extra_item_reason == obj.extra_item_reason && t.requesting_section_uuid == requesting_section_uuid);
            if(foundInExtraItemsIndex < 0 && obj.requesting_section_uuid == requesting_section_uuid) {
              keep = false;
            }
          }

          return keep;
        };

        setItems( (prev) => {
          return prev.concat(data.extra_items).filter(filterFuncSame).filter(NoLongerInNewItemsFilter);
        });

      }
    });
  };

  const getExtraItemsActionTimer = useRef();

  const getExtraItems = (key_value, section_uuid, values, item, options) => {
    if(getExtraItemsActionTimer.current != undefined) { clearTimeout(getExtraItemsActionTimer.current) }
    getExtraItemsActionTimer.current = setTimeout(() => { getExtraItemsAction(key_value, section_uuid, values, item, options); }, 500);
  }

  const sliceNumber = 2;

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  const getSubSectionFromPart = (part) => {
    return part + ", ";
  }

  useEffect(() => {
    if(navSection.parts.length > 0) {
      navSection.parts.map((navSectionPart) => {
        payload.items.map((item) => {
          if(item.parts.indexOf(navSectionPart) > -1) {
            setItems((prev) => { return prev.concat(item).filter(onlyUnique) } )
          }
        })
      })
    } else {

      let checkMemberInPayload = (member) => {
        payload.items.map((item) => {
          if(item.uuid == member.uuid) {
            setItems((prev) => { return prev.concat(item).filter(onlyUnique) } );
          }});
      };

      navSection.members.map(checkMemberInPayload);
      navSection.sub_sections.map((subSection) => {
        subSection.members.map(checkMemberInPayload);
      });

      if(navSection.part_type == 'general') {
        setItems((_prev) => { return [navSection.drawing] } )
      }
    }

  }, []);

  const navSectionForm = () => {
    let item = items[0];

    return <QuoteBuilderForm
      config={config.nav_section}
      navSection={navSection}
      item={item}
      options={payload[item.uuid]}
      getExtraItems={getExtraItems}
    />
  }

  const oneColumn = () => {
    let item = items[0];

    return (
      <div>
        <div className="row">
          <div className="col-lg-8 float-right">
            <div className="">
              {navSectionForm()}
            </div>
          </div>
        </div>

        <div className="col-lg-8 float-right">
          <div className="card">
            <QuoteBuilderNavColumn
              config={config}
              item={item}
              options={payload[item.uuid]}
              presentedOptions={presentedOptions[item.uuid]}
              setItems={setItems}
              navSection={navSection}
              getExtraItems={getExtraItems}
              colIndex={0}
            />
          </div>
        </div>
      </div>
    )
  }

  const moreColumns = () => {
    return (
       <div className="col-lg-12 newtabcontent navSectionContent">
        <table className={`quote_builder_table_2`}>
          <tbody>
           <tr>
              <td colSpan="2" valign="top" className="sectionTd">
                {navSectionForm()}
              </td>
            </tr>

              {items.each_slice(sliceNumber).map((slice, sliceIndex) => {
                return (
                  <tr>
                    {slice.map((item, colIndex) => {
                      return <td valign="top" className="sectionTd">
                        <div className="card">

                           <QuoteBuilderNavColumn
                              config={config}
                              item={item}
                              options={payload[item.uuid]}
                              presentedOptions={presentedOptions[item.uuid]}
                              setItems={setItems}
                              navSection={navSection}
                              colIndex={`${(sliceIndex * sliceNumber) + colIndex}`}
                            />
                        </div>

                      </td>
                    })}

                  {[...Array(sliceNumber - slice.length)].map(() => {
                    return <td> &nbsp; </td>
                  })}
                  </tr>
                )
              })}

          </tbody>
      </table>
     </div>
    )
  }

  return (<>
    {navSection.type == 'two_layers' && (
      <div className="row" style={{"marginRight" : '30px', 'textAlign': 'right', 'marginLeft' : '-30px'}}>
        <div className="col-lg-12">
          <div className="page-in-name"style={{'backgroundColor': '#f9f9f9', 'color': '#5c5c5c' }}>
            <hr />
              <h1> <strong> {navSection.label} &nbsp; </strong> </h1>
            <hr />
          </div>
        </div>
      </div>
    )}

   <div className={`col-lg-12 ${navSection.type == 'two_layers' ? '' : ''} navSection`}>
    {items.length == 1 && oneColumn()}

    {items.length > 1 && moreColumns()}
  </div>
  </>)
}

export default QuoteBuilderNavSection;
