import React, { useState, useEffect, useContext } from 'react';
import LayoutNavSection from 'components/layout/LayoutNavSection.jsx';

const LayoutNavSectionList = (props) => {
  const [requiredParts, setRequiredPartsInternal] = useState([]);
  const [selectedParts, setSelectedPartsInternal] = useState({});
  const [selectedPartsNormalised, setSelectedPartsNormalised] = useState([]);

  const [selectedMembers, setSelectedMembersInternal] = useState({});
  const [selectedMembersNormalised, setSelectedMembersNormalised] = useState([]);

  const [activeRedraws, setActiveRedraws] = useState([]);

  const setRequiredParts = (parts) => {
    setRequiredPartsInternal( (previous) => previous.concat(parts) )
  }

  const setSelectedParts = (parts, id) => {
    setSelectedPartsInternal( (previous) => {
      previous[id] = parts;
      return { ...previous };
    });
  }

  const updateSelectedPartsNormalised = () => {
    let res = [];
    for (const index in selectedParts) {
      res = res.concat(selectedParts[index]).flat();
    }
    setSelectedPartsNormalised(res);
  }

  const setSelectedMembers = (members, id) => {
    setSelectedMembersInternal( (previous) => {
      previous[id] = members;
      return { ...previous };
    });

    setSelectedParts(members.map((member) => member.part_names).flat(), id);
  }

  const updateSelectedMembersNormalised = () => {
    let res = [];
    for (const index in selectedMembers) {
      res = res.concat(selectedMembers[index]).flat();
    }
    setSelectedMembersNormalised(res);
  }

  const missingParts = () => {
    return requiredParts.filter((part) => { return selectedPartsNormalised.indexOf(part) == -1});
  }

  useEffect(updateSelectedPartsNormalised, [selectedParts]);
  useEffect(updateSelectedMembersNormalised, [selectedMembers]);
  useEffect(() =>{ window.selectedMembersNormalised = selectedMembersNormalised} , [selectedMembersNormalised]);

  useEffect(() => {
    if(activeRedraws.length > 0) {
      $(".RedrawLayoutButton").attr('disabled', 'disabled');
      $(".RedrawLayoutButtonImg").attr('src', window.redrawLoadingPngUrl);
      disableDownloadButtons();
    } else {
      enableDownloadButtons();
    }

    $("#RedrawLayoutButton").removeAttr('disabled');
    $("#RedrawLayoutButtonImg").attr('src', window.redrawRefreshPngUrl);
  }, [activeRedraws]);

  return (<>
      {props.navSections.map((navSection, index) => {
        return <LayoutNavSection
          key={`navSection_${index}`}
          setSelectedMembers={setSelectedMembers}
          activeRedraws={activeRedraws}
          setActiveRedraws = {setActiveRedraws}
          navSection={navSection}
          index={index}
          setRequiredParts={setRequiredParts}
        />})}
    {false && missingParts().length > 0 && (
     <div className="col-lg-12 newtabcontent">
      <div className="col-lg-9 float-right">
        <div className="alert alert-danger fade in">
          <span className=""> <i className="fa fa-exclamation-circle"></i>  <strong> {`${window.wording_select_templates_for_parts.replace('(?)',missingParts().map(str => `"${str}"`).join(', ')) }`} </strong> </span>
          <br />
        </div>
      </div>
    </div>
    )}
    </>
  )
}

export default LayoutNavSectionList;
