import React from 'react';

const HiddenInput = (props) => {

  return (
    <div className="input-group number_input">
      <input
        type="hidden"
        name={props.name}
        value={props.value}
        />
    </div>
  )
}

export default HiddenInput
