import React from 'react';
import { LengthInput, Tooltip } from 'components/common';

const LengthFormField = ({ config, value, setValue, unit, register, errors, disabled, setFetchInProgress }) => {
  return (
    <tr>
      <td> {t(config.label)} { config.tooltip_text != undefined && <Tooltip title={t(config.tooltip_text)} /> } </td>
      <td align="right">
        <LengthInput
          name={config.name}
          register={register}
          errors={errors}
          unit={config.length_unit_in_values_need_translate ? t('quote_builder.' + unit) : unit}
          value={value}
          class_name={config.class_name}
          validators={config.validators != undefined ? config.validators : []}
          placeholder={config.placeholder}
          onChange={(e) => setValue(config.name, e.target.value)}
        />
      </td>
    </tr>
  )
}

export default LengthFormField;
