import React, {useState, useEffect}  from 'react';
import { MyDownloadsButton } from 'components/show_drawing/download_buttons/index.js';

const LoggedInCanDownload = ({}) => {

  const handleDownloadDXF = (e) => {
    e.preventDefault();
    $("#dxf_dimension_texts_included").val( dimensionTextsIncluded() );
    on_download_form_submit(event, "#download_form_dxf", ".dxf");
  }

  const handleDownloadSVG = (e) => {
    e.preventDefault();
    $("#svg_dimension_texts_included").val( dimensionTextsIncluded() );
    on_download_form_submit(event, "#download_form_svg", ".svg");
  }

  const handleDownloadPDF = (e) => {
    e.preventDefault();
    $("#pdf_dimension_texts_included").val( dimensionTextsIncluded() );
    on_download_form_submit(event, "#download_form_pdf", ".pdf");
  }

  return (<>
    <div className="downloadButtonscontainer">

      <div className="inner">
        <div className="child">
          <button id="download_as_dxf" className="btn btn-default btn-lg download-btn" onClick={handleDownloadDXF}>
            <img className="downloadIcon"src={window.dxf_icon} /> &nbsp; {t('models.download_as_dxf')}
          </button>
        </div>
      </div>

      <div className="inner">
        <div className="child">
          <button id="download_as_svg" className="btn btn-default btn-lg download-btn" onClick={handleDownloadSVG}>
            <img className="downloadIcon" src={window.svg_icon} /> &nbsp; {t('models.download_as_svg')}
          </button>
        </div>
      </div>

      <div className="inner">
        <div className="child">
          <button id="download_as_pdf" className="btn btn-default btn-lg download-btn" onClick={handleDownloadPDF}>
            <img src={window.pdf_icon} className="downloadIcon"/> &nbsp; {t('models.download_as_pdf')}
          </button>
        </div>
      </div>

      <MyDownloadsButton />
    </div>

    <div className="download_instructionsCont">
      <span className="download_instructions" dangerouslySetInnerHTML={{__html: t("drawing.download_instructions_for_layout") }}></span>
    </div>
  </>
  );
}

export default LoggedInCanDownload;
