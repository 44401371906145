import React, { useState, useEffect, useRef }  from 'react';
import { GetRequest } from 'components/common/index.js';
import { ContentPopup } from 'components/ContentPopup.jsx';

const MyDownloadsButton = ({}) => {
  const [userDownloadCount, setUserDownloadCount] = useState(0)

  const getUserDownloadsCount = () => {
    if(window.drawing_id == undefined) { return; }

    GetRequest({
      url: `/${window.drawing_id}/user-downloads-btn-count`,
      callback: (data) => {
        setUserDownloadCount(() => data.count);
      }
    })
  }

  useEffect( () => {
    let listen = 'show_drawing.update_user_downloads_count_required';
    document.addEventListener(listen, getUserDownloadsCount);
    return () => { document.removeEventListener(listen, getUserDownloadsCount);}
  }, [])

  useEffect(getUserDownloadsCount, []);

  const handleShowDownloads = (e) => {
    e.preventDefault();
    setUpUserDownloadsBtn()

    if(userDownloadCount > 0) {
      fetchUserDownloads()
    }
  }

  if(userDownloadCount == 0) { return }

  return (
    <div className="inner">
      <div className="child float-right">
       <button className="btn btn-default btn-lg download-btn" onClick={handleShowDownloads}>
        <span className="icon-download"></span> &nbsp; { t("models.my_downloads") }  &nbsp;
        <span className="badge badge-light" id="user_download_count_cont" style={{"backgroundColor":"#eb5424", "fontWeight":"bold"}}>
        {userDownloadCount}
       </span>
       </button>
      </div>
    </div>
  )
}

export default MyDownloadsButton;