import React, { useState, useEffect }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import { GetRequest } from 'components/common/index.js';
import Constants from 'components/show_drawing/Constants.jsx';

const OptionPresets = ( { id, drawing, unit, setRedrawRequested} ) => {
  const [selectedOption, setSelectedOption] = useState(-1)
  const onChange = (e) => {
    setSelectedOption( () => e.target.value )
  }

  const fetchOptionPreset = () => {
    //https://diecuttemplates.com.dev/option-presets/3/mm
    GetRequest({
      url: `/option-presets/${selectedOption}/${unit}`,
      callback: (data) => {
        const names = data.map(obj => obj.name);
        const values = {}
        data.map(obj => { values[obj.name] = obj.value} );

        emitEvent(Constants.drawing_set_values_and_redraw, { id: id, names: names, values: values })
      }
    })

  }
  const onSelectedOptionChanged = () => {
    if(selectedOption < 0) { return }
    fetchOptionPreset();
  }

  // useEffect
    useEffect(onSelectedOptionChanged, [selectedOption])
  // useEffect

  return (
    <div className={styles.row}>
      <span> { t('drawing.presets') } </span>
      <select value={selectedOption} onChange={onChange} className={`form-control ${styles.form_control_v2}`}>
        <option value="-1"> { t('drawing.choose_manual_dimensions')} </option>
        <optgroup label={t('drawing.presets')}>

          {drawing.option_presets.map( (option_preset) => {
            return <option key={option_preset.id} value={option_preset.id}> { option_preset.name} </option>
          } )}

        </optgroup>
      </select>
    </div>
  )
}

export default OptionPresets;
