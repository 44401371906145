import React, { useState, useEffect }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import Constants from 'components/show_drawing/Constants.jsx';
import MyEventListener from 'components/common/MyEventListener.jsx'

const OptionAlignment = ( { id, option, setRedrawRequested, addToOptionsUserChanged } ) => {
  const [changed, setChanged] = useState(false)
  const DB_ALIGNMENT_TOP_LEFT   = 2
  const DB_ALIGNMENT_TOP_CENTER = 12
  const DB_ALIGNMENT_TOP_RIGHT  = 22

  const DB_ALIGNMENT_LEFT   = 1
  const DB_ALIGNMENT_CENTER = 11
  const DB_ALIGNMENT_RIGHT  = 21

  const DB_ALIGNMENT_BOTTOM_LEFT   = 0
  const DB_ALIGNMENT_BOTTOM_CENTER = 10
  const DB_ALIGNMENT_BOTTOM_RIGHT  = 20

  const [selectedValue, setSelectedValue] = useState(option.default_value != undefined ? option.default_value : 11)
  const onChange = (e) => {
    setSelectedValue( () => e.target.value)
    addToOptionsUserChanged(option);
    setChanged( () => true )
  }

  const onDrawingOptionValueSetMsg = (msg) => {
    if(msg.detail.id == id && msg.detail.name == option.name) {
      setSelectedValue( () => msg.detail.value )
    }
  }
  // useEffect
  useEffect( () => { emitEvent(Constants.drawing_option_change_completed, {id: id, name: option.name}) } , [selectedValue] )
  // useEffect
  MyEventListener([
    {
      key: Constants.drawing_option_set_value,
      callback: onDrawingOptionValueSetMsg
    }
  ]);

  const show = (v) => {
   if(option.possible_values == undefined ||option.possible_values.length == 0 ) {
    return true
   }
   return option.possible_values.includes(v);
  }

  const input = () => {
    return (
    <div className="preview-alignment-options alignment-options">
       <table width="100%">
        <tr>
          <td>
          {show(DB_ALIGNMENT_TOP_LEFT) && (
            <label>
              <input className="alignment-radio" type="radio" name={option.name} value={DB_ALIGNMENT_TOP_LEFT}
              checked={selectedValue == DB_ALIGNMENT_TOP_LEFT} onChange={onChange} />
              <img src={window.img_arrow_top_left} height="20" />
            </label>
          )}

       </td>
          <td>
           {show(DB_ALIGNMENT_TOP_CENTER) && (
          <label>
            <input className="alignment-radio" type="radio" name={option.name} value={DB_ALIGNMENT_TOP_CENTER}
            checked={selectedValue == DB_ALIGNMENT_TOP_CENTER} onChange={onChange} />
             <img src={window.img_arrow_top} height="20" />
          </label>
         )}
       </td>
          <td>
           {show(DB_ALIGNMENT_TOP_RIGHT) && (
            <label>
              <input className="alignment-radio" type="radio" name={option.name} value={DB_ALIGNMENT_TOP_RIGHT}
               checked={selectedValue == DB_ALIGNMENT_TOP_RIGHT} onChange={onChange} />
               <img src={window.img_arrow_top_right} height="20" />
             </label>
            )}
          </td>
        </tr>

        <tr>
          <td>
           {show(DB_ALIGNMENT_LEFT) && (
            <label>
              <input className="alignment-radio" type="radio" name={option.name} value={DB_ALIGNMENT_LEFT}
               checked={selectedValue == DB_ALIGNMENT_LEFT} onChange={onChange} />
               <img src={window.img_arrow_left} height="20" />
            </label>
            )}
       </td>
          <td>
           {show(DB_ALIGNMENT_CENTER) && (
          <label>
            <input className="alignment-radio" type="radio" name={option.name} value={DB_ALIGNMENT_CENTER}
            checked={selectedValue == DB_ALIGNMENT_CENTER} onChange={onChange} />
            <img src={window.img_arrow_center} height="20" />
          </label>
          )}
       </td>
          <td>
           {show(DB_ALIGNMENT_RIGHT) && (
             <label>
            <input className="alignment-radio" type="radio" name={option.name} value={DB_ALIGNMENT_RIGHT}
            checked={selectedValue == DB_ALIGNMENT_RIGHT} onChange={onChange} />
             <img src={window.img_arrow_right} height="20" />
             </label>
             )}
          </td>
        </tr>
        <tr>
          <td>
           {show(DB_ALIGNMENT_BOTTOM_LEFT) && (
            <label>
              <input className="alignment-radio" type="radio" name={option.name} value={DB_ALIGNMENT_BOTTOM_LEFT}
                checked={selectedValue == DB_ALIGNMENT_BOTTOM_LEFT} onChange={onChange} />
              <img src={window.img_arrow_bottom_left} height="20" />
            </label>
           )}
       </td>
          <td>
           {show(DB_ALIGNMENT_BOTTOM_CENTER) && (
          <label>
            <input className="alignment-radio" type="radio" name={option.name} value={DB_ALIGNMENT_BOTTOM_CENTER}
              checked={selectedValue == DB_ALIGNMENT_BOTTOM_CENTER} onChange={onChange} />
             <img src={window.img_arrow_bottom} height="20" />
          </label>
         )}
       </td>
          <td>
           {show(DB_ALIGNMENT_BOTTOM_RIGHT) && (
            <label>
              <input className="alignment-radio" type="radio" name={option.name} value={DB_ALIGNMENT_BOTTOM_RIGHT}
               checked={selectedValue == DB_ALIGNMENT_BOTTOM_RIGHT} onChange={onChange} />
               <img src={window.img_arrow_bottom_right} height="20" />
             </label>
             )}
          </td>
        </tr>
      </table>
    </div>
    )
  }

  // useEffect starts
  useEffect( () => setRedrawRequested( () => changed ), [selectedValue] )
  // useEffect ends
  return (
    <div className={`${styles.row} ${styles.row_alignment}`}>
      <div className="input-group">
        <span className={`input-group-addon input-group-addon-${window.locale} ${styles.input_add_on_for_alignment}`}>
          {option.presented_name}
        </span>

        <div className={styles.div_alignment}>
          {input()}
        </div>

      </div>
    </div>
)
}

export default OptionAlignment;
