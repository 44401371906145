import React from 'react';

const CurrencyInput = (props) => {
  const recent = () => {
    return(
      <optgroup label={props.labelRecent}>
        {props.recent.map( (currency) => {
          return <option value={currency.iso_code} key={currency.iso_code}> {currency.name} </option>
        })}
      </optgroup>
    )
  }

  return (
    <div>
      <select
        className={`form-control ${props.class_name != undefined ? props.class_name : 'general-frm-element'}`}
        name={props.name != undefined ? props.name : 'currency' }
        value={props.value}
        onChange={props.onChange}
      >
       { props.recent?.length > 0 && recent() }

       {props.all.map((currency, index) => {
          return <option value={currency.iso_code} key={index}> {currency.iso_code} - {currency.name} </option>
        })}
      </select>
    </div>
  )
}

export default CurrencyInput
