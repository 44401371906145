import React, { useState, useEffect }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import Constants from 'components/show_drawing/Constants.jsx';
import MyEventListener from 'components/common/MyEventListener.jsx'

const InternalDimensionsChooser = ( { id, unit, drawing, setRedrawRequested } ) => {
  const [value, setValue] = useState(0)
  const [changed, setChanged] = useState(false)
  const handleChange = (e) => {
    e.preventDefault();
    setValue( () => e.target.value )
    setChanged( () => true )
  }

  const onDrawingOptionValueSetMsg = (msg) => {
    if(msg.detail.id != id) { return }
    if(msg.detail.name == 'internal_dimensions') {
      setValue( () =>  msg.detail.value ? 1 : 0 )
      setChanged( () => false )
    }
  }

  // useEffect
  useEffect( () => {
    setRedrawRequested( () => changed )
    emitEvent(Constants.drawing_option_change_completed, { id: id, name: 'internal_dimensions' });
  }, [value] )

  useEffect(() => { setValue( () => 0 ) } , [id, drawing, unit])
  // useEffect

  MyEventListener([{
    key: Constants.drawing_option_set_value,
    callback: onDrawingOptionValueSetMsg,
  }])

  return (
    <div className={styles.row}>
      <select name="internal_dimensions" value={value} onChange={handleChange} className={`form-control ${styles.form_control_v2}`}>
         <option value="0"> { t('drawing.option_external_dimensions') } </option>
         <option value="1"> { t('drawing.option_internal_dimensions') } </option>
      </select>
    </div>)
}

export default InternalDimensionsChooser;
