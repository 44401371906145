import React, { useState, useEffect } from 'react';
import { NumberInput, Tooltip } from 'components/common';

const DelayedNumberFormField = ({ config, value, setValue, register, errors, disabled, setFetchInProgress, integerOnly, requestPublishItemOnChange }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleDelayedInputValue = (value) => {
    setValue(config.name, value);
    if(config.publish_on_change_key != undefined) {
      var event = new CustomEvent(config.publish_on_change_key, { "detail": value });
      document.dispatchEvent(event);
    }

    if((config.publish_item_on_change && config.publish_item_if_eval == undefined) || (config.publish_item_on_change && eval(config.publish_item_if_eval)) ) {
      requestPublishItemOnChange({ publish_item_key: config.publish_item_key });
    }
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => handleDelayedInputValue(inputValue), 500);
    return () => clearTimeout(timeOutId);
  }, [inputValue]);

  return (
    <tr>
      <td> {t(config.label)} { config.tooltip_text != undefined && <Tooltip title={t(config.tooltip_text)} /> }</td>
      <td align="right">
        <NumberInput
          name={config.name}
          placeholder={config.placeholder}
          register={register}
          errors={errors}
          class_name={config.class_name}
          integerOnly={integerOnly}
          validators={config.validators != undefined ? config.validators : []}
          value={inputValue}
          onChange={event => setInputValue(event.target.value)}
        />
      </td>
    </tr>
  )
}

export default DelayedNumberFormField;
