import React, { useState, useEffect }  from 'react';
import ToolTip from 'components/show_drawing/ToolTip.jsx';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import MyEventListener from 'components/common/MyEventListener.jsx'
import Constants from 'components/show_drawing/Constants.jsx';

const PrintingMethodChooser = ({ id, unit, drawing }) => {
  const [printingMethod, setPrintingMethod] = useState('offset')

  const onDrawingOptionValueSetMsg = (msg) => {
    if(msg.detail.id == id && msg.detail.name == 'printing_method') {
      emitEvent('addDebugEntry', { event: "[PrintingMethodChooser] printingMethod changed", name: msg.detail.name, value: msg.detail.value})
      if(printingMethod == msg.detail.value) { publishChangedEvent() }
      setPrintingMethod( () => msg.detail.value)
    }
  }

  const publishChangedEvent = () => {
    emitEvent(Constants.drawing_option_change_completed, {id: id, name: 'printing_method'})
    emitEvent('addDebugEntry', { event: "[PrintingMethodChooser] SetValue event emitted", id: id, name: 'printing_method', value: printingMethod} )
  }

  // useEffect
  useEffect(publishChangedEvent, [id, unit, drawing,printingMethod] )

  useEffect( () => { setPrintingMethod( () => 'offset' ) }, [id, unit, drawing])
  // useEffect

  MyEventListener([
    {
      key: Constants.drawing_option_set_value,
      callback: onDrawingOptionValueSetMsg
    }
  ])

  return (
    <div className={styles.row}>
     <span> { t('drawing.printing_method') } </span>
      <ToolTip title={t('drawing.help')} toolTipKey='offset' />
      <select style={{ 'display': 'inline' }} name="printing_method" value={printingMethod} onChange={(e) => setPrintingMethod(e.target.value)} className={`form-control ${styles.form_control_v2}`}>
        <option value="offset"> {t('drawing.offset_printing')} </option>
        <option value="flexo"> {t('drawing.flexo_printing')} </option>
      </select>
    </div>
  )
}

export default PrintingMethodChooser;
