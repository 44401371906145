import React, { useState, useEffect, useRef }  from 'react';
import { MyDownloadsButton } from 'components/show_drawing/download_buttons/index.js';
import { ContentPopup } from 'components/ContentPopup.jsx';
import { PricingTables, PaymentForm, ChangeCurrency, PaymentSuccess } from 'components/payment/index.js';

const PurchaseRequired = ({}) => {
  const handleClick = (e) => {
    e.preventDefault()
    setShowPopUp(() => true);
  }

  const [showPopUp, setShowPopUp] = useState(false);
  const popupRef = useRef();

  const [mode, setMode] = useState('pricing_tables');

  const handleBackClick = (e) => {
    e.preventDefault();
    setMode('pricing_tables');
  }

  const [subscription, setSubscription] = useState({})
  const [currencyName, setCurrencyName] = useState('')

  const backButton = () => {
    return (
      <span style={{'display':'inline', 'float':'right'}}>
        &nbsp;
        &nbsp;
        &nbsp;
        <button className="btn simple-btn"  onClick={handleBackClick}>
          <i className="zmdi zmdi-replay"></i> {t('nav.back')}
        </button>
      </span>
    );
  }

  const popupContent = () => {
    let callback = () => {
      popupRef.current.style.display = "none";
      setShowPopUp(() => false);
    };

    let content = (
      <div className="testimonials-block preview-promo-block">
        <span className="testimonials-desc" dangerouslySetInnerHTML={{__html: t('quote_builder.quote_builder_not_available_purchase_required')}}></span>
        <div className="testimonials-desc">
          {mode == 'payment_form' && backButton() }
          {mode == 'pricing_tables' && <br />}
          <ChangeCurrency currencyName={currencyName} mode={mode} />
          {mode == 'payment_success' && <PaymentSuccess callback={callback} />}
          {mode == 'pricing_tables' && <PricingTables setMode={setMode} setSubscription={setSubscription} setCurrencyName={setCurrencyName} hideCredits={true} /> }
          {mode == 'payment_form'   && <PaymentForm setMode={setMode} subscription={subscription} setSubscription={setSubscription} setCurrencyName={setCurrencyName}/> }
        </div>
      </div>
    )

    return <ContentPopup
      contextClass="wrapper"
      myRef={popupRef}
      autoOpen={true}
      content={content}
      cancelButton={false}
      beforeClose={callback}
      notWide={true}
    />;
  }

  const performShowPopup = () => {
    setShowPopUp( () => true);
  }

  useEffect( () => {
    let listen = 'quote_builder.purchase_required';
    document.addEventListener(listen, performShowPopup);
    return () => { document.removeEventListener(listen, performShowPopup);}
  }, [])

  return (
    <div>
      {showPopUp && popupContent()}
    </div>
  )
}

export default PurchaseRequired;
