const LayoutMostEfficient = (members, uuid, callback) => {
  let layoutOptions = objectifyForm( $(`#form_${uuid}`) );
  const options = Object.assign({ layout: layoutOptions, authenticity_token: $('meta[name=csrf-token]').attr('content'), members: members }, window.dielineOptions);

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(options)
  };

  if(members.length == 1) {
    callback([]);
    return;
  }

  fetch('/layouts-most-efficient', requestOptions)
    .then(response => response.json())
    .then(data => {
      callback(data);
  })
}

export default LayoutMostEfficient;
