import React, { useEffect, useState, useRef } from 'react';

const MyEventListener = ( events ) => {
  const eventMapping = useRef({})
  const eventQueue = useRef([]);
  const setEventQueue = ( setter ) => { eventQueue.current = setter(eventQueue.current) }

  const processEventQueue = () => {
   for (const [key, msg] of Object.entries(eventQueue.current)) {
      for(let j = 0 ; j < msg.length; j++) {
        eventMapping.current[key]( msg[j] );
      }

      setEventQueue( (prev) => {
        prev[key] = []
        return {...prev}
      } );
    }
    setTimeout(processEventQueue, 10);
  }

  for(let i = 0; i < events.length; i++) {
    let key = events[i].key
    let callback = events[i].callback

    eventMapping.current[key] = callback;

    useEffect( () => {
      var messageReceived = (msg) => {
        // console.log({messageReceived: key, message: msg})
        setEventQueue( (prev) => {
          if(prev[key] == undefined) { prev[key] = []}
          prev[key] = prev[key].concat( [msg] );
          return { ...prev }
        } )
      }
      document.addEventListener(key, messageReceived);
      return () => { document.removeEventListener(key, messageReceived);}
    }, [])
  }

  useEffect(processEventQueue, []);

}

export default MyEventListener;
