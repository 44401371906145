import React from 'react';
import { ValidatorFactory } from 'components/common';

const StringInput = (props) => {
  const validate = (val) => {
    if(props.validators != undefined) {
      for(let i=0; i< props.validators.length; i++) {
        let validator = props.validators[i];

        if(!(ValidatorFactory(validator, val) || ValidatorFactory(validator, props.value)) ) {
          return t(`quote_builder.validator_${validator}_message`);
        }
      }
    }
  }

  return (
    <>
    <div className="input-group number_input general-frm-element">
      <input
        type={`${props.email ? 'email': 'text'}`}
        onWheel={(e) => e.target.blur()}
        className={`${props.errors[props.name] ? 'input-error form-control' : 'form-control'} ${props.class_name != undefined ? props.class_name : 'general-frm-element'}`}
        size="10"
        autoComplete="off"
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        {...props.register(props.name, {
          required: window.t_price_calculator_required,
          validate: validate,
          onChange: props.onChange
        })}
        />
    </div>
   {props.errors[props.name] && <small className="error"> {props.errors[props.name].message} </small>}
    </>
  )
}

export default StringInput
