import React, { useEffect } from 'react';
import { HiddenInput, GetRequest } from 'components/common';
import { ListenCondition } from 'components/quote_builder/form_fields/index.js'

const HiddenFormField = ({ config, options, value, disabled, setValue, item, navSection, requestGetExtraItems }) => {
  useEffect(() => {
    if(config.subscribe_on_change_key != undefined) {
      let listener = function(e) {
        setValue(config.name, () => { return e.detail } );
        setValue('updated_at', () => { return Date.now() } );
      }
      document.addEventListener(config.subscribe_on_change_key, listener);

      return () => { document.removeEventListener(config.subscribe_on_change_key, listener);}
    }
  }, []);

  const isSameItem = (other) => {
    return item.is_item_extra == other.is_item_extra && item.extra_item_reason == other.extra_item_reason && item.uuid == other.uuid;
  }

  const noOp = () => { }
  if(config.listen_conditions != undefined) {
    for(let i=0; i < config.listen_conditions.length; i++) {

      useEffect(() => {
        let listener = ListenCondition.listener({
          config: config,
          condition: config.listen_conditions[i],
          item: item,
          navSection: navSection,
          setValue: setValue,
          setReadOnly: noOp,
          setReadOnlyMessage: noOp,
          setNotAvailable: noOp,
          setNotAvailableReason: noOp,
          options: options,
          GetRequest: GetRequest
        });

        document.addEventListener(config.listen_conditions[i].listen, listener);
        return () => { document.removeEventListener(config.listen_conditions[i].listen, listener);}

      }, []);
    }
  }

  useEffect(() => {
    if(config.get_extra_items_on_change_if == undefined && config.get_extra_items_on_change) {
      requestGetExtraItems( { key: config.key, value: value } );
    }

    if(config.get_extra_items_on_change_if != undefined && eval(config.get_extra_items_on_change_if)) {
      requestGetExtraItems( { key: config.key, value: value } );
    }
  }, [value])

  return (
    <tr className="hiddenTr">
      <td colSpan="2">
        <HiddenInput
          name={config.name}
          value={value}
        />
      </td>
    </tr>
  )
}

export default HiddenFormField;
