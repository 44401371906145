import React, { useState, useEffect } from 'react';
import QuoteBuilderForm from 'components/quote_builder/QuoteBuilderForm.jsx';
import { PostRequest } from 'components/common/index.js';

import { HotStamping, Material, Printing, Cellophane, Cutting, GuillotineCutting, Glueing, Lamination, Magnet, CoatingLabor, Transport, VCutLabor } from 'components/quote_builder/sections'

const QuoteBuilder = ({ item, config, options, navSection, colIndex, getExtraItems, navSectionColumnType } ) => {
  const [sections, setSections] = useState([]);

  const formBuilderComponent = (section, rowIndex) => {
    const components = {
      HotStamping: HotStamping,
      Material: Material,
      Printing: Printing,
      Cellophane: Cellophane,
      GuillotineCutting: GuillotineCutting,
      Cutting: Cutting,
      Glueing: Glueing,
      Lamination: Lamination,
      Magnet: Magnet,
      CoatingLabor: CoatingLabor,
      Transport: Transport,
      VCutLabor: VCutLabor,
    }
    const SpecificComponent = components[section.react_component_name];

    const configKeys = {
      HotStamping: 'hot_stamping',
      Material: 'material',
      Printing: 'printing',
      Cellophane: 'cellophane',
      Cutting: 'cutting',
      GuillotineCutting: 'guillotine_cutting',
      Glueing: 'glueing',
      Lamination: 'lamination',
      Magnet: 'magnet',
      CoatingLabor: 'coating_labor',
      Transport: 'transport',
      VCutLabor: 'v_cut_labor',
    }
    const sectionConfigKey = configKeys[section.react_component_name];

    const titleTr = () => {
      return (config[sectionConfigKey].title != undefined) && (
        <tr>
          <th colSpan="2">
            {config[sectionConfigKey].title != undefined && t(config[sectionConfigKey].title) }
            {section.extra_item_title != undefined && ` (${t(section.extra_item_title)})` }
          </th>
        </tr>);
    }

    if(!section.render) {
      return
    }

    if(false && section.render_false_reason != undefined && section.render_false_reason != '') {
      return(
        <div className={`${config.class_name != undefined ? config.class_name : ''}`}>
          <table className="table table-striped table-bordered-outside quote_builder_table">
          <tbody>
            {titleTr()}
            <tr>
              <td colSpan="2">
                <div className="well">
                  <i className="fa fa-exclamation-circle"></i>  <small> {t('quote_builder.' + section.render_false_reason)} </small>
                </div>
              </td>
            </tr>
          </tbody>
         </table>
        </div>
      )
    }

    return <SpecificComponent
        key={colIndex + '_' + rowIndex}
        config={config}
        disabled={!section.render}
        extra_item_title={section.extra_item_title}
        getExtraItems={getExtraItems}
        options={section.options}
        uuid={section.uuid}
        item={item}
        navSection={navSection}
        colIndex={colIndex}
        rowIndex={rowIndex}
        navSectionColumnType={navSectionColumnType}
      />;
  }

  const getSections = () => {
    let extraItemOptions = {
      is_item_extra: item.is_item_extra,
      extra_item_reason: item.extra_item_reason
    };

    let layout_part_groups_windows = window.layout_part_group_window_values;
    let user_quote_options = { user_quote_id: window.user_quote_id };

    PostRequest({
      url: `/quote-builder/sections/${item.uuid}`,
      data: {
        options: { ...options, ...extraItemOptions, ...{layout_part_groups_windows}, ...{user_quote_options}, ...{navSection}, ...{navSectionColumnType} }
      },
      callback: (data) => {
        setSections(data.sections);
      }
    })
  };

  useEffect(getSections, []);

  useEffect(() => {
    let listen = 'quote_builder.layout_part_group_window_changed';
    let listener = function(e) {
      let layout_part_group_code = e.detail.code;
      let window_value = e.detail.value;
      if(window.layout_part_group_window_values == undefined) { window.layout_part_group_window_values = {} }
      window.layout_part_group_window_values[layout_part_group_code] = window_value;
      getSections();
    }
    document.addEventListener(listen, listener);

    return () => { document.removeEventListener(listen, listener);}
  }, [])

return (
    <table className="table">
      <tbody>
        {sections.map((section, rowIndex) => {
          return formBuilderComponent(section, rowIndex)
        })}
      </tbody>
    </table>
  )
}

export default QuoteBuilder;
