import { PostRequest } from 'components/common/index.js';

const HandleActivationFormula = ( { option, item, setActive, setWeRecommend } ) => {
  let listener = function(e) {
    if(e.detail == item.uuid) {
      let dataToPost = { ...window.dielineOptions };
      dataToPost['layout_options'] = objectifyForm($(`#form_${item.uuid}`));
      dataToPost['drawing_option_id'] = option.id;

      PostRequest({
        url: `/layouts/${item.klass}/${item.id}/drawing-option-activation`,
        data: dataToPost,
        callback: (data) => {
          setActive( (_prev) => { return data.visible } );
          setWeRecommend( (_prev) => {return data.we_recommend} );
        }
      });
    }
  };
  document.addEventListener('layout_redraw_finished', listener);
  return () => {
    document.removeEventListener('layout_redraw_finished', listener);
  }
}

export default HandleActivationFormula;
