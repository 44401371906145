import React, { useEffect } from 'react';
import LayoutSvg from 'components/quote_builder/LayoutSvg.jsx'
import QuoteBuilder from 'components/quote_builder/QuoteBuilder.jsx'
import { Tooltip } from 'components/common';
import { PostRequest } from 'components/common/index.js';

const QuoteBuilderNavColumn = ({ config, item, options, presentedOptions, setItems, navSection, colIndex, getExtraItems}) => {

  // /quote-builder/reset-nav-column/:user_quote_id/:item_uuid/:extra_item_reason
  const onDestruct = () => {
    if(item.is_item_extra) {
      PostRequest({
        url: `/quote-builder/reset-nav-column/${window.user_quote_id}/${item.uuid}/${item.extra_item_reason}`,
        data: {},
        callback: (data) => {
          // console.log({data})
        }
      })
    }
    console.log({
      onDestruct: 'onDestruct',
      item: item,
    })

  }

  useEffect(() => {
    return onDestruct;
  }, []);


  const extraItemReasonDescription = () => {
    return (
      <span className="float-right">
        <Tooltip title={t('quote_builder.extra_item_reason_' + item.extra_item_reason)} style="info" isSmall={true} hint={t('quote_builder.column_information')} />
      </span>
    );
  }

  const Svg = () => {
    if(item.klass == 'Drawing') {
      return; // we need a DrawingSvg component here
    }

    return <LayoutSvg
      item={item}
      options={options}
      presentedOptions={presentedOptions}
      navSection={navSection}
    />
  }
  const columnTitle = () => {
    if(navSection.part_type == 'carton_lamination' || ['lamination_extra_item_one'].includes(item.extra_item_reason)) {
      return (
        <span className="float-right">
          <strong> {t('quote_builder.column_title_lamination')}  </strong>
        </span>
      )
    }

    return <br />;
  }

  const navSectionColumnType = () => {
    if(item.navSectionType != undefined) {
      return item.navSectionType;
    }
    return navSection.navSectionType;
  }

  const navSectionInfo = () => {
    return <small> [Kolon: {navSectionColumnType()}] </small>
  }

  return (<>
    {Svg()}

    {window.isAdmin && navSectionInfo() }
    <br />

    <div className="quote_builder_container">

     {false && item.extra_item_reason != undefined && extraItemReasonDescription() }
     {false && item.extra_item_reason == undefined && <br /> }
     {columnTitle()}
      <QuoteBuilder
        item={item}
        config={config}
        options={options}
        navSection={navSection}
        colIndex={colIndex}
        getExtraItems={getExtraItems}
        navSectionColumnType={navSectionColumnType()}
      />
    </div>
   </>)
}

export default QuoteBuilderNavColumn;
