import React, { useState, useEffect, useContext, useRef } from 'react';
import QuoteBuilderNavSection from 'components/quote_builder/QuoteBuilderNavSection.jsx';
import QuoteBuilderForm from 'components/quote_builder/QuoteBuilderForm.jsx';
import QuoteBuilderManagePanel from 'components/quote_builder/QuoteBuilderManagePanel.jsx';

// has_many QuoteBuilderNavSections
const QuoteBuilderNavSectionList = ({ config, payload, presentedOptions, navSections }) => {

return (
  <div className="container">
    <div className="row">
      <div className="float-right">

        <div style={{ 'marginRight': '50px'}}>
          <a href="/quote-builder/settings" className="btn btn-default btn-sm no-border" target="_blank">
            [ <i className="fa fa-cog" aria-hidden="true"></i> {t('nav.quote_builder_settings_long')} ]
          </a>
        </div>

      </div>
    </div>

    <div className="row" style={{ 'marginLeft': '-50px'}}>
      <div className="col-lg-7">
        <QuoteBuilderForm config={config.customer} />
      </div>

      <div className="col-lg-5">
        <div style={{ 'marginRight': '50px'}}>
          <QuoteBuilderForm config={config.general} />
          <QuoteBuilderManagePanel />
        </div>
      </div>
    </div>

    {navSections.map((navSection, index) => {
      return (
        <div className="row" key={`row_${index}`}>
          <QuoteBuilderNavSection
            config={config}
            payload={payload}
            presentedOptions={presentedOptions}
            key={`navSection_${index}`}
            navSection={navSection} />
         </div>
      )
    })}

</div>
);
}

export default QuoteBuilderNavSectionList;
