import React, { useState, useEffect, useRef}  from 'react';
import { PostRequest } from 'components/common/index.js';
import fileDownload from 'js-file-download'
import Constants from 'components/show_drawing/Constants.jsx';

const DownloadConfirmation = ( { id, drawing, currentDrawingOptions, currentUserDrawingOptions, advancedOptionsVisible, fileFormat, dimensionTextschecked} ) => {
  const [canDownload, setCanDownload] = useState(true)
  const [presentedOptions, setPresentedOptions] = useState( {} )
  const jsonRef = useRef();
  const notesTextRef = useRef();
  const [fileName, setFileName] = useState('')
  const [defaultFileName, setDefaultFileName] = useState('')
  const [fileNameWithDimensions, setFileNameWithDimensions] = useState('')
  const [fileNameWithDimensionsChecked, setFileNameWithDimensionsChecked] = useState(false)
  const [notesVisible, setNotesVisible] = useState(false)
  const [charRemaining, setCharRemaining] = useState(255)
  const [cadetClassName, setCadetClassName] = useState("fa fa-caret-down")
  const [downloadWillCostCredit, setDownloadWillCostCredit] = useState(false)
  const [creditCount, setCreditCount] = useState(0)
  const [couponCode, setCouponCode] = useState("")
  const [downloadWillCostCoupon, setDownloadwillCostCouponCode] = useState(false)
  const [alreadyDownloaded, setAlreadyDownloaded] = useState(false)
  const [activeSubscription, setActiveSubscription] = useState(false)
  const [downloadNotes, setDownloadNotes] = useState("")

  const getPresentedOptions = () => {
    // /Users/emre/code/boxes/app/controllers/concerns/show_drawing_react_actions.rb
    PostRequest({
      url: "/" + id  +  `/params_to_confirm?advanced_options_visible=${advancedOptionsVisible}`,
      data: currentUserDrawingOptions,
      callback: (data) => {
        setPresentedOptions( () => data.options_to_confirm )
        setCanDownload( () => data.can_download )
        setFileName( () => data.default_file_name )
        setDefaultFileName( () => data.default_file_name )
        setFileNameWithDimensions( () => data.file_name_with_dimensions )
        setDownloadWillCostCredit( () => data.download_will_cost_credit )
        setCreditCount( () => data.available_credits )
        setDownloadwillCostCouponCode( () => data.download_will_cost_coupon_code )
        setCouponCode( () => data.coupon_code_value )
        setAlreadyDownloaded( () => data.already_downloaded )
        setActiveSubscription( () => data.active_subscription )
      }
    })
  }

  const handleFileDimensionsChecked = (e) => {
    setFileNameWithDimensionsChecked( (prev) => !prev )
  }

  const handleAddNotesClick = (e) => {
    e.preventDefault()
    setNotesVisible( (prev) => !prev )
  }

  const handleNotesChange = (e) => {
    var tval = e.target.value,
    tlength = tval.length,
    set = 254,
    remain = parseInt(set - tlength);
    setCharRemaining( () => remain )

    if (remain <= 0 && e.which !== 0 && e.charCode !== 0) {
      e.target.value = (tval).substring(0, tlength - 1);
      return false;
    }

    setDownloadNotes( () => e.target.value )
  }

  const fileNameOnChange = (e) => {
    setFileName( () => e.target.value )
  }
  const fileNameWithExtension = () => {
    return `${fileName}.${fileFormat}`
  }

  const performDownload = (e) => {
    e.preventDefault();

    emitEvent(Constants.drawing_download_started, {id: id})

    const url = `/download/${id}/${fileFormat}`
    const dataToPost = currentUserDrawingOptions;
    dataToPost.authenticity_token = $('meta[name=csrf-token]').attr('content');
    dataToPost.dimension_texts_included = dimensionTextschecked
    dataToPost.download_notes = downloadNotes

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dataToPost)
    };

    fetch(url, options)
      .then( res => res.blob() )
      .then( blob => {
        emitEvent(Constants.drawing_download_finished, {id: id})
        fileDownload(blob, fileNameWithExtension())
        refreshDownloadCounts();
      });
  }

  const downloadForm = () => {
    return <div>
      <div className="well" style={{padding: '10px'}}>
        <input type="checkbox" checked={fileNameWithDimensionsChecked} onChange={handleFileDimensionsChecked} />
        <small> { t('download_confirmation.file_name_include_dimensions') } </small>

        <br />

        <div className="input-group">
          <span className="input-group-addon"> { t('download_confirmation.file_name') } </span>
          <input type="text" size="100" className="form-control" name="file_name" id="file_name" value={fileName} onChange={fileNameOnChange}/>
          <span className="input-group-addon"><span id="file_extension"> .{fileFormat} </span> </span>
        </div>

        <a href="#" onClick={handleAddNotesClick}>
          <small>
            { t('download_confirmation.add_notes')} <i className={cadetClassName} aria-hidden="true"></i>
          </small>
        </a>

       {notesVisible && (
        <span>
          <textarea rows="2" className="form-control" onKeyPress={handleNotesChange} ref={notesTextRef} > </textarea>
          <small><p> {charRemaining} </p></small>
        </span> )}

        {downloadWillCostCredit && <p className="well"
          dangerouslySetInnerHTML={{__html: t('download_confirmation.credit_information').replace('%{credit_count}', creditCount) }}></p>
        }

        {downloadWillCostCoupon && <p className="well"
          dangerouslySetInnerHTML={{__html: t('download_confirmation.coupon_information').replace('%{coupon_value}', couponCode) }}></p>
        }

        {alreadyDownloaded && activeSubscription && <p className="well"
          dangerouslySetInnerHTML={{__html: t('download_confirmation.already_downloaded_without_credit') }}></p>
        }

        {alreadyDownloaded && !activeSubscription && <p className="well"
          dangerouslySetInnerHTML={{__html: t('download_confirmation.already_downloaded_with_credit') }}></p>
        }

      </div>

      <div style={{marginTop: '15px'}}>
        <center>
          <button className="simple-btn button btn btn-default" onClick={performDownload}> { t('download_confirmation.confirm') } </button>
        </center>
      </div>

    </div>
  }

  const cannotDownloadError = () => {
    trackEvent('js_error_download_confirm_but_cannot_download', {
      id: id,
      options: currentDrawingOptions,
    })

    return <div>
      <p> { t('drawing.unexpected_error_happened')  } </p>
    </div>
  }

  // useEffect
  useEffect( () => {
    $(jsonRef.current).jsonview( JSON.stringify(presentedOptions) )
  } , [ presentedOptions ])

  useEffect(getPresentedOptions, [ currentDrawingOptions ]);
  useEffect( () => {
    setFileName( () => fileNameWithDimensionsChecked ? fileNameWithDimensions : defaultFileName )
  }, [ fileNameWithDimensionsChecked ]);
  useEffect( () => {
    setCadetClassName( () => notesVisible ?  'fa fa-caret-up' : 'fa fa-caret-down' )
    $(notesTextRef.current).focus();
  }, [ notesVisible ] )
  // useEffect
  return (
    <div>
      <div className="promo-text">
        { t('download_confirmation.title').replace('%{name}', drawing.catalog_name_as_title) }
      </div>
      <hr />

     <div className="">
      <p>
        { t('download_confirmation.information').replace('%{name}', drawing.catalog_name_as_title) }
      </p>
      </div>

    <div className="">
      <div width="100%" ref={jsonRef}></div>
      <div style={{marginTop: '15px'}}>
      { canDownload && downloadForm() }
      { !canDownload && cannotDownloadError() }
      </div>
    </div>

  </div>
  )
}

export default DownloadConfirmation;

