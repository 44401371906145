import React, { useState, useEffect, useRef } from 'react';
import { GetRequest, Tooltip } from 'components/common';
import { ContentPopup } from 'components/ContentPopup.jsx';
import Util from 'components/quote_builder/Util.jsx';

const ShowFinalizedQuote = ( {} ) => {
  const sliceNumber = 2;
  const [sections, setSections] = useState([]);

  const [number, setNumber] = useState("");
  const [count, setCount] = useState("");
  const [customer_name, set_customer_name] = useState("");
  const [customer_email, set_customer_email] = useState("");
  const [customer_phone, set_customer_phone] = useState("");
  const [aggregated_totals, set_aggregated_totals] = useState({});
  const [layout_part_group_windows, set_layout_part_group_windows] = useState({});
  const [notes, set_notes] = useState("");
  const [date, setDate] = useState("");
  const [currency, setCurrency] = useState("");
  const [totalPresented, setTotalPresented] = useState("");
  const [priceForOneFormatted, setPriceForOneFormatted] = useState("");

  useEffect(() => {
    GetRequest({
      url: `/quote-builder/my-quotes/${window.user_quote_id}/finalized-sections`,
      callback: (data) => {
        setSections(data.user_quote_nav_sections);
        setNumber(data.number);
        setCount(data.count);
        setDate(data.date);
        set_customer_name(data.customer_name);
        set_customer_email(data.customer_email);
        set_customer_phone(data.customer_phone);
        set_aggregated_totals(data.aggregated_totals);
        set_layout_part_group_windows(data.layout_part_group_windows);
        set_notes(data.notes);
        setCurrency(data.currency);
        setTotalPresented(data.total_presented);
        setPriceForOneFormatted(data.price_for_one_formatted);
      }
    })
  }, [])

  const columns_one = (columns) => {
     return (
      <div className="row">
        <div className="col-lg-4"> </div>
        <div className="col-lg-8">
          {getColumnContent(columns[0])}
        </div>
      </div>
    );
  }

  const columns_more = (columns) => {
    return columns.each_slice(sliceNumber).map((slice, sliceIndex) => {
      return (
        <div className="row">
          {slice.map((column) => {
            return <div className="col-lg-6"> {getColumnContent(column)} </div>
          })}
        </div>
      )
    });
  }

  const dangerouslySetInnerHTML = (text) => {
    return <span dangerouslySetInnerHTML={{__html: text}}></span>
  }

  const sectionTable = (sectionData) => {
    return(
      <table className="table table-striped table-bordered">
        <tbody>
          <tr>
            <th colSpan="2"> {sectionData.properties_presented.title} </th>
          </tr>
            {sectionData.properties_presented.section_attributes.map((section_attribute) => {
              return (
                <tr>
                  <td> {section_attribute.name} </td>
                  <td className="align-right">
                    {dangerouslySetInnerHTML(section_attribute.description)} {dangerouslySetInnerHTML(section_attribute?.summary)}
                  </td>
                </tr>
              );
            })}
          <tr>
            <td> &nbsp; </td>
            <td>
              {lineItemPrices(sectionData.results_presented)}
            </td>
          </tr>
        </tbody>
      </table>
      );
  }

  const InfoTables = () => {
    return (
      <div className="container">
        <div className="col-lg-7" >
          <div className="quote_builder_general_form_container" style={{'marginLeft': '15px'}}>
            <br />
            {QuoteInfoCustomer()}
          </div>
        </div>
        <div className="col-lg-5">
          <div className="quote_builder_general_form_container">
           <a href="/quote-builder/settings" className="btn btn-default btn-sm no-border" target="_blank">
            <i className="fa fa-cog" aria-hidden="true"></i> {t('nav.quote_builder_settings_long')}
           </a>
          {generalQuoteInfo()}
          </div>
        </div>
      </div>
    );
  }

  const dataRows = (data) => {
    let res = [];

    for (let [key, value] of Object.entries(data)) {
      res.push(<tr>
        <td> {key} </td>
        <td  align="right" > {value} </td>
      </tr>);
    }

    return res;
  }

  const aggregatedTotalRows = () => {
    let res = [];

    res.push(<tr key='empty1'>
      <th colSpan="2" className="bgWhite"> &nbsp; </th>
    </tr>);


    for (let [title, values] of Object.entries(aggregated_totals)) {

      res.push(
        <tr key={`${title}`}>
          <th colSpan="2" className="aggregatedTotalRowTitle"> {title}
          <span className="float-right"> {values._total} </span>
          </th>
        </tr>);

      for (let [key, value] of Object.entries(values)) {
        if(key != '_total') {
          res.push(
            <tr key={`${key}`}>
              <td>  &nbsp; &nbsp; {key} </td>
              <td  align="right" > {value} </td>
            </tr>);
        }
      }
    }

      res.push(<tr key='empty2'>
        <th colSpan="2" className="bgWhite"> &nbsp; </th>
      </tr>);

    return res;
  }

  const generalQuoteInfo = () => {
    return (
      <table className="table table-striped">
        <tbody>

        <tr>
          <td> {t('quote_builder.quote_number')}   </td>
          <td align="right"> {number} <br />
            <small>
              <a href="?download=pdf" target='_blank'> <i className="fa fa-file-pdf-o" aria-hidden="true"></i> PDF </a>
            </small>
          </td>
        </tr>

        <tr>
          <td> {t('quote_builder.date')}   </td>
          <td align="right"> {date}  </td>
        </tr>

        <tr>
          <td> {t('quote_builder.count')} </td>
          <td align="right"> {count}  </td>
        </tr>

        {dataRows(layout_part_group_windows)}

        <tr>
          <td>  {t('quote_builder.currency')}  </td>
          <td align="right"> {currency}  </td>
        </tr>

        {aggregatedTotalRows()}

        <tr>
          <td> {t('quote_builder.price_for_one')} </td>
          <td align="right"> {priceForOneFormatted }  </td>
        </tr>

        <tr>
          <th> {t('quote_builder.total')}  </th>
          <td align="right"> <strong> {totalPresented} </strong> </td>
        </tr>

        </tbody>
      </table>
    )
  };


  const QuoteInfoCustomer = () => {
    return (
      <table className="table table-striped">
        <tbody>

        <tr>
          <td> {t('quote_builder.customer_name')}   </td>
          <td align="right"> {customer_name}  </td>
        </tr>

        <tr>
          <td> {t('quote_builder.customer_email')}   </td>
          <td align="right"> {customer_email}  </td>
        </tr>

        <tr>
          <td> {t('quote_builder.customer_phone')} </td>
          <td align="right"> {customer_phone}  </td>
        </tr>

        <tr>
          <td>  {t('quote_builder.notes')}  </td>
          <td align="right"> {notes}  </td>
        </tr>

        </tbody>
      </table>
    )
  };

  const lengthsByColorCodeFormattedTrRows = (data) => {
    return Util.lengthsByColorCodeFormattedTrRows(data, 3)
  }

  const getColumnContent = (ColumnData) => {
    let options_layout_dimension_width = t('layouts.options_layout_dimension_width');
    let options_layout_dimension_height = t('layouts.options_layout_dimension_height');
    let output_x_length_key = t('layouts.output_x_length');
    let output_y_length_key = t('layouts.output_y_length');
    let output_dimensions = ColumnData.presented_output_dimensions;

    return (
    <div className="layout-output">
      {output_dimensions != undefined && (<>
        <div className={`quote-builder-img-${window.redraw_theme}`}>
          {window.redraw_theme == 'black_bg' && <img src={ColumnData.image_black_bg.url} /> }
          {window.redraw_theme == 'white_bg' && <img src={ColumnData.image_white_bg.url} /> }
          {window.redraw_theme == 'gray_bg'  && <img src={ColumnData.image_gray_bg.url} /> }
        </div>

        <div className="float-right" style={{ 'marginBottom': "30px"}}>
          <table className="quote-layout-info-table">
            <tbody>
              <tr>
                <td align="left">
                  <span className="arrow">
                    <i className="fa fa-arrows-h" aria-hidden="true"></i>
                  </span>
                  <span className="info-quote"> {t('layouts.output_x_length')} {output_dimensions[output_x_length_key]} </span>
                </td>

                <td align="left">
                  <span className="arrow">
                    <i className="fa fa-arrows-v" aria-hidden="true"></i>
                  </span>
                  <span className="info-quote">{t('layouts.output_y_length')} {output_dimensions[output_y_length_key]} </span>
                </td>

                <td align="right">
                  {output_dimensions['num_total'] == undefined || output_dimensions['num_total'] == (output_dimensions['num_x_and_y'][0] * output_dimensions['num_x_and_y'][1]) && (
                    <> ({output_dimensions['num_x_and_y'][0]} x {output_dimensions['num_x_and_y'][1]})</>
                  )}

                  {output_dimensions['num_total'] != undefined && output_dimensions['num_total'] != (output_dimensions['num_x_and_y'][0] * output_dimensions['num_x_and_y'][1]) && (
                    <> ({output_dimensions['num_total'][0]})</>
                  )}
                </td>
              </tr>

              <tr>
                <td align="left">
                  <span className="arrow">
                    <i className="fa fa-arrows-h" aria-hidden="true"></i>
                  </span>
                  <span className="info-quote"> {t('layouts.options_layout_dimension_width')} {ColumnData.presented_layout_options[options_layout_dimension_width]} </span>
                </td>

                <td align="left">
                  <span className="arrow">
                    <i className="fa fa-arrows-v" aria-hidden="true"></i>
                  </span>
                  <span className="info-quote">{t('layouts.options_layout_dimension_height')}  {ColumnData.presented_layout_options[options_layout_dimension_height]} </span>
                </td>
                <td align="right">
                  <ShowColumnDimensions ColumnData={ColumnData} />
                 </td>
              </tr>

              {Object.keys(ColumnData.presented_output_dimensions['lengths_by_color_code']).length > 0 && (lengthsByColorCodeFormattedTrRows(ColumnData.presented_output_dimensions['lengths_by_color_code'])
                  )}

              {ColumnData.only_for_plotter && (
                <tr key="onlyForPlotter_warning">
                  <td colSpan="3">
                   <div className="alert alert-info fade in">
                    <i className="fa fa-info-circle"></i>&nbsp; <strong> {t('layouts.nesting_is_only_for_plotter')} </strong>
                  </div>
                  </td>
                </tr>
                )}

            </tbody>
          </table>
        </div>
      </>)}
      {ColumnData.sections.map(sectionTable)}
    </div>
  );
  };

  const lineItemPrices = (results_presented) => {
    return (
     <table className="float-right full_width">
        <tbody>

          {results_presented.number_of_sheets != '' && (
            <tr>
              <td>
                {t('quote_builder.number_of_sheets')}
                {results_presented.num_of_drawings_in_set > 1 && (
                  <Tooltip title={t('quote_builder.num_of_drawings_in_set_description').replace('%s', results_presented.num_of_drawings_in_set)} />)}
              </td>
              <td className="align-right"> {results_presented.number_of_sheets}</td>
            </tr>
            ) }

          {results_presented.result_info_rows.map( (resultInfoRow) => {
            return (<tr key={resultInfoRow.key}>
              <td>
                {t(resultInfoRow.key)}
                {resultInfoRow.tooltip_text != undefined && (
                  <Tooltip title={resultInfoRow.tooltip_text} />)}
              </td>
              <td className="align-right">
                {resultInfoRow.value != undefined && resultInfoRow.value}
              </td>
            </tr>)
          } )}

          {results_presented.line_item_prices.map( (line_item_price) => {
            return (
               <tr>
                 <td>
                  {line_item_price.title} {line_item_price.notes != '' && <Tooltip title={line_item_price.notes} />}
                 </td>

                  <td className="align-right">
                    {line_item_price.price_formatted}

                    {line_item_price.price_in_pence > 0 && line_item_price.exchange_info_presented != '' && (
                      <Tooltip title={line_item_price.exchange_info_presented} />
                    )}
                    </td>
                </tr>
              );
          })}

        {results_presented.price_for_one_formatted != undefined && (
          <tr className="tr-bordered-top">
            <td> {t('quote_builder.price_for_one')} </td>
            <td className="align-right">
              {results_presented.price_for_one_formatted}
            </td>
          </tr>)}

          <tr className={`${results_presented.price_for_one_formatted == undefined ? 'tr-bordered-top' : ''}`}>
            <th> {t('quote_builder.total')} </th>
            <th className="align-right">
              {results_presented.total_price_formatted}
            </th>
          </tr>

        </tbody>
      </table>
    );
  }

  const navSectionForm = (sectionData) => {
    return (<table className="table table-striped">
      <tbody>
        {sectionData.wastage_count > 0 &&              (<tr key='wastage_count'>  <td> {t('quote_builder.wastage_count')} </td> <td className="align-right">  {sectionData.wastage_count} {t(`quote_builder.wastage_unit_${sectionData.wastage_unit}`)}  </td> </tr> )}
        {sectionData.lamination != 'none' &&           (<tr key='lamination'>     <td> {t('quote_builder.lamination')}    </td> <td className="align-right">  {t(`quote_builder.${sectionData.lamination}`)} </td> </tr> )}
        {sectionData.printing_side != 'none'  &&       (<tr key='printing_side'>  <td> {t('quote_builder.printing_side')} </td> <td className="align-right">   {t(`quote_builder.${sectionData.printing_side}`)} </td> </tr> )}
        {sectionData.printing_block != 'one_block'  && (<tr key='printing_block'> <td> {t('quote_builder.printing_block')} </td> <td className="align-right">   {t(`quote_builder.${sectionData.printing_block}`)} </td> </tr> )}
      </tbody>
    </table>);
  }

  const getSection = (sectionData) => {
    if(sectionData.columns.length == 0) { return }

    return (<>
      <div className="container">
        {sectionData.label != '' && (
            <div className="row">
              <div className="col-lg-12">
                <div className="promo-block">
                  <div className="promo-text"> {sectionData.label} </div>
                  <div className="center-line"> </div>
                  <br />
                </div>
              </div>
            </div>
        )}
      {navSectionForm(sectionData)}
      {sectionData.columns.length == 1 && (columns_one(sectionData.columns))}
      {sectionData.columns.length > 1 && (columns_more(sectionData.columns))}
      </div>
    </>);
  }

  return (
    <div className="ShowFinalizedQuoteCont">

      {InfoTables()}

      {sections.length > 0 && sections.map(getSection)}
    </div>
  )
};

const ShowColumnDimensions = ( { ColumnData } ) => {
  const keyValuePairRow = (keyValuePair) => {
    return <tr> <th> {keyValuePair[0]} </th> <td>  {keyValuePair[1]} </td> </tr>;
  }
  const content = <div>
    <table className="table table-striped">
      <tbody>
        <tr>
          <th colSpan="2"> <h3> Dieline </h3> </th>
        </tr>
         { Object.entries(ColumnData.presented_dieline_options).map(keyValuePairRow) }
      </tbody>
    </table>
    <table className="table table-striped">
      <tbody>
        <tr>
          <th colSpan="2">  <h3> Layout </h3> </th>
        </tr>
          { Object.entries(ColumnData.presented_layout_options).map(keyValuePairRow) }
      </tbody>
    </table>
  </div>
  const showDimensionsContentPopupRef = useRef();
  const show = () => { showDimensionsContentPopupRef.current.style.display = "block";}

  const showDimensionsContentPopup = () => {
    let callback = () => {
      showDimensionsContentPopupRef.current.style.display = "none";
      setShowDimension( (_prev) => { return false });
    };

    let response = <ContentPopup myRef={showDimensionsContentPopupRef} autoOpen={false} content={content} cancelButton={false} beforeClose={callback} />;

    return response;
  };

  return (
    <div>
      <span className="info-quote">
        <a onClick={(e) => {
          e.preventDefault();
          show( );
        }} className="btn btn-default btn-sm no-border">
        <i className="icon-tools"> </i> &nbsp; {t('layouts.show_dimensions') }</a>
      </span>
      {showDimensionsContentPopup()}
    </div>
  )
}

export default ShowFinalizedQuote;
