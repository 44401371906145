import React, { useState, useEffect }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';

const DrawingFooter = ( { id, drawing, theme, setTheme, unit } ) => {
  const [userLoggedIn, setUserLoggedIn] = useState(window.loggedIn)

  const showNotes = (e) => {
    e.preventDefault();
    showDrawingNotesPopup(e)
    getDrawingNote();
  }

  useEffect(() => { setUserLoggedIn( window.loggedIn) } , [id, drawing, theme, unit]);

  return (
    <div>
       <div className={styles.floatLeft}>

        {drawing.ecma_code != undefined && drawing.ecma_code != '' && (<>
          <span className="ecma">ECMA </span >{drawing.ecma_code}
          {drawing.old_ecma_code != undefined && drawing.old_ecma_code != '' && (<>
            &nbsp; ({drawing.old_ecma_code})
          </>)}
        </>
        ) }

        {drawing.fefco_code != undefined && drawing.fefco_code != '' && (<>
            <span className="ecma">FEFCO </span >{drawing.fefco_code}
          </>
        ) }

      </div>

      <div className={styles.floatRight}>
       {userLoggedIn && <> <a href="#" data-id={id} className="a-notes-icon" onClick={showNotes}>
        <i id="notes-icon" className="zmdi" title={t('notes.title')} ></i>  <small> { t('nav.my_drawing_notes') } </small>
        </a> &nbsp; </> }

        <a href="/blog/line-colors-in-dielines-explained" target="_blank">
          <small> <i className="fa fa-question-circle" aria-hidden="true"></i> { t('drawing.line_colors_explained') } </small>
        </a>

        &nbsp;

        <select value={theme} onChange={ (e) => setTheme( () => e.target.value ) }>
          <option value="black_bg"> { t('drawing.black_background') } </option>
          <option value="white_bg"> { t('drawing.white_background') } </option>
          <option value="gray_bg">  { t('drawing.gray_background') }</option>
        </select>
      </div>
    </div>
  )
}

export default DrawingFooter;
