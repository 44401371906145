import React, { useState, useEffect, useRef }  from 'react';
import { MyDownloadsButton } from 'components/show_drawing/download_buttons/index.js';
import { ContentPopup } from 'components/ContentPopup.jsx';
import { PricingTables, PaymentForm, ChangeCurrency, PaymentSuccess } from 'components/payment/index.js';

const LoggedInPurchaseRequired = ({}) => {
  const handleClick = (e) => {
    e.preventDefault()
    setShowPopUp(() => true);
  }

  const [showPopUp, setShowPopUp] = useState(false);
  const popupRef = useRef();

  const [mode, setMode] = useState('pricing_tables');

  const handleBackClick = (e) => {
    e.preventDefault();
    setMode('pricing_tables');
  }

  const [subscription, setSubscription] = useState({})
  const [currencyName, setCurrencyName] = useState('')

  const backButton = () => {
    return (
      <span style={{'display':'inline', 'float':'right'}}>
        &nbsp;
        &nbsp;
        &nbsp;
        <button className="btn simple-btn"  onClick={handleBackClick}>
          <i className="zmdi zmdi-replay"></i> {t('nav.back')}
        </button>
      </span>
    );
  }

  const popupContent = () => {
    trackEvent('js_purchase_required_popup', {
      component: 'show_drawing/LoggedInPurchaseRequired',
      id: window.drawing_id,
      catalog_name: window.drawing_catalog_name,
    });

    let callback = () => {
      popupRef.current.style.display = "none";
      setShowPopUp(() => false);
    };

    let content = (
      <div className="testimonials-block preview-promo-block">
        <div className="testimonials-desc">
          {mode == 'payment_form' && backButton() }
          {mode == 'pricing_tables' && <br />}
          <ChangeCurrency currencyName={currencyName} mode={mode} />
          {mode == 'payment_success' && <PaymentSuccess callback={refreshDownloadButtons} />}
          {mode == 'pricing_tables' && <PricingTables setMode={setMode} setSubscription={setSubscription} setCurrencyName={setCurrencyName} /> }
          {mode == 'payment_form'   && <PaymentForm setMode={setMode} subscription={subscription} setSubscription={setSubscription} setCurrencyName={setCurrencyName}/> }
        </div>
      </div>
    )

    return <ContentPopup
      contextClass="wrapper"
      myRef={popupRef}
      autoOpen={true}
      content={content}
      cancelButton={false}
      beforeClose={callback}
      notWide={true}
    />;
  }
  return (
    <div className="downloadButtonscontainer">

      <div className="inner">
        <div className="child">
          <button className={`btn btn-default btn-lg download-btn buy-now-btn-with-icons-${window.I18nLocale}`} onClick={handleClick}>
            <img className="downloadIcons" src={window.dxf_svg_and_pdf_icon} /> {t('payment.buy_now')} &nbsp; &nbsp;
          </button>
        </div>
      </div>

      <MyDownloadsButton />

      {showPopUp &&  popupContent()}
    </div>
  )
}

export default LoggedInPurchaseRequired;
