import React, { useEffect, useState } from 'react';

import { CurrencyInput } from 'components/common';

const CurrencyFormField = ({ config, value, setValue, disabled, setFetchInProgress }) => {
  const [recentCurrencies, setRecentCurrencies] = useState([]);
  const [allCurrencies, setAllCurrencies] = useState([]);

  const onChange = (e) => {
    setValue(config.name, e.target.value);
    if(config.publish_on_change_key != undefined) {
      var event = new CustomEvent(config.publish_on_change_key, { "detail": e.target.value });
      document.dispatchEvent(event);
    }
  }

  useEffect( () => {
    if(disabled) { return };
    setFetchInProgress(true);

    updateRequestCount(1);
    fetch('/quote-builder/currencies', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => response.json())
    .then(data => {
      updateRequestCount(-1);
      setRecentCurrencies(data.recent);
      setAllCurrencies(data.all);
      setFetchInProgress(false);
    })
  }, []);

  const component = <CurrencyInput
    labelRecent=""
    recent={recentCurrencies}
    all={allCurrencies}
    name={config.name}
    value={value}
    class_name={config.class_name}
    onChange={onChange}
  />;

  return (
      <tr>
        <td> {t(config.label)} </td>
        <td align="right"> {component} </td>
      </tr>
    )
}

export default CurrencyFormField;
