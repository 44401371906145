import React, { useState, useRef, useContext, useEffect }  from 'react';

const ChangeCurrency = ({ currencyName, mode }) => {
  // const currentHeight = useRef(0);

  const onMouseEnter = () => {
    if(mode == 'payment_form') { return }
    let heightOfHeader = { pricing_tables: 110, payment_form: 220 }[mode]
    if(parseInt(window.promoCurrentHeight) == 0) { return }

    let dropdownHeight = parseInt(window.promoCurrentHeight) + heightOfHeader;
    $("#preview_promo_modal_content_container").animate({height: dropdownHeight + 'px'});
  }

  const onMouseLeave = () => {
    if(mode == 'payment_form') { return }
    if(parseInt(window.promoCurrentHeight) == 0) { return }

    $("#preview_promo_modal_content_container").animate({height: window.promoCurrentHeight});
  }

  const [currency, setCurrency] = useState('');

  const handleClick = (e) => {
    e.preventDefault();
    setUserParam('currency', $(e.target).data('code'), (data) => {
      document.dispatchEvent(new CustomEvent('show_drawing.currency_changed', {}) );
      $('#currency-dropdown-content').addClass('hidden')
      setTimeout(() => {
        $('#currency-dropdown-content').removeClass('hidden')
      }, 100) ;
    } )
  }

  return (<>
    <div className="currency-dropdown" style={{"float":"right"}}>
      <button className="currency-dropbtn">
        {currencyName} &nbsp;
        <i className="fa fa-caret-down" aria-hidden="true"></i>
      </button>

      <div className="currency-dropdown-content" id="currency-dropdown-content" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="USD"> <span className="flag-icon flag-icon-us" data-code="USD"> </span> <i className="fa fa-dollar" data-code="USD"> </i>
          <small data-code="USD"> USD </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="EUR"> <span data-code="EUR" className="flag-icon flag-icon-eu"> </span> <i data-code="EUR" className="fa fa-euro">   </i>
          <small  data-code="EUR"> EUR </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="GBP"> <span data-code="GBP" className="flag-icon flag-icon-gb"> </span> <i data-code="GBP" className="fa fa-gbp">    </i>
          <small data-code="GBP"> GBP </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="AUD"> <span data-code="AUD" className="flag-icon flag-icon-au"> </span> <i data-code="AUD" className="fa fa-dollar"> </i>
          <small data-code="AUD"> AUD </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="NZD"> <span data-code="NZD" className="flag-icon flag-icon-nz"> </span> <i data-code="NZD" className="fa fa-dollar"> </i>
          <small data-code="NZD"> NZD </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="CAD"> <span data-code="CAD" className="flag-icon flag-icon-ca"> </span> <i  data-code="CAD" className="fa fa-dollar"> </i>
          <small data-code="CAD"> CAD </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="TRY"> <span data-code="TRY" className="flag-icon flag-icon-tr"> </span> <i data-code="TRY" className="fa fa-try">    </i>
          <small data-code="TRY"> TRY </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="INR"> <span data-code="INR" className="flag-icon flag-icon-in"> </span> <i data-code="INR" className="fa fa-inr"> </i>
          <small data-code="INR"> INR </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="ZAR"> <span data-code="ZAR" className="flag-icon flag-icon-za"> </span> R
          <small data-code="ZAR"> ZAR </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="THB"> <span data-code="THB" className="flag-icon flag-icon-th"> </span> ฿
          <small data-code="THB"> THB </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="MYR"> <span data-code="MYR" className="flag-icon flag-icon-my"> </span>
          <small data-code="MYR"> MYR </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="SAR"> <span data-code="SAR" className="flag-icon flag-icon-sa"> </span> <i data-code="SAR" className="fa fa-sar"> </i>
          <small data-code="SAR"> SAR </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="MXN"> <span data-code="MXN" className="flag-icon flag-icon-mx"> </span> <i data-code="MXN" className="fa fa-mxn"> </i>
          <small data-code="MXN"> MXN </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="ILS"> <span data-code="ILS" className="flag-icon flag-icon-il"> </span> <i data-code="ILS" className="fa fa-ils"> </i>
          <small data-code="ILS"> ILS </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="EGP"> <span data-code="EGP" className="flag-icon flag-icon-eg"> </span> <i data-code="EGP" className="fa fa-egp"> </i>
          <small data-code="EGP"> EGP </small> </a>
        <a href="#" className="change_currency_btn" onClick={handleClick} data-code="AED"> <span data-code="AED" className="flag-icon flag-icon-ae"> </span> <i data-code="AED" className="fa fa-aed"> </i>
          <small data-code="AED"> AED </small> </a>
      </div>
    </div>
  </>)
}

export default ChangeCurrency;
