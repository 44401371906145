 import React, { useEffect } from 'react';

const Recurring = ({ subscription, selectedSubscription, handleSubscriptionRowClick, handleSubscriptionRadioSelect}) => {
  if(subscription == undefined) { return }

  const price_formatted = () => {
    if(subscription.has_discount) { return subscription.type == 'monthly' ? price_formatted_monthly_discounted() : price_formatted_annually_discounted() }
    return subscription.type == 'monthly' ? price_formatted_monthly() : price_formatted_annually()
  }

  const price_formatted_monthly = () => {
    return price_formatted_div(t('sign_up.per_month'))
  }

  const price_formatted_annually = () => {
    return price_formatted_div(t('sign_up.per_year'))
  }

  const price_formatted_div = (per) => {
    return (
      <div className="float-right" data-value={subscription.type}>
        <div className="float-right largeText" data-value={subscription.type}>
          {subscription.price_formatted}
        </div>

        <br />

        <div className="float-right" style={{"color":"#F44336"}} data-value={subscription.type}>
         <small data-value={subscription.type}> &nbsp; {per} </small>
        </div>
      </div>)
  }

  const price_formatted_monthly_discounted = () => {
    return price_formatted_div_discounted(t('sign_up.per_month'))
  }

  const price_formatted_annually_discounted = () => {
    return price_formatted_div_discounted(t('sign_up.per_year'))
  }

  const price_formatted_div_discounted = (per) => {
    return (
      <div className="float-right" data-value={subscription.type}>
        <div className="float-right clear largeText" data-value={subscription.type}>
          <strike data-value={subscription.type}>
            {subscription.price_without_discount_formatted}
          </strike>

           &nbsp;

          <strong data-value={subscription.type}> {subscription.price_formatted} </strong>
        </div>
        <div className="float-right clear-right" style={{"color":"#F44336"}}>
         <small data-value={subscription.type}> &nbsp; {per} </small>
        </div>
      </div>
    )
  }

  useEffect(() => {
    $('.offer_ends').each(offerExpiresAt);
  }, [])

  return (
    <tr className="subscription_type_row" onClick={handleSubscriptionRowClick} data-value={subscription.type} key={subscription.type}>
      <td data-value={subscription.type}>
        <div className="float-left" data-value={subscription.type}>
          <input type="radio"
            name="subscription_type"
            value={subscription.type}
            checked={selectedSubscription === subscription.type}
            onChange={handleSubscriptionRadioSelect}
            className="subscription_option"
          />

          &nbsp; {t(`sign_up.${subscription.type}`)}
          &nbsp; ({t('sign_up.unlimited_access')} + {t('sign_up.manufacturing_cost_calculator')}  <sup>**</sup> )

          {subscription.has_discount && <div className="marg5" data-value={subscription.type}>
            <span class="badge badge-dark" data-value={subscription.type}> {t('sign_up.special_offer')}
            {subscription.coupon_code_expires_at != undefined && (
              <>
                &nbsp; - <span data-expires-at={subscription.coupon_code_expires_at} class="offer_ends"> </span>
              </>
            )}
            </span>
          </div>}

        </div>

        {price_formatted(subscription)}

        <br />

      </td>
    </tr>
    )
}

export default Recurring;
