import React, { useState, useEffect }  from 'react';
import Constants from 'components/show_drawing/Constants.jsx';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import MyEventListener from 'components/common/MyEventListener.jsx'

const DimensionTextsIncludedChooser = ( { id, drawing, unit, dimensionTextschecked, setDimensionTextschecked} ) => {
  const onDimensionTextsChange = (e) => {
    setDimensionTextschecked( () => e.target.checked )
  }

  return (
    <div className={styles.row}>
      <div className="input-group short-text">
        <span className={`${styles.clickable} input-group-addon input-group-addon-${window.locale} ${styles.input_add_on_for_boolean}`} onClick={ () => setDimensionTextschecked( (prev) => !prev) }>
          { t('models.dimension_texts') }
          <div className={styles.div_boolean}>
            <input
              name='dimension_texts_included'
              type="checkbox"
              value="1"
              onChange={onDimensionTextsChange}
              className="drawing-option-input"
              checked={dimensionTextschecked}
              autoComplete="off"
            />
          </div>
        </span>
      </div>
    </div>
    )
}

export default DimensionTextsIncludedChooser;
