import React, { useState, useEffect }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';

const MaterialGroupChooser = ({ id, unit, drawing, setId }) => {
  const [value, setValue] = useState(id);
  const onChange = (e) => {
    e.preventDefault();
    setValue( () => e.target.value )
  }

  // useEffect
  useEffect( () => {
    if(id != value) { setId( () => value ) }
  }, [value])

  useEffect( () => { setValue( () => id) }, [id, unit, drawing] )
  // useEffect

  if(drawing == undefined) { return }
  if(drawing.main_group_chooser.drawing_id_in_cartons == undefined && drawing.main_group_chooser.drawing_id_in_corrugated == undefined) { return }

  return (
    <div className={styles.row}>
      <span> { t('drawing.choose_main_group') } </span>
      <select value={value} onChange={onChange} className={`form-control ${styles.form_control_v2}`} >
        <option value={drawing.main_group_chooser.drawing_id_in_cartons != undefined ? drawing.main_group_chooser.drawing_id_in_cartons : id }> {t('models.material_cartons')} </option>
        <option value={drawing.main_group_chooser.drawing_id_in_corrugated != undefined ? drawing.main_group_chooser.drawing_id_in_corrugated : id}> {t('models.material_corrugated_cardboards')} </option>
      </select>
    </div>
  )
}

export default MaterialGroupChooser;
