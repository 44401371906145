import React, { useState, useEffect }  from 'react';
import Constants from 'components/show_drawing/Constants.jsx';
import MyEventListener from 'components/common/MyEventListener.jsx'
import { GetRequest } from 'components/common/index.js';

const LoadFromLocalStorage = ({ id, unit, userDowloadId, debug, formRef, drawing, setResetEnabled, setList, initialOptions }) => {
  const [readyToPublishValues, setReadyToPublishValues] = useState(false)
  const [waitingForOptionNamesReady, setWaitingForOptionNamesReady] = useState([])
  const [userOptions, setUserOptions] = useState( {} )
  const [addOnOptions, setAddOnOptions] = useState( {} )
  const [readyUserOptions, setReadyUserOptions] = useState( []  )

  const prepareUserOptions = (user_options) => {
    if(user_options['add_ons'] != undefined && user_options['add_ons'].length > 0) {
      populateAddOnOptions(user_options)
    }

    if(user_options['material_id'] == undefined) {
      user_options['material_id'] = `custom|${user_options['malzeme']}`
    }

    if(!user_options["internal_dimensions"]) {
      delete user_options["internal_dimensions"]
    }

    return user_options;
  }

  const userOptionsFromLocalStorage = () => {
    if(drawing.main_id == undefined) { return }

    let keyForLocalStorage =  `fo_${drawing.main_id}_${unit}`;
    var user_options = localStorage.getItem(keyForLocalStorage);
    if(!user_options) { return }
    user_options = JSON.parse(user_options)
    setUserOptions( () => prepareUserOptions(user_options) )
  }

  // https://diecuttemplates.com.dev/drawing-download/52288/264312
  const getUserDownloadOptions = () => {
    if(drawing.main_id == undefined) { return }

    GetRequest({
      url: `/drawing-download/${id}/${userDowloadId}`,
      callback: (data) => {
        var options_user_changed = data.options.options_user_changed.split(',')
        var options = { advancedOptionsVisible:  data.advanced_options_enabled }

        if(data.options.material_id != undefined && data.options.material_id != '') {
          options['material_id'] = data.options.material_id
        } else {
          options['material_id'] = `custom|${data.options['malzeme']}`
        }

        if(data.options.printing_method != undefined && data.options.printing_method != '') {
          options['printing_method'] = data.options.printing_method
        }
        for(let i = 0; i< options_user_changed.length; i++) {
          options[ options_user_changed[i] ] = data.options[  options_user_changed[i] ]
        }

        if(data.options['add_ons'] != undefined && data.options['add_ons'].length > 0) {
          populateAddOnOptions(data.options)
        }

        if(data.options["internal_dimensions"]) {
          options["internal_dimensions"] = "1"
        }

        setUserOptions( () => options )
      }
    })
  }

  const getInitialOptions = () => {
    if(drawing.main_id == undefined) { return }
    console.log( {initialOptions} )
    setUserOptions( () => prepareUserOptions(initialOptions) )
  }

  const populateUserOptions = () => {
    if(drawing.image == undefined) { return }

    if(initialOptions != undefined && Object.keys(initialOptions).length > 0) { getInitialOptions(); return; }

    (userDowloadId != undefined && userDowloadId != '') ? getUserDownloadOptions() : userOptionsFromLocalStorage()
  }

  const populateAddOnOptions = (user_options) => {
    var add_on_options = {}
    for(let i=0; i< user_options['add_ons'].length; i++) {
      var add_on_id = user_options['add_ons'][i]
      add_on_options[ add_on_id ] = {}
      if(drawing.add_on_option_names[add_on_id] != undefined) {
        for(let j=0; j < drawing.add_on_option_names[add_on_id].length; j++) {
           var add_on_option_name = drawing.add_on_option_names[add_on_id][j];
           if(user_options [add_on_option_name] != undefined ) {
              add_on_options[ add_on_id ][add_on_option_name] = user_options [add_on_option_name];
           }
        }
      }
    }

    emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] populateAddOnOptions", id: id, add_on_options: add_on_options })
    setAddOnOptions( () => add_on_options )
  }

  const publishAddOnValues = () => {
    if(addOnOptions == undefined || Object.keys(addOnOptions).length == 0) { return }
    emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] publishAddOnValues", id: id, addOnOptions: addOnOptions })

    emitEvent(Constants.drawing_add_add_on, { id: id, options: addOnOptions})
  }

  const optionNamesInUserOptions = () => {
    var res = Object.keys(userOptions).filter(value => drawing.option_names.includes(value));
    res.push("material_id")
    if (userOptions['printing_method'] != undefined) { res.push("printing_method") }
    if (userOptions['internal_dimensions'] != undefined) { res.push("internal_dimensions") }

    emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] optionNamesInUserOptions", id: id, res: res, readyToPublishValues: readyToPublishValues })
    return res;
  }

  const onUserOptionsChanged = () => {
    if(userOptions == undefined || drawing.main_id == undefined) { return }

    if(Object.keys(userOptions).length == 0 ) {
      setResetEnabled( () => false )
      return
    }

    setResetEnabled( () => true)

    emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] UserOptions", id: id, userOptions: userOptions, readyToPublishValues: readyToPublishValues })

    setWaitingForOptionNamesReady( (_prev) => optionNamesInUserOptions() )
  }

  const onWaitingForOptionNamesReadyChanged = () => {
    emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] waitingForOptionNamesReady", id: id, waitingForOptionNamesReady: waitingForOptionNamesReady, readyToPublishValues: readyToPublishValues })
  }

  const onDrawingOptionChangeCompleted = (msg) => {
    if(msg.detail.id != id) { return }
    emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] onDrawingOptionChangeCompleted REMOVING => ", id: id, detail: msg.detail })
    setReadyUserOptions( (prev) => prev.concat( [msg.detail.name ] ).filter(onlyUnique) )
  }

  const onReadyUserOptionsChanged = () => {
    emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] onReadyUserOptionsChanged entry", id: id,
      waitingForOptionNamesReady: waitingForOptionNamesReady.join(','),
      readyUserOptions: readyUserOptions.join(','),
      userOptions: userOptions
    })

    if (!userOptions || Object.keys(userOptions).length == 0 ) { return }
    if (readyUserOptions.length == 0 )                         { return }
    if (waitingForOptionNamesReady.length == 0 )               { return }

    const areAllReady = waitingForOptionNamesReady.every( ai => readyUserOptions.includes(ai) );
    const difference = waitingForOptionNamesReady.filter(element => !readyUserOptions.includes(element));

    emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] checkIfReadyToPublish", id: id,
      waitingForOptionNamesReady: waitingForOptionNamesReady.join(','),
      readyUserOptions: readyUserOptions.join(','),
      missing: difference.join(','),
      areAllReady: areAllReady
    })

    setReadyToPublishValues( () => areAllReady)
  }

  const isPublishRequired = () => {
    if (!userOptions || Object.keys(userOptions).length == 0 ) { return false }

    const formDataJSON = {};
    (new FormData(formRef.current)).forEach((value, key) => { formDataJSON[key] = value });

    for (const [key, value] of Object.entries(userOptions)) {
      if(key != 'advancedOptionsVisible' && parseFloat(value) != parseFloat(formDataJSON[key]) ) {
        emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] isPublishRequired TRUE ", key: key, v1: parseFloat(value), v2: parseFloat(formDataJSON[key])  } )
        return true
      }
    }

    emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] isPublishRequired FALSE ", userOptions: userOptions } )
    return false
  }


  const publishAdvancedOptionsVisible = () => {
    if( !userOptions || Object.keys(userOptions).length == 0 ) { return }

    emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] publishAdvancedOptionsVisible called", id: id})
    if (userOptions['advancedOptionsVisible']) {
      emitEvent(Constants.advanced_options_set_visible, { id: id, value: true } )
    }
  }

  const cleanUp = () => {
    emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] clearup" })
    setWaitingForOptionNamesReady( () => [] )
    setReadyUserOptions( () => [] )
    setReadyToPublishValues( () => false )
    setUserOptions( () => {} )
  }

  const OnDrawingSvgCompleted = (msg) => {
    if(msg.detail.id != id) { return }

    publishAddOnValues();
    setAddOnOptions( () => {} )

    cleanUp();
  }

  const publishValues = () => {
    if(readyToPublishValues && isPublishRequired() ) {
      emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] publishValues" });
      emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] setting the LIST for the listener", list: optionNamesInUserOptions() })
      emitEvent(Constants.drawing_set_values_and_redraw, { id: id, names: optionNamesInUserOptions(), values: userOptions })
    }
  }

  const onPerformPublishValuesMsg = (msg) => {
    if(msg.detail.id == id) {
      setList( () => msg.detail.names );

      for(const [key, value] of Object.entries(msg.detail.values)) {
        if(key != 'advancedOptionsVisible') {
          emitEvent('addDebugEntry', { event: "[LoadFromLocalStorage] publishOptionValue", name: key, value: value })
          emitEvent(Constants.drawing_option_set_value, { id: id, name: key, value: value } )
        }
      }

      cleanUp(); //job done
    }
  }

  // useEffect starts
  useEffect(onWaitingForOptionNamesReadyChanged, [ waitingForOptionNamesReady ])
  useEffect(populateUserOptions, [ drawing ])
  useEffect(onUserOptionsChanged, [ userOptions ])
  useEffect(publishAdvancedOptionsVisible, [ userOptions ])
  useEffect(onReadyUserOptionsChanged, [ userOptions, readyUserOptions, waitingForOptionNamesReady  ])
  useEffect(publishValues, [ readyToPublishValues ])
  // useEffect ends

  MyEventListener([
    {
      key: Constants.drawing_option_change_completed,
      callback: onDrawingOptionChangeCompleted
    },{
      key: Constants.drawing_svg_updated,
      callback: OnDrawingSvgCompleted
    },{
      key: Constants.drawing_set_values_and_redraw,
      callback: onPerformPublishValuesMsg,
    }
  ])

  return <small>
    { debug && <div> [{ JSON.stringify(userOptions) }]</div> }
    { debug && <div> [L] waitingForOptionNamesReady=[{waitingForOptionNamesReady.join(",")}] </div>}
    { debug && <div> [L] readyUserOptions=[{readyUserOptions.join(',')}] </div>}
    { debug && <div> [L] isPublishRequired? [{isPublishRequired ? 'YES' : 'NO'}] </div>}
    { debug && <div> [L] readyToPublishValues? [{readyToPublishValues ? 'YES' : 'NO'}] </div>}
    { debug && <hr />}
  </small>
}

export default LoadFromLocalStorage;
