import React, { useState, useEffect, useContext } from 'react';
import LayoutContext from 'components/contexts/LayoutContext.jsx';
import { Angle, Bool, Integer, Length } from 'components/layout/options/drawing_options/index.js'

const ChooseDielineOptions = ( {item, member_klass, member_id, recoveryApplyMethods} ) => {
  const myLayoutContext = useContext(LayoutContext);
  const getUUID = () => { return item.uuid; }

  const defaultOptions = () => {
    if(myLayoutContext.unit == 'in') {
      return {...myLayoutContext.optionsUserEntered }
    } else {
      return {...myLayoutContext.formulaOptions }
    }
  };

  const [options, setOptions] = useState([]);
  const [formulaOptions, setFormulaOptions] = useState( {} );

  const fetchLayoutDielineOptions = () => {
    fetch(`/layouts-template-not-advanced-options/${member_klass}/${member_id}`)
      .then(response => response.json())
      .then( (data) => {
        setFormulaOptions( (prev) => {
          prev[ getUUID() ] = defaultOptions()
          return { ... prev }
        });

        setOptions( (_prev) => { return data } );

        data.map( (optionData) => {
          let name = optionData.name;
          let defaultVal = defaultOptions()[name];
          let layoutOptions = objectifyForm($(`#form_${item.uuid}`));
          let existingValue = layoutOptions[name];
          let value = existingValue == undefined ? defaultVal : existingValue;

          setFormulaOptions( (prev) => {
            prev[ getUUID() ][name] = value;
            return { ... prev }
          });
        } )
      })
  }

  const getOnChangeFor = (option) => {
    return (val) => setFormulaOptions((previous) => {
      previous[getUUID()][option.name] = val;
      return { ...previous }
    })
  }

  const getValueByName = (name) => {
    if(formulaOptions[ getUUID() ] == undefined) {
      let default_options = defaultOptions();
      setFormulaOptions( (prev) => {
        prev[ getUUID() ] = default_options;
        return { ... prev }
      })

      return null;
    }

    return formulaOptions[ getUUID() ][name];
  }

  const setRecoveryApplyMethodFor = (option) => {
    if(recoveryApplyMethods == undefined) {
      return () => {};
    }
    recoveryApplyMethods.current[option.name] = getOnChangeFor(option)
  }

  const optionFactory = (option) => {
    let formField;
    switch(option.input_type) {
      case 'integer':
        formField = <Integer
          key={option.id}
          item={item}
          option={option}
          value={getValueByName(option.name)}
          onChange={getOnChangeFor(option)}
          enabled={true}
          doNotLoadFromLocalStorage={true}
        />
        break;

      case 'length':
        formField = <Length
          key={option.id}
          item={item}
          option={option}
          unit={myLayoutContext.unit}
          value={getValueByName(option.name)}
          onChange={getOnChangeFor(option)}
          enabled={true}
          doNotLoadFromLocalStorage={true}
        />
        break;

      case 'boolean':
        formField = <Bool
          key={option.id}
          item={item}
          option={option}
          value={getValueByName(option.name)}
          onChange={getOnChangeFor(option)}
          enabled={true}
          doNotLoadFromLocalStorage={true}
        />
        break;

      case 'angle':
        formField = <Angle
          key={option.id}
          item={item}
          option={option}
          value={getValueByName(option.name)}
          onChange={getOnChangeFor(option)}
          enabled={true}
          doNotLoadFromLocalStorage={true}
        />
        break;
      }

      return formField;
  }

  useEffect( () => {
    options.map((option) => {
      setRecoveryApplyMethodFor(option);
    })
  }, [options]);

  useEffect(fetchLayoutDielineOptions, [member_klass, member_id]);

  return(<>
    {options.length > 0 &&
      <div className="LayoutRedrawFormSection">
        {options.map((option) => {
          return optionFactory(option)
        })}
      </div>
    }
  </>
  );

}

export default ChooseDielineOptions;
