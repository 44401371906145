import React, { useEffect, useState, useRef } from 'react';
import { PostRequest, GetRequest } from 'components/common';
import {AskConfirm, ConfirmPopup} from 'components/ConfirmPopup.jsx';

const QuoteBuilderManagePanel = ({}) => {

  const [attributes, setAttributes] = useState({});
  const confirmPopupRef = useRef({});
  const requestInProgress = useRef(false);

  const getSummary = () => {
    if(window.user_quote_id != undefined) {
      if(requestInProgress.current) { return; }
      requestInProgress.current = true;

      GetRequest({
        url: `/quote-builder/my-quotes/${window.user_quote_id}/summary`,
        callback: (data) => {
          setAttributes(data);
          requestInProgress.current = false;
        }
      })
    }
  }

  useEffect(getSummary, [])

  const onSectionResultUpdated = () => {
    getSummary();
  }

  const setStatusConfirm = (e) => {
    let newValue = e.target.value;

    if(newValue == 'finalized') {
      AskConfirm(
        confirmPopupRef,
        t('quote_builder.confirm_finalized'),
        () => {
          PostRequest({
            url: `/quote-builder/${window.drawing_id}/${window.drawing_slug}/${window.user_quote.id}/finalize`,
            data: {
            },
            callback: (data) => {
              location.replace(data.redirect_to);
            }
          })

        }
      );
    }
  };
  const isTotalPriceSet = () => {
    return attributes.price_for_one_formatted != undefined && attributes.total_presented != undefined && attributes.price_for_one_formatted != '' && attributes.total_presented != '';
  }

  useEffect(() => {
    document.addEventListener('quote_builder.section_result_updated', onSectionResultUpdated);
    return () => { document.removeEventListener('quote_builder.section_result_updated', onSectionResultUpdated);}
  }, []);

  const aggregatedTotalRows = () => {
    let res = [];

    for (let [title, values] of Object.entries(attributes.aggregated_totals)) {

      res.push(
        <tr key={`title_${title}`}>
          <th colSpan="2" className="aggregatedTotalRowTitle"> {title}
          <span className="float-right"> {values._total} </span>
          </th>
        </tr>);

      for (let [key, value] of Object.entries(values)) {
        if(key != '_total') {
          res.push(
            <tr key={`key_${key}`}>
              <td>  &nbsp; &nbsp; {key} </td>
              <td  align="right" > {value} </td>
            </tr>);
        }
      }
    }

      res.push(<tr key='empty'>
        <th colSpan="2"> &nbsp; </th>
      </tr>);

    return res;
  }

  return (
    <> <ConfirmPopup myRef={confirmPopupRef} />
      <table className="table">
        <tbody>
          <tr key="status">
            <td>
              <strong> {t('quote_builder.quote_status')} </strong>
            </td>

            <td align="right">
              <select value={attributes.status}
                onChange={setStatusConfirm}
                disabled={!isTotalPriceSet()}
                className="form-control narrow_form_element"
              >
                <option key='draft' value='draft'> {t('quote_builder.draft')} </option>
                <option key='finalized' value='finalized'> {t('quote_builder.finalized')} </option>
              </select>
            </td>

            </tr>
            { isTotalPriceSet() && (
              <>
                {aggregatedTotalRows()}
                <tr key="price_for_one">
                   <td> <strong> {t('quote_builder.price_for_one')} </strong>  </td>
                   <td align="right">  {attributes.price_for_one_formatted}  </td>
                </tr>
                <tr key="total">
                   <td>  <strong> {t('quote_builder.total')} </strong>  </td>
                   <td align="right">  {attributes.total_presented}  </td>
                </tr>
              </>
            )}
        </tbody>
      </table>
    </>
  );
}

export default QuoteBuilderManagePanel;
