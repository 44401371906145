import React from 'react';

const Util = {
  lengthsByColorCodeFormattedTrRows: (lengthsByColorCodeFormatted, size) => {

    let retval = []
    let myArray = [];

    Object.entries(lengthsByColorCodeFormatted).map((pair) => {
      myArray.push( t('quote_builder.total') + " " + pair[0] + " " + pair[1] )
    })

    const subsets = [];
    for (let i = 0; i < myArray.length; i += size) { subsets.push(myArray.slice(i, i + size)); }
    const lastSubset = subsets[subsets.length - 1];
    const lastSubsetSize = lastSubset ? lastSubset.length : 0;
    const paddingSize = size - lastSubsetSize;
    if (paddingSize > 0) {
      const emptyStrings = Array(paddingSize).fill('');
      subsets[subsets.length - 1] = lastSubset.concat(emptyStrings);
    }

    for(let i=0; i< subsets.length; i++) {
      let tr = []
      for(let j=subsets[i].length -1 ; j >= 0; j--) {
        tr.push(<span className="info-quote"> {subsets[i][j]} </span>)
      }

      retval.push(
        <tr>
          {tr.map( (td) => {
            return <td align="right"> {td}</td>
          })}
        </tr>)
    }

    return retval;

  }
}

export default Util;
