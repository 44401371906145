import React, { useState }  from 'react';
import styles from 'components/show_drawing/redraw_form/_style.module.scss';
import Constants from 'components/show_drawing/Constants.jsx';
import MyEventListener from 'components/common/MyEventListener.jsx'

import { OptionLenghtWithUnit, OptionBoolean, OptionIntegerWithOptions, OptionIntegerWithRange, OptionAlignment, AddWindowsPopup, OptionPresets, InternalDimensionsChooser, DimensionTextsIncludedChooser } from 'components/show_drawing/redraw_form/index.js';

const OptionWithChilden = ( { id, drawing, option, unit, addToOptionsUserChanged, optionsUserChanged, setRedrawRequested} ) => {
   const key = (option) => { return "" + id + option.name + unit }
   const [childrenHidden, setChildrenHidden] = useState(true)

   const getOption = (option) => {
    if(option.input_type == 'length' && option.possible_values.length == 0) {
      return <OptionLenghtWithUnit
        id={id}
        drawing={drawing}
        option={option}
        unit={unit}
        key={key(option)}
        addToOptionsUserChanged={addToOptionsUserChanged}
        optionsUserChanged={optionsUserChanged}
        setRedrawRequested={setRedrawRequested}
      />
    }

    if(option.input_type == 'integer' && option.possible_values.length == 0) {
      return <OptionIntegerWithRange
        id={id}
        drawing={drawing}
        option={option}
        unit={unit}
        key={key(option)}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChanged}
        setRedrawRequested={setRedrawRequested}
      />
    }

    if(option.input_type == 'integer' && option.possible_values.length > 0) {
      throw new Error("[OptionIntegerWithOptions] is not implemented yet. Do we need this?")
      return <OptionIntegerWithOptions id={id} option={option} unit={unit} key={key(option) } addToOptionsUserChanged={addToOptionsUserChanged}  />
    }

    if(option.input_type == 'angle' && option.possible_values.length > 0) {
      return <OptionIntegerWithRange
        id={id}
        drawing={drawing}
        option={option}
        unit={unit}
        key={key(option)}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChanged}
        setRedrawRequested={setRedrawRequested}
      />
    }

    if(option.input_type == 'boolean') {
      return <OptionBoolean
        id={id}
        drawing={drawing}
        option={option}
        unit={unit}
        setRedrawRequested={setRedrawRequested}
        key={key(option)}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChanged}
      />
    }

    if(option.input_type == 'alignment') {
      return <OptionAlignment
        id={id}
        option={option}
        unit={unit}
        setRedrawRequested={setRedrawRequested}
        key={key(option)}
        optionsUserChanged={optionsUserChanged}
        addToOptionsUserChanged={addToOptionsUserChanged}
      />
    }

    throw new Error("[DrawingOptions] getOption returning null")
  }

  const getChildOption = (option) => {
    return getOption(option)
  }

  const childOptionVisibleChangeMsg = (msg) => {
    if(msg.detail.id == id && msg.detail.name == option.name) {
      setChildrenHidden( () => !msg.detail.visible )
    }
  }

  // useEffect
  MyEventListener([
    {
      key: Constants.child_options_visible_change,
      callback: childOptionVisibleChangeMsg
    }
  ])
  // useEffect
  return (
    <div>
      {getOption(option)}
      {!childrenHidden && <div className={styles.child_option}> {option.children.map( getChildOption )} </div> }
    </div>
  )
}

export default OptionWithChilden;
