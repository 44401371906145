import React, { useEffect } from 'react';

const PaymentSuccess = ({ callback }) => {
  const continueToDownload = (e) => {
    e.preventDefault();
    callback()
  }

  const trackPaymentSuccess = () => { trackEvent('js_payment_success', { file: '/payment/PaymentSuccess.jsx' }) }

  useEffect(refreshDownloadButtons, [])
  useEffect(clearFlashes, [])
  useEffect(trackPaymentSuccess, [])

  return (<>
    <br />
    <br />
    <div className="alert alert-success fade in">
      <h4>
        <i className="fa fa-check-circle"></i>
        &nbsp;
        {t('payment.thank_you_subscription_success')}
      </h4>
      <br />
      <hr />
      <center>
        <a className="btn simple-btn" href="#" onClick={continueToDownload}>  { t('payment.continue_to_download') } </a>
      </center>
    </div>
  </>)
}

export default PaymentSuccess;
