import React, {useState} from 'react';

import ChooseLayoutDimensions from 'components/layout/options/ChooseLayoutDimensions.jsx';
import ChooseNumXNumY from 'components/layout/options/ChooseNumXNumY.jsx';
import ChooseUserOptions from 'components/layout/options/ChooseUserOptions.jsx';
import RedrawLayoutButton from 'components/layout/options/RedrawLayoutButton.jsx';
import ChooseAdvancedOptions from 'components/layout/options/ChooseAdvancedOptions.jsx';

const LayoutRedrawForm = ({item, setRedrawRequested, recoveryApplyMethods, redrawInProgress, disabled, formRef}) => {
  const [options, setOptions] = useState({});

  return (
    <>
      <div className="LayoutRedrawForm">
        {!disabled && (<form ref={formRef} id={`form_${item.uuid}`}>
          <ChooseLayoutDimensions item={item} />
          <ChooseNumXNumY item={item} />
          <ChooseUserOptions item={item} member_id={item.id} member_klass={item.klass} recoveryApplyMethods={recoveryApplyMethods} />
          <ChooseAdvancedOptions item={item} member_id={item.id} member_klass={item.klass} recoveryApplyMethods={recoveryApplyMethods} />
          <RedrawLayoutButton uuid={item.uuid} redrawInProgress={redrawInProgress} setRedrawRequested={setRedrawRequested} />
        </form>)}

        {disabled && <div className="disabledForm">
          <ChooseLayoutDimensions />
          <ChooseNumXNumY/>
          <RedrawLayoutButton disabled />
          <br />
          <br />
        </div>}
        <br />
      </div>
    </>
  )
};

export default LayoutRedrawForm;
