import React, {useEffect} from 'react';
import LoadFromLocalStorage from 'components/layout/options/LoadFromLocalStorage.jsx'

const OptionRowInteger = ( {item, description, name, value, onChange, min_value, max_value, possible_values, disabled, onClick, doNotLoadFromLocalStorage} ) => {

  const optionValues = () => {
    let result = [];

    if(min_value != undefined && max_value != undefined) {
      for(let i = min_value; i <= max_value; i++) {
        result.push(<option key={i} value={i}> {i} </option>);
      }
    }

    if(possible_values != undefined && possible_values.length > 0) {
      possible_values.map( (i) => {
        result.push(<option key={i} value={i}> {i} </option>);
      })
    }

    return result;
  }

  useEffect(() => {
    if(doNotLoadFromLocalStorage) { return; }
    LoadFromLocalStorage(item, name, onChange)
  } ,[]);

  return (
    <div className="LayoutRedrawFormInputRow">
      <div className="input-group short-text">
        <span className="input-group-addon">
         {description}
        </span>

        <select
          disabled={disabled}
          name={name}
          value={value}
          onChange={ e => onChange(e.target.value)}
          onClick={e => { if(onClick != undefined) { onClick(e.target.value)} }}
          className="form-control-int form-control selectpickerl"
        >
          {optionValues().map((option) => option)}
        </select>
      </div>
    </div>
  );
}

export default OptionRowInteger