import React, {useEffect, useState, useContext, createRef } from 'react';
import OptionRowInteger from 'components/layout/options/OptionRowInteger.jsx'
import OptionRowIntegerFreeForm from 'components/layout/options/OptionRowIntegerFreeForm.jsx'
import OptionRowFloat from 'components/layout/options/OptionRowFloat.jsx'
import LayoutContext from 'components/contexts/LayoutContext.jsx';
import RedrawDataToPost from  'components/layout/RedrawDataToPost.jsx';

const ChooseUserOptions = ( { item, member_klass, member_id, recoveryApplyMethods } ) => {
  const myLayoutContext = useContext(LayoutContext);
  const [layoutTemplateOptions, setLayoutTemplateOptions] = useState([]);
  const [layout_template_option_values, set_layout_template_option] = useState(myLayoutContext.layoutOptions);
  const [activationResults, setActivationResults] = useState({});
  const [dimension_texts_included, set_dimension_texts_included] = useState(window.dimension_texts_included == true);

  const requestRedraw = () => { document.dispatchEvent(new CustomEvent('REDRAW_REQUESTED', { "detail": item.uuid })); }
  const fetchLayoutTemplateOptions = (redrawOnEmpty = true) => {
    fetch(`/layouts-template-options/${member_klass}/${member_id}`)
      .then(response => response.json())
      .then(data => {
        setLayoutTemplateOptions(data);
        if(data.length == 0 && redrawOnEmpty) { requestRedraw() }
      }
    )
  }

  const getOnChangeFor = layout_template_option => {
    return (val) => set_layout_template_option((previous) => {
      previous[layout_template_option.name] = val;
      return { ...previous };
    })
  }

  const setRecoveryApplyMethodFor = layout_template_option => {
    recoveryApplyMethods.current[layout_template_option.name] = getOnChangeFor(layout_template_option)
  }

  const checkActivationFormulaResult = (layout_template_option) => {
    if(!layout_template_option.has_activation_formula) {
      setActivationResults((prev) => {
        prev[layout_template_option.name] = true;
        return { ...prev }
      })

      return ;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(RedrawDataToPost( { uuid: item.uuid }))
    };

    fetch(`/layouts/${item.klass}/${item.id}/layout_template_option_activation/${layout_template_option.id}`, requestOptions)
      .then(response => response.json())
      .then((data) => {
        setActivationResults((prev) => {
          prev[layout_template_option.name] = data.visible;
          return { ...prev }
        })
      }
    );
  }

  const HandleActivationFormula = () => {
    let listener = function(e) {
      if(e.detail == item.uuid) { fetchLayoutTemplateOptions(false);}
    };

    document.addEventListener('layout_redraw_finished', listener);
    return () => { document.removeEventListener('layout_redraw_finished', listener); }
  }

  useEffect(HandleActivationFormula, [member_klass, member_id]);
  useEffect(() => {
    layoutTemplateOptions.map((layoutTemplateOption) => {
      checkActivationFormulaResult(layoutTemplateOption)
    })
  }, [layoutTemplateOptions]);

  const numofOptionsToSet = createRef(-1);

  const decreaseNum = () => {
    if(numofOptionsToSet.current == null) { numofOptionsToSet.current = layoutTemplateOptions.length }
    numofOptionsToSet.current = numofOptionsToSet.current - 1;
    if(numofOptionsToSet.current == 0) { requestRedraw(); }
  }

  const layoutTemplateOptionIntegerOnly = (layout_template_option) => {
    setRecoveryApplyMethodFor(layout_template_option)
    return <OptionRowIntegerFreeForm
      item={item}
      key={layout_template_option.id}
      description={layout_template_option.description}
      name={layout_template_option.name}
      decreaseNum={decreaseNum}
      value={layout_template_option_values[layout_template_option.name]}
      onChange={getOnChangeFor(layout_template_option)}
    />
  }

  const layoutTemplateOptionFloat = layout_template_option => {
    setRecoveryApplyMethodFor(layout_template_option)
    return activationResults[layout_template_option.name] && <OptionRowFloat
      wide= "true"
      item={item}
      key={layout_template_option.id}
      description={layout_template_option.description}
      name={layout_template_option.name}
      unit={myLayoutContext.unit}
      decreaseNum={decreaseNum}
      value={layout_template_option_values[layout_template_option.name]}
      onChange={getOnChangeFor(layout_template_option)}
    />
  }

  useEffect(fetchLayoutTemplateOptions, [member_klass, member_id]);

  useEffect(() => {
    dimension_texts_included ? $(".dmt").show() : $(".dmt").hide()
    window.dimension_texts_included = dimension_texts_included;
  }, [dimension_texts_included] )

  return(<>
    <div className="LayoutRedrawFormSection">
      <div className="choose_options">
        <h3> <i className="zmdi zmdi-format-valign-center"></i> </h3>
        <span className="option-label">
          {window.wording_layouts_choose_options}
        </span>
      </div>

      {layoutTemplateOptions.map((layout_template_option) => {
        return layout_template_option.integer_only ? layoutTemplateOptionIntegerOnly(layout_template_option) : layoutTemplateOptionFloat(layout_template_option)
      })}

      {myLayoutContext.dimension_texts_on_layouts_enabled && (
        <div className="input-group LayoutRedrawFormInputRow">
          <span className="input-group-addon input-group-addon-wide">
            <span className="float-left capitalized"> {t('models.dimension_texts')}</span>
            <input className="float-right form-check-input"
              value="true"
              checked={dimension_texts_included}
              type="checkbox"
              onChange={ (e) => { set_dimension_texts_included(() => !dimension_texts_included ) }}
              name="dimension_texts_included"
            />
          </span>
        </div>) }

    </div>
    </>
  );
}

export default ChooseUserOptions;
